import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';
import { AuthProvider } from 'hooks/use-auth';
import axios from 'axios';
import './index.css';
import { ConfigProvider } from 'antd';
import koKR from 'antd/lib/locale/ko_KR';
import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.withCredentials = true;

ReactDOM.render(
  <BrowserRouter>
    <ConfigProvider locale={koKR}>
      <AuthProvider>
        <Switch>
          <Route path={`/admin`} component={AdminLayout} />
          <Route path={`/auth`} component={AuthLayout} />
          <Redirect to="/auth/signin" />
        </Switch>
      </AuthProvider>
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
