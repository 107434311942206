import { Td, Tr } from '@chakra-ui/table';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';

export const ContentTableRow = ({ content, total, page, index }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(`/admin/contents/write`, { content });
  };
  const id = total - (page - 1) * 10 - index;

  return (
    <Tr onClick={handleClick}>
      <Td>{id < 0 ? '' : id}</Td>
      <Td>{content.title}</Td>
      <Td>{content.videoUrl.indexOf('s3.ap') > -1 ? '아마존' : 'Vimeo'}</Td>
      <Td>{content.videoLength}</Td>
      <Td>{content.likeCount}</Td>
      <Td>{dayjs(content.createdAt).format('YYYY-MM-DD')}</Td>
    </Tr>
  );
};
