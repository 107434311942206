import { Tag, Td, Tr, useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';

export const UserTableRow = (props) => {
  const { user } = props;
  const history = useHistory();
  const toast = useToast();

  const estimateStatus = (status) => {
    if (status === 'SUBMIT') {
      return (
        <Tag type="solid" colorScheme="teal">
          검토필요
        </Tag>
      );
    } else if (status === 'CONFIRM') {
      return <Tag colorScheme="red">완료</Tag>;
    }
  };
  const constractStatus = (status) => {
    if (status === 'READY') {
      return (
        <Tag type="solid" colorScheme="teal">
          계약서요청
        </Tag>
      );
    } else if (status === 'UPLOADED') {
      return <Tag colorScheme="red">입금대기</Tag>;
    } else if (status === 'MONEY_CHECKED') {
      return <Tag colorScheme="green">입금확인</Tag>;
    } else if (status === 'FINISH') {
      return <Tag colorScheme="twitter">계약완료</Tag>;
    } else if (status === 'REJECT') {
      return <Tag colorScheme="gray">계약종료</Tag>;
    }
  };

  const isAdStoreStatus = (isAdStore) => {
    if (isAdStore) {
      return <Tag colorScheme="red">계약완료</Tag>;
    }

    return <span>-</span>;
  };

  const goDetailPage = (id, isDeletedAccount = false) => {
    if (isDeletedAccount) {
      toast({
        position: 'top',
        status: 'warning',
        title: '탈퇴 유저입니다.',
      });
    } else {
      history.push('/admin/users/' + id);
    }
  };

  return (
    <Tr
      onClick={() => goDetailPage(user.id, user.phone === '01000000000')}
      height="57px"
    >
      <Td>{user.id}</Td>
      <Td>{user.name}</Td>
      <Td>{user.email}</Td>
      <Td>{user.phone}</Td>
      <Td>
        {estimateStatus(
          user.estimate?.status ||
            (user.estimate && user.estimate[user.estimate.length - 1]?.status),
        )}
      </Td>
      <Td>
        {constractStatus(
          user.contract?.status ||
            (user.contract && user.contract[user.contract.length - 1]?.status),
        )}
      </Td>
      <Td>{isAdStoreStatus(user.isAdStore)}</Td>
      <Td>{dayjs(user.updatedAt).format('YYYY-MM-DD HH:mm')}</Td>
    </Tr>
  );
};
