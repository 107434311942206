import { Button } from '@chakra-ui/button';
import { Checkbox, CheckboxGroup } from '@chakra-ui/checkbox';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Box, Center, Flex, Link, Stack, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { Stat, StatLabel, StatNumber } from '@chakra-ui/stat';
import { Textarea } from '@chakra-ui/textarea';
import { useToast } from '@chakra-ui/toast';
import { Descriptions, Image } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { uploadFile } from 'utils';
import { contractStatus } from 'utils';
import { engToKor } from 'utils';

export const UserDetail = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [adminPurchaseSolutionType, setAdminPurchaseSolutionType] =
    useState(null);
  const [changeSolutionType, setChangeSolutionType] = useState(null);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const history = useHistory();
  const toast = useToast();

  const getData = async () => {
    const { user_id } = params;
    const { data } = await axios.get('/users/' + user_id);
    setUserInfo(data);
    const res = await axios.get('/service-statics/business-categories');
    setBusinessCategories(res.data.value.data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (!userInfo) {
    return null;
  }

  const adminPurchase = async () => {
    if (!adminPurchaseSolutionType) {
      toast({
        position: 'top',
        status: 'warning',
        title: '솔루션 타입을 먼저 선택해주세요.',
      });
      return;
    }
    const { user_id } = params;
    await axios.post('/admin/admin-purchase', {
      user_id: +user_id,
      solutionType: adminPurchaseSolutionType,
    });
    toast({
      position: 'top',
      status: 'success',
      title: '처리되었습니다.',
    });
    history.go(0);
  };

  const onChangeSolutionType = async () => {
    if (!changeSolutionType) {
      toast({
        position: 'top',
        status: 'warning',
        title: '솔루션 타입을 먼저 선택해주세요.',
      });
      return;
    }
    if (!window.confirm('정말로 변경하시겠습니까?')) {
      return;
    }
    const { user_id } = params;
    await axios.post('/admin/change-solution-type', {
      user_id: +user_id,
      solutionType: changeSolutionType,
    });
    toast({
      position: 'top',
      status: 'success',
      title: '처리되었습니다.',
    });
    history.go(0);
  };

  const onChangeUserResetCount = async () => {
    const { user_id } = params;
    await axios.post('/admin/reset-change-count', {
      user_id: +user_id,
    });
    toast({
      position: 'top',
      status: 'success',
      title: '처리되었습니다.',
    });
  };

  const stopUsing = async () => {
    if (
      confirm(
        '해당 회원은 더 이상 솔루션을 이용할 수 없습니다. 계속 하시겠습니까?',
      )
    ) {
      await axios.post('/admin/stop-using', {
        estimate_id: userInfo.estimate.id,
      });
      toast({
        position: 'top',
        status: 'success',
        title: '처리되었습니다.',
      });
      history.go(0);
    }
  };

  const handleUserInfoChange = (key, value) => {
    console.log({ key, value });
    setUserInfo({ ...userInfo, [key]: value });
  };

  const handleDayoffCheck = (checked) => {
    handleUserInfoChange('dayoff', checked);
  };

  const handleBusinessCategoryChange = (e) => {
    setUserInfo({
      ...userInfo,
      businessCategory: e.target.value,
      businessSubCategory: '',
    });
  };

  const handleBusinessSubCategoryChange = (e) => {
    setUserInfo({ ...userInfo, businessSubCategory: e.target.value });
  };

  const onUserInfoChangeSubmit = async () => {
    if (!userInfo.businessCategory || !userInfo.businessSubCategory) {
      return toast({
        position: 'top',
        status: 'warning',
        title: '업종을 선택해주세요',
      });
    }
    if (!userInfo.location) {
      return toast({
        position: 'top',
        status: 'warning',
        title: '사업장 주소 대분류를 선택해주세요',
      });
    }

    await axios.patch(`/admin/normal-users/${userInfo.id}`, {
      storeImageUrl: userInfo.storeImageUrl,
      businessNumber: userInfo.businessNumber,
      businessName: userInfo.businessName,
      businessAddress: userInfo.businessAddress,
      businessDetailAddress: userInfo.businessDetailAddress,
      businessOpenTime: userInfo.businessOpenTime,
      businessCloseTime: userInfo.businessCloseTime,
      businessTimeMemo: userInfo.businessTimeMemo,
      businessBusyHour: userInfo.businessBusyHour,
      businessMaxSeat: userInfo.businessMaxSeat,
      businessDailyVisitor: userInfo.businessDailyVisitor,
      businessCustomerAge: userInfo.businessCustomerAge,
      businessEmail: userInfo.businessEmail,
      location: userInfo.location,
      businessCategory: userInfo.businessCategory,
      businessSubCategory: userInfo.businessSubCategory,
      dayoff: userInfo.dayoff,
    });
    toast({
      position: 'top',
      status: 'success',
      title: '수정되었습니다',
    });
  };

  const handleUnlimitedPlaylist = async () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    const checked = !userInfo.isUnlimitedPlaylist;
    if (checked) {
      const { contract } = userInfo;
      if (contract.status !== 'FINISH') {
        return toast({
          position: 'top',
          status: 'warning',
          title: (
            <>
              <p>계약서가 완료 된 유저에게만 권한을 부여 할 수 있습니다.</p>
              <p>먼저 가구매를 진행해주세요.</p>
            </>
          ),
        });
      }
    } else {
      if (
        !window.confirm(`콘텐츠 무제한 이용 해제시 현재 사용자가 사용중인 빔몬스터 콘텐츠, 작가 콘텐츠(사용자가 구매한 작가 콘텐츠 제외)가 모두 초기화됩니다.
        그래도 진행하시겠습니까 ?`)
      ) {
        return;
      }
    }
    await axios.post('/creators/toggle-unlimited-playlist', {
      user_id: userInfo.id,
    });
    getData();
  };

  const handlestoreImageUrlUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const upload = uploadFile(file, '전경사진.jpg', false);
    upload.on('httpUploadProgress', (progress) => {
      const { loaded, total, Key } = progress;
      const pro = Math.floor((loaded / total) * 100);
      if (pro === 100) {
        setTimeout(() => {
          setUserInfo({
            ...userInfo,
            storeImageUrl: `https://beam-monster-backend.s3.ap-northeast-2.amazonaws.com/${Key}`,
          });
        }, 500);
      }
    });
    await upload.done();
  };

  const updateAdminScreenSize = async () => {
    await axios.patch(`/admin/normal-users/${userInfo.id}`, {
      adminScreenSize: userInfo.adminScreenSize,
    });
    toast({
      position: 'top',
      status: 'success',
      title: '수정되었습니다',
    });
  };

  const setStoreAd = async (isAdStore) => {
    await axios.patch(`/admin/normal-users/${userInfo.id}`, {
      isAdStore: isAdStore,
    });
    setUserInfo({ ...userInfo, isAdStore });
  };

  return (
    <Box mt="120px">
      <Descriptions
        bordered
        title="유저정보"
        size={'small'}
        extra={
          <Button type="primary" onClick={() => history.push('/admin/users')}>
            목록
          </Button>
        }
      >
        <Descriptions.Item label="이름">{userInfo.name}</Descriptions.Item>
        <Descriptions.Item label="휴대폰번호">
          {userInfo.phone}
        </Descriptions.Item>
        <Descriptions.Item label="이메일">
          {userInfo.email} / 인증: {userInfo.isEmailVerified ? 'O' : 'X'}{' '}
        </Descriptions.Item>
        <Descriptions.Item label="생년월일">
          {dayjs(userInfo.birthday).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="성별">
          {userInfo.gender === 'MAIL' ? '남자' : '여자'}
        </Descriptions.Item>
        <Descriptions.Item label="마케팅 동의여부">
          {userInfo.marketingAgree ? 'O' : 'X'}
        </Descriptions.Item>
        <Descriptions.Item label="견적서 정보" span={1.5}>
          {userInfo.estimate &&
            userInfo.estimate.status?.indexOf('READY') === -1 && (
              <>
                <Stat mb={3}>
                  <StatLabel>주문번호</StatLabel>
                  <StatNumber>
                    <Link
                      onClick={() =>
                        history.push(`/admin/estimates/${userInfo.estimate.id}`)
                      }
                    >
                      {userInfo.estimate.id} <ExternalLinkIcon mb="5px" />
                    </Link>
                  </StatNumber>
                </Stat>
                <Stat mb={3}>
                  <StatLabel>하드웨어</StatLabel>
                  <StatNumber>
                    {engToKor(userInfo.estimate.hardware)}
                  </StatNumber>
                </Stat>
                <Stat mb={3}>
                  <StatLabel>상품</StatLabel>
                  <StatNumber>
                    {engToKor(userInfo.estimate.solutionType)}
                  </StatNumber>
                </Stat>
                <Stat mb={3}>
                  <StatLabel>견적완료일</StatLabel>
                  <StatNumber>
                    {userInfo.estimate.adminConfirmDate
                      ? dayjs(userInfo.estimate.adminConfirmDate).format(
                          'YYYY-MM-DD HH:mm',
                        )
                      : ''}
                  </StatNumber>
                </Stat>
              </>
            )}
        </Descriptions.Item>
        <Descriptions.Item label="계약서 정보" span={1.5}>
          {userInfo.contract &&
            userInfo.contract.status?.indexOf('READY') === -1 && (
              <>
                <Stat mb={3}>
                  <StatLabel>주문번호</StatLabel>
                  <StatNumber>
                    <Link
                      onClick={() =>
                        history.push(`/admin/contracts/${userInfo.contract.id}`)
                      }
                    >
                      {userInfo.contract.id} <ExternalLinkIcon mb="5px" />
                    </Link>
                  </StatNumber>
                </Stat>
                <Stat mb={3}>
                  <StatLabel>결제타입</StatLabel>
                  <StatNumber>
                    {engToKor(userInfo.contract.paymentType)}
                  </StatNumber>
                </Stat>
                <Stat mb={3}>
                  <StatLabel>상태</StatLabel>
                  <StatNumber>
                    {contractStatus(userInfo.contract.status)}
                  </StatNumber>
                </Stat>
                <Stat mb={3}>
                  <StatLabel>계약완료일</StatLabel>
                  <StatNumber>
                    {userInfo.contract.finishDate
                      ? dayjs(userInfo.contract.finishDate).format(
                          'YYYY-MM-DD HH:mm',
                        )
                      : ''}
                  </StatNumber>
                </Stat>
              </>
            )}
        </Descriptions.Item>
        <Descriptions.Item label="실제 설치 사이즈 메모" span={3}>
          <Textarea
            rows={3}
            value={userInfo.adminScreenSize}
            placeholder={'광고영상 송출 시 커스텀 영상 크기 생성 참고용'}
            onChange={(e) =>
              setUserInfo({ ...userInfo, adminScreenSize: e.target.value })
            }
          />
          <Button colorScheme="green" mt={1} onClick={updateAdminScreenSize}>
            저장
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label="솔루션 강제 변경" span={1.5}>
          <Flex>
            <Select
              width="120px"
              disabled={userInfo.contract?.status !== 'FINISH'}
              defaultValue={userInfo.estimate.solutionType}
              placeholder="상품 선택"
              onChange={(e) => setChangeSolutionType(e.target.value)}
            >
              <option value="BASIC">베이직</option>
              <option value="STANDARD">스탠다드</option>
              <option value="PREMIUM">프리미엄</option>
            </Select>
            <Center>
              <Button
                onClick={onChangeSolutionType}
                disabled={userInfo.contract?.status !== 'FINISH'}
                colorScheme="red"
                size="sm"
                ml="10px"
              >
                상품 변경
              </Button>
              <Button
                onClick={onChangeUserResetCount}
                disabled={userInfo.contract?.status !== 'FINISH'}
                colorScheme="orange"
                size="sm"
                ml="10px"
              >
                플레이리스트 초기화
              </Button>
            </Center>
          </Flex>
          {userInfo.contract?.status !== 'FINISH' && (
            <Text fontWeight="light">
              계약서 완료 상태에서만 변경할 수 있습니다.
            </Text>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="상품 가구매" span={1.5}>
          <Flex>
            <Select
              width="120px"
              disabled={
                userInfo.contract && userInfo.contract.status === 'FINISH'
              }
              onChange={(e) => setAdminPurchaseSolutionType(e.target.value)}
              placeholder="상품 선택"
            >
              <option value="BASIC">베이직</option>
              <option value="STANDARD">스탠다드</option>
              <option value="PREMIUM">프리미엄</option>
            </Select>
            <Button
              disabled={
                userInfo.contract && userInfo.contract.status === 'FINISH'
              }
              onClick={adminPurchase}
              colorScheme="green"
              ml="10px"
            >
              상품 가구매
            </Button>
            <Button
              disabled={!userInfo.estimate}
              onClick={stopUsing}
              colorScheme="red"
              ml="10px"
            >
              구매취소
            </Button>
          </Flex>
          {userInfo.contract && userInfo.contract.status === 'FINISH' && (
            <Text fontWeight="light">
              현재 계약이 완료 된 상태에서는 가구매를 할 수 없습니다.
            </Text>
          )}
          <Flex alignItems="center" onClick={handleUnlimitedPlaylist}>
            <input
              type="checkbox"
              disabled={loading}
              checked={userInfo.isUnlimitedPlaylist}
            />
            <Text ml="1" userSelect="none">
              콘텐츠 무제한 사용
            </Text>
          </Flex>
        </Descriptions.Item>
        <Descriptions.Item label="사업장 정보" span={3}>
          <Stat mb={3}>
            <StatLabel>광고계약 여부</StatLabel>
            <Stack spacing={[2, 5]} direction={'row'}>
              <Checkbox
                value={userInfo.isAdStore}
                defaultChecked={userInfo.isAdStore}
                onChange={(e) => setStoreAd(e.target.checked)}
              >
                계약매장
              </Checkbox>
            </Stack>
          </Stat>
          {userInfo.isAdStore && (
            <>
              <Stat mb={3}>
                <StatLabel>업종 대분류</StatLabel>
                <Select
                  style={{ width: '100%' }}
                  placeholder="업종 대분류를 선택해주세요."
                  value={userInfo.businessCategory || ''}
                  onChange={handleBusinessCategoryChange}
                >
                  {businessCategories.map((el) => (
                    <option value={el.category} key={el.category}>
                      {el.category}
                    </option>
                  ))}
                </Select>
              </Stat>
              <Stat mb={3}>
                <StatLabel>업종 상세</StatLabel>
                {userInfo.businessCategory &&
                businessCategories.find(
                  (el) => el.category === userInfo.businessCategory,
                ) ? (
                  <Select
                    style={{ width: '100%' }}
                    value={userInfo.businessSubCategory || ''}
                    onChange={handleBusinessSubCategoryChange}
                    placeholder="상세업종을 선택해주세요."
                  >
                    {businessCategories
                      .find((el) => el.category === userInfo.businessCategory)
                      .subCategories.map((el) => (
                        <option value={el} key={el}>
                          {el}
                        </option>
                      ))}
                  </Select>
                ) : (
                  <Select
                    placeholder="대분류를 먼저 선택해주세요."
                    disabled
                  ></Select>
                )}
              </Stat>
              <Stat mb={3}>
                <StatLabel>사업장명</StatLabel>
                <Input
                  value={userInfo.businessName || ''}
                  size="lg"
                  onChange={(e) =>
                    handleUserInfoChange('businessName', e.target.value)
                  }
                ></Input>
                <StatNumber></StatNumber>
              </Stat>
              <Stat mb={3}>
                <StatLabel>사업장 주소 대분류</StatLabel>
                <Select
                  value={userInfo.location || ''}
                  placeholder="사업장주소를 선택해주세요."
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, location: e.target.value })
                  }
                >
                  {[
                    '서울',
                    '경기',
                    '인천',
                    '대전',
                    '세종',
                    '충남',
                    '충북',
                    '강원',
                    '부산',
                    '대구',
                    '울산',
                    '경남',
                    '경북',
                    '광주',
                    '전남',
                    '전북',
                    '제주',
                  ].map((el) => (
                    <option value={el} key={el}>
                      {el}
                    </option>
                  ))}
                </Select>
              </Stat>
              <Stat mb={3}>
                <StatLabel>사업장 주소</StatLabel>
                <Input
                  value={userInfo.businessAddress}
                  size="lg"
                  onChange={(e) =>
                    handleUserInfoChange('businessAddress', e.target.value)
                  }
                ></Input>
              </Stat>
              <Stat mb={3}>
                <StatLabel>사업자등록번호</StatLabel>
                <Input
                  value={userInfo.businessNumber || ''}
                  size="lg"
                  onChange={(e) =>
                    handleUserInfoChange('businessNumber', e.target.value)
                  }
                ></Input>
              </Stat>
              <Stat mb={3}>
                <StatLabel>고객 최대 방문 시간대</StatLabel>
                <Input
                  value={userInfo.businessBusyHour}
                  size="lg"
                  onChange={(e) =>
                    handleUserInfoChange('businessBusyHour ', e.target.value)
                  }
                ></Input>
                <Text>
                  * 10:30~23:30 와 같이 24시간 표기형식으로 형식으로
                  작성해주세요.
                </Text>
              </Stat>
              <Stat mb={3}>
                <StatLabel>고객 연령대</StatLabel>
                <Input
                  value={userInfo.businessCustomerAge || ''}
                  size="lg"
                  onChange={(e) =>
                    handleUserInfoChange('businessCustomerAge', e.target.value)
                  }
                ></Input>
              </Stat>
              <Stat mb={3}>
                <StatLabel> 매장 오픈시간 </StatLabel>
                <Input
                  value={userInfo.businessOpenTime || ''}
                  size="lg"
                  onChange={(e) =>
                    handleUserInfoChange('businessOpenTime', e.target.value)
                  }
                ></Input>
                <Text>* 24:00 형식으로 작성해주세요.</Text>
              </Stat>
              <Stat mb={3}>
                <StatLabel> 매장 마감시간</StatLabel>
                <Input
                  value={userInfo.businessCloseTime || ''}
                  size="lg"
                  onChange={(e) =>
                    handleUserInfoChange('businessCloseTime', e.target.value)
                  }
                ></Input>
                <Text>* 24:00 형식으로 작성해주세요.</Text>
              </Stat>
              <Stat mb={3}>
                <StatLabel>고객 수용인원</StatLabel>
                <Input
                  value={userInfo.businessMaxSeat || ''}
                  size="lg"
                  onChange={(e) =>
                    handleUserInfoChange('businessMaxSeat', e.target.value)
                  }
                ></Input>
              </Stat>
              <Stat mb={3}>
                <StatLabel>일일 평균 방문자</StatLabel>
                <Input
                  value={userInfo.businessDailyVisitor || ''}
                  size="lg"
                  onChange={(e) =>
                    handleUserInfoChange('businessDailyVisitor', e.target.value)
                  }
                ></Input>
              </Stat>
              <Stat mb={3}>
                <StatLabel>매장 특이사항</StatLabel>
                <Input
                  value={userInfo.businessTimeMemo || ''}
                  size="lg"
                  onChange={(e) =>
                    handleUserInfoChange('businessTimeMemo', e.target.value)
                  }
                ></Input>
              </Stat>
              <Stat mb={3}>
                <StatLabel>휴무일</StatLabel>
                <CheckboxGroup
                  onChange={(value) => handleDayoffCheck(value)}
                  colorScheme="green"
                  defaultValue={userInfo.dayoff}
                >
                  <Stack spacing={[2, 5]} direction={'row'}>
                    <Checkbox value="MONDAY">월요일</Checkbox>
                    <Checkbox value="TUESDAY">화요일</Checkbox>
                    <Checkbox value="WEDNESDAY">수요일</Checkbox>
                    <Checkbox value="THURSDAY">목요일</Checkbox>
                    <Checkbox value="FRIDAY">금요일</Checkbox>
                    <Checkbox value="SATURDAY">토요일</Checkbox>
                    <Checkbox value="SUNDAY">일요일</Checkbox>
                  </Stack>
                </CheckboxGroup>
              </Stat>
              <Stat mb={3}>
                <StatLabel>세금계산서 이메일</StatLabel>
                <Input
                  value={userInfo.businessEmail || ''}
                  size="lg"
                  onChange={(e) =>
                    handleUserInfoChange('businessEmail', e.target.value)
                  }
                ></Input>
              </Stat>
              <Stat mb={3}>
                <StatLabel>사업자등록증</StatLabel>
                {userInfo.businessRegistrationImgUrl && (
                  <a download href={userInfo.businessRegistrationImgUrl}>
                    파일 다운로드
                  </a>
                )}
              </Stat>
              <Stat mb={3}>
                <StatLabel>가게 썸네일 이미지</StatLabel>
                <input
                  type="file"
                  onChange={handlestoreImageUrlUpload}
                  accept="image/*"
                  className="z-10 w-full absolute opacity-0 h-full cursor-pointer"
                />
                {userInfo.storeImageUrl && (
                  <img
                    style={{ width: 300, height: 'auto' }}
                    src={userInfo.storeImageUrl}
                  />
                )}
              </Stat>
              <Button colorScheme="green" onClick={onUserInfoChangeSubmit}>
                사업장 정보 저장
              </Button>
            </>
          )}
        </Descriptions.Item>
      </Descriptions>
    </Box>
  );
};
