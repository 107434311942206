import React, { useState } from 'react';
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
// Assets
import signInImage from 'assets/img/signInImage.png';
import { useAuth } from 'hooks/use-auth';
import { useHistory } from 'react-router';
import axios from 'axios';

function SignIn() {
  // Chakra color mode
  const titleColor = useColorModeValue('red.400', 'red.800');
  const textColor = useColorModeValue('gray.400', 'white');
  const { updateUser, user } = useAuth();
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState(false);
  const toast = useToast();
  const history = useHistory();

  const handleEmail = (e) => {
    const value = e.target.value;
    setLoginId(value);
  };

  const handlePassword = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        email: loginId,
        password,
      };
      const res = await axios.post('/auth/login', data);
      const { access_token, isAdmin } = res.data;
      if (!isAdmin) {
        toast({
          position: 'top',
          status: 'warning',
          title: '어드민 유저 정보가 일치하지 않습니다.',
        });
        return;
      }
      await updateUser(access_token);
      history.push('/admin/dashboard');
    } catch (e) {
      toast({
        position: 'top',
        status: 'warning',
        title: '어드민 유저 정보가 일치하지 않습니다.',
      });
      const { status } = e.response;
      if (status === 401) {
        setIsError(true);
      }
    }
  };

  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: '100px', md: '0px' }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: 'none' }}
          w={{ base: '100%', md: '50%', lg: '42%' }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: '150px', lg: '80px' }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              반갑습니다
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              아이디와 비밀번호를 입력해 주세요.
            </Text>
            <form onSubmit={onSubmit}>
              <FormControl isInvalid={isError}>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  아이디
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="24px"
                  fontSize="sm"
                  type="text"
                  placeholder="아이디를 입력해주세요."
                  size="lg"
                  value={loginId}
                  onChange={handleEmail}
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  비밀번호
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="36px"
                  fontSize="sm"
                  id="pwd"
                  type="password"
                  placeholder="비밀번호를 입력해 주세요."
                  value={password}
                  onChange={handlePassword}
                  size="lg"
                />
                <Button
                  fontSize="16px"
                  type="submit"
                  bgColor={titleColor}
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  _hover={{
                    bg: 'red.300',
                  }}
                  _active={{
                    bg: 'red.500',
                  }}
                >
                  로그인
                </Button>
                {isError && (
                  <FormErrorMessage>
                    로그인 정보가 정확하지 않습니다.
                  </FormErrorMessage>
                )}
              </FormControl>
            </form>
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
