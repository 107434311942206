import { Button } from '@chakra-ui/button';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Container, Heading, HStack } from '@chakra-ui/layout';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import Card from 'components/Card/Card';
import { useAuth } from 'hooks/use-auth';
import { useState } from 'react';
import { useHistory } from 'react-router';

export const AdminUserWrite = ({ location }) => {
  const editAdminUser = location.adminUser || {};
  const isEditMode = !!editAdminUser.id;
  const [loginId, setLoginId] = useState(editAdminUser.loginId);
  const [name, setName] = useState(editAdminUser.name);
  const [phone, setPhone] = useState(editAdminUser.phone);
  const [password, setPassword] = useState(null);
  const [position, setPosition] = useState(editAdminUser.position);
  const [email, setEmail] = useState(editAdminUser.email);
  const [role, setRole] = useState(editAdminUser.role);
  const [errorMsg, setErrorMsg] = useState(null);
  const history = useHistory();
  const toast = useToast();
  const { user } = useAuth();

  const handleRole = (value) => {
    setRole(value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePosition = (e) => {
    setPosition(e.target.value);
  };

  const handleLoginId = (e) => {
    setLoginId(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handlePhone = (e) => {
    const value = e.target.value;
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((el) => el.toString());
    let numValue = '';
    for (let i = 0; i < value.length; i++) {
      const char = value[i];
      if (numbers.indexOf(char) > -1) {
        numValue += char;
      }
    }
    setPhone(numValue);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const user = {
      email,
      phone,
      name,
      password,
      loginId,
      position,
      role,
    };

    for (let key in user) {
      const value = user[key];
      user[key] = value?.trim();
    }

    try {
      let toastTitle = '계정이 생성되었습니다.';
      if (editAdminUser.id) {
        await axios.patch(`/admin/users/${editAdminUser.id}`, user);
        toastTitle = '수정되었습니다.';
      } else {
        await axios.post('/admin/users', user);
      }
      toast({
        title: toastTitle,
        status: 'success',
        isClosable: true,
      });
      history.replace('/admin/admin-users');
    } catch (e) {
      const { data } = e.response;
      if (data.message === '아이디중복') {
        setErrorMsg('이미 존재하는 아이디 입니다.');
      }
    }
  };

  const onDelete = async () => {
    await axios.delete(`/admin/users/${editAdminUser.id}`);
    toast({
      title: '삭제 되었습니다.',
      status: 'success',
      isClosable: true,
    });
    history.replace('/admin/admin-users');
  };

  return (
    <Container mt="80px" centerContent={false} maxWidth="100%">
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
        <Heading mb="30px" size="md">
          어드민 유저 등록
        </Heading>
        <form onSubmit={onSubmit}>
          <FormControl isRequired mb="24px" isInvalid={!!errorMsg}>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              아이디
            </FormLabel>
            <Input
              size="sm"
              borderRadius="15px"
              fontSize="sm"
              type="text"
              placeholder="로그인 아이디"
              value={loginId}
              onChange={handleLoginId}
            />
            <FormErrorMessage>{errorMsg}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={!isEditMode} mb="24px">
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              비밀번호
            </FormLabel>
            <Input
              size="sm"
              borderRadius="15px"
              fontSize="sm"
              type="password"
              placeholder="비밀번호"
              value={password}
              onChange={handlePassword}
            />
            {isEditMode && (
              <FormHelperText>
                비밀번호를 변경할 경우에만 입력해주세요.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              이름
            </FormLabel>
            <Input
              size="sm"
              borderRadius="15px"
              fontSize="sm"
              type="text"
              placeholder="이름"
              value={name}
              onChange={handleName}
            />
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              전화번호
            </FormLabel>
            <Input
              size="sm"
              borderRadius="15px"
              fontSize="sm"
              type="text"
              maxLength="11"
              placeholder="전화번호"
              value={phone}
              onChange={handlePhone}
            />
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              이메일
            </FormLabel>
            <Input
              size="sm"
              borderRadius="15px"
              fontSize="sm"
              type="email"
              placeholder="이메일"
              value={email}
              onChange={handleEmail}
            />
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              직급
            </FormLabel>
            <Input
              size="sm"
              borderRadius="15px"
              fontSize="sm"
              type="text"
              placeholder="직급"
              value={position}
              onChange={handlePosition}
            />
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              역할
            </FormLabel>
            <RadioGroup
              defaultValue="Itachi"
              value={role}
              onChange={handleRole}
            >
              <HStack spacing="24px">
                <Radio value="마스터">마스터</Radio>
                <Radio value="리더">리더</Radio>
                <Radio value="매니저">매니저</Radio>
              </HStack>
            </RadioGroup>
            <FormHelperText>
              마스터(모든 권한) 리더(읽기, 수정 권한) 매니저(읽기 권한)
            </FormHelperText>
          </FormControl>
          <FormControl>
            <Button
              fontSize="16px"
              type="submit"
              bg="red.300"
              w="100%"
              h="45"
              mb="20px"
              color="white"
              mt="20px"
              _hover={{
                bg: 'red.200',
              }}
              _active={{
                bg: 'red.400',
              }}
            >
              {editAdminUser.id ? '수정' : '저장'}
            </Button>
          </FormControl>
        </form>
        <Box>
          {editAdminUser.id && (
            <Button colorScheme="red" onClick={onDelete}>
              삭제
            </Button>
          )}
        </Box>
      </Card>
    </Container>
  );
};
