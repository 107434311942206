import { Button } from '@chakra-ui/button';
import { Checkbox, CheckboxGroup } from '@chakra-ui/checkbox';
import { LinkIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Box, Flex, Heading, Spacer, Stack, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { useToast } from '@chakra-ui/toast';
import { Descriptions } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { uploadFile, getVimeoUrl } from 'utils';
import StoreOrderTable from './StoreOrderTable';

function AdvertiseStoreDetail() {
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isNewUploadedVideo, setIsNewUploadedVideo] = useState(null);
  const [updateAd, setUpdateAd] = useState(null);
  const [ad, setAd] = useState({
    videoUrl: '',
    startDate: '',
    endDate: '',
    category: '',
  });
  const param = useParams();
  const toast = useToast();

  const getData = async () => {
    setLoading(true);
    setStore(null);
    const { storeUser_id } = param;
    const { data } = await axios.get('/advertises/stores/' + storeUser_id);

    setStore(data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (!store) {
    return null;
  }

  const handleUpload = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const isProd = process.env.NODE_ENV === 'production';

    const FOLDER_URI = isProd
      ? '/users/202267125/projects/20925311'
      : '/users/202267125/projects/20925269';

    const formData = new FormData();
    formData.append('files', file);
    formData.append('folder_uri', FOLDER_URI);
    formData.append('name', file.name);

    try {
      const { data: url } = await axios.post(
        '/utils/create-vimeo-video',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (updateAd) {
        setTimeout(() => {
          setUpdateAd({
            ...updateAd,
            videoUrl: `${url}`,
          });
        }, 500);
      } else {
        setTimeout(() => {
          setAd({
            ...ad,
            videoUrl: `${url}`,
          });
        }, 500);
      }
    } catch (e) {
      console.log(e);
    }
    setIsNewUploadedVideo(URL.createObjectURL(file));
    console.log(file);
    setLoading(false);
  };

  const onUpdateSubmit = async () => {
    setLoading(true);
    const { videoUrl, startDate, endDate } = updateAd;

    try {
      await axios.put('/advertises/' + updateAd.id + '/video-url', {
        videoUrl,
      });
      if (dayjs().isAfter(dayjs(startDate)) && dayjs().isBefore(endDate)) {
        await axios.patch('/advertises/orders/' + updateAd.order.id, {
          startDate,
          endDate,
          status: 'RUNNING',
        });
      } else {
        await axios.patch('/advertises/orders/' + updateAd.order.id, {
          startDate,
          endDate,
        });
      }
    } catch (e) {
      const { data } = e.response;
      setLoading(false);
      if (data.message === 'full') {
        return toast({
          title: '해당 기간에 광고가 6개가 꽉 찬 시기가 존재합니다.',
          status: 'warning',
          isClosable: true,
        });
      }
    }
    setUpdateAd(null);
    setIsNewUploadedVideo(null);
    toast({
      title: '수정 되었습니다.',
      status: 'success',
      isClosable: true,
    });
    getData();
  };

  const handleUpdateCancel = async () => {
    setUpdateAd(null);
    setIsNewUploadedVideo(null);
    setAd({
      videoUrl: '',
      startDate: '',
      endDate: '',
      category: '',
    });
  };

  const handleCreate = async () => {
    setLoading(true);
    const { videoUrl, startDate, endDate, category } = ad;
    if (!videoUrl || !startDate || !endDate || !category) {
      return toast({
        title: '모든 항목을 입력해주세요.',
        status: 'warning',
        isClosable: true,
      });
    }
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      return toast({
        title: '시작일과 종료일이 정확하지 않습니다.',
        status: 'warning',
        isClosable: true,
      });
    }

    try {
      await axios.post('/advertises/orders', {
        startDate,
        endDate,
        category,
        videoUrl,
        storeUser_id: store.id,
      });
    } catch (e) {
      const { data } = e.response;
      setLoading(false);
      if (data.message === 'full') {
        return toast({
          title: '해당 기간에 광고가 6개가 꽉 찬 시기가 존재합니다.',
          status: 'warning',
          isClosable: true,
        });
      }
    }
    setAd({ startDate: '', endDate: '', category: '', videoUrl: '' });
    setIsNewUploadedVideo(null);
    toast({
      title: '생성 되었습니다.',
      status: 'success',
      isClosable: true,
    });
    getData();
  };

  const handleUpdateAdSelect = async (ad) => {
    ad.videoUrl = (await getVimeoUrl(ad.videoUrl)) || '변환중';
    console.log(ad.videoUrl);
    setUpdateAd(ad);
  };

  const handleFinishAd = async (ad) => {
    await axios.patch('/advertises/orders/' + ad.order.id, {
      status: 'FINISH',
    });
    toast({
      title: '수정 되었습니다.',
      status: 'success',
      isClosable: true,
    });
    getData();
    handleUpdateCancel();
  };

  const handleRun = async (ad) => {
    await axios.patch('/advertises/orders/' + ad.order.id, {
      status: 'RUNNING',
    });
    toast({
      title: '수정 되었습니다.',
      status: 'success',
      isClosable: true,
    });
    getData();
    handleUpdateCancel();
  };

  return (
    <Box mt="100px">
      <Text fontSize="lg" fontWeight="bold">
        업체정보
      </Text>
      <Descriptions bordered>
        <Descriptions.Item label="업체명">
          <Link
            href={`/admin/users/${store.id}`}
            to={`/admin/users/${store.id}`}
          >
            {store.businessName}
            <LinkIcon />
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="지역">{store.location}</Descriptions.Item>
        <Descriptions.Item label="최대방문시간대">
          {store.businessBusyHour}
        </Descriptions.Item>
        <Descriptions.Item label="고객 연령대">
          {store.businessCustomerAge}
        </Descriptions.Item>
        <Descriptions.Item label="일평균 방문자">
          {store.businessDailyVisitor}
        </Descriptions.Item>
        <Descriptions.Item label="매장운영시간">
          {store.businessOpenTime}~{store.businessCloseTime}
        </Descriptions.Item>
        <Descriptions.Item label="업체대표명">
          {store.name}({store.phone})
        </Descriptions.Item>
        <Descriptions.Item label="휴무일" span={2}>
          <CheckboxGroup
            colorScheme="green"
            isDisabled
            defaultValue={store.dayoff}
          >
            <Stack spacing={[2, 5]} direction={'row'}>
              <Checkbox value="MONDAY">월</Checkbox>
              <Checkbox value="TUESDAY">화</Checkbox>
              <Checkbox value="WEDNESDAY">수</Checkbox>
              <Checkbox value="THURSDAY">목</Checkbox>
              <Checkbox value="FRIDAY">금</Checkbox>
              <Checkbox value="SATURDAY">수</Checkbox>
              <Checkbox value="SUNDAY">일</Checkbox>
            </Stack>
          </CheckboxGroup>
        </Descriptions.Item>
      </Descriptions>
      <Text fontSize="lg" fontWeight="bold" style={{ marginTop: '30px' }}>
        광고목록
      </Text>

      {store && (
        <StoreOrderTable
          handleUpdateAdSelect={handleUpdateAdSelect}
          storeUserId={store.id}
        ></StoreOrderTable>
      )}

      <Text fontSize="lg" fontWeight="bold" style={{ marginTop: '30px' }}>
        광고 등록
      </Text>
      <Box
        mt="10"
        borderWidth="1px"
        borderRadius="lg"
        p="6"
        backgroundColor="white"
      >
        {updateAd ? (
          <>
            <Heading mb="20px" size="md">
              광고 수정
            </Heading>
            <label>광고 영상</label>
            <br />
            <input
              type="file"
              onChange={handleUpload}
              accept="video/*"
              onClick={(e) => (e.target.value = null)}
            />
            {loading && '업로드 중...'}
            {isNewUploadedVideo ? (
              <video width="320" height="240" controls>
                - <source src={isNewUploadedVideo} type="video/mp4" />-{' '}
              </video>
            ) : updateAd.videoUrl === '변환중' ? (
              'Vimeo 변환중...'
            ) : (
              updateAd.videoUrl && (
                <iframe
                  src={updateAd.videoUrl}
                  width="500px"
                  height="400px"
                  frameBorder="0"
                ></iframe>
              )
            )}
            <br />
            <br />
            <br />
            <label>시작일</label>
            <br />
            <Input
              defaultValue={updateAd.startDate}
              onChange={(e) => {
                const date = dayjs(e.target.value, 'YYYY-MM-DD');
                setUpdateAd({
                  ...updateAd,
                  startDate: date.format('YYYY-MM-DD'),
                });
              }}
              placeholder="시작일"
              maxW="200"
              mb="4"
              type="date"
            />
            <br />
            <label>종료일</label>
            <br />
            <Input
              defaultValue={updateAd.endDate}
              onChange={(e) => {
                const date = dayjs(e.target.value, 'YYYY-MM-DD');
                setUpdateAd({
                  ...updateAd,
                  endDate: date.format('YYYY-MM-DD'),
                });
              }}
              placeholder="종료일"
              mb="4"
              maxW="200"
              type="date"
            />
            <br />
            <Flex justify="space-between">
              <div>
                <Button
                  mt="4"
                  style={{ width: 100 }}
                  colorScheme="green"
                  onClick={onUpdateSubmit}
                >
                  수정
                </Button>
                <Button
                  mt="4"
                  style={{ width: 100 }}
                  onClick={handleUpdateCancel}
                  variant="ghost"
                >
                  취소
                </Button>
              </div>
              {['RUNNING', 'PAID'].indexOf(updateAd.status) > -1 && (
                <Button
                  mt="4"
                  style={{ width: 100 }}
                  onClick={() => handleFinishAd(updateAd)}
                  colorScheme="red"
                >
                  광고종료처리
                </Button>
              )}
              {['FINISH'].indexOf(updateAd.status) > -1 && (
                <Button
                  mt="4"
                  style={{ width: 100 }}
                  colorScheme="green"
                  onClick={() => handleRun(updateAd)}
                >
                  광고재시작
                </Button>
              )}
            </Flex>
          </>
        ) : (
          <>
            <Heading mb="20px" size="md">
              새로운 광고
            </Heading>
            <label>광고영상</label>
            <br />
            <input
              accept="video/*"
              type="file"
              onChange={handleUpload}
              onClick={(e) => (e.target.value = null)}
            />
            {loading && '업로드 중...'}
            {ad.videoUrl === '변환중'
              ? 'Vimeo 변환중...'
              : isNewUploadedVideo && (
                  <video width="320" height="240" controls>
                    - <source src={isNewUploadedVideo} type="video/mp4" />-{' '}
                  </video>
                )}
            <br />
            <br />
            <br />
            <label>광고 카테고리</label>
            <Select
              placeholder="-"
              maxW="200"
              value={ad.category}
              onChange={(e) => setAd({ ...ad, category: e.target.value })}
            >
              {[
                '금융/부동산/보험',
                'IT/전자/통신',
                '공연/엔터테인먼트',
                '도서/교육/취업',
                '공구/자동차',
                '여행/관광',
                '생활/잡화',
                '공공기관/공익',
                '반려/취미 ',
                '미용/뷰티',
                '스포츠/레저/게임',
                '식품/음료/건강',
                '가구/인테리어',
                '이벤트/웨딩',
                '인쇄/문구/사무기기',
                '의류/패션잡화',
                '서비스',
                '주류광고',
                '의료/약품',
              ].map((el) => {
                return (
                  <option value={el} key={el}>
                    {el}
                  </option>
                );
              })}
            </Select>
            <br />
            <label>시작일</label>
            <br />
            <Input
              maxW="200"
              value={ad.startDate}
              onChange={(e) => {
                const date = dayjs(e.target.value, 'YYYY-MM-DD');
                setAd({
                  ...ad,
                  startDate: date.format('YYYY-MM-DD'),
                });
              }}
              placeholder="시작일"
              mb="4"
              type="date"
            />
            <br />
            <label>종료일</label>
            <br />
            <Input
              maxW="200"
              value={ad.endDate}
              onChange={(e) => {
                const date = dayjs(e.target.value, 'YYYY-MM-DD');
                setAd({
                  ...ad,
                  endDate: date.format('YYYY-MM-DD'),
                });
              }}
              placeholder="종료일"
              mb="4"
              type="date"
            />
            <div>
              <Button
                mt="4"
                style={{ width: 100 }}
                colorScheme="green"
                onClick={handleCreate}
                disabled={loading}
              >
                광고생성
              </Button>
            </div>
          </>
        )}
      </Box>
    </Box>
  );
}

export default AdvertiseStoreDetail;
