import {
  Table,
  TableCaption,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/table';
import axios from 'axios';
import { useEffect, useState } from 'react';

export const ContentVimeoStatus = ({ videoUrl }) => {
  const [status, setStatus] = useState({
    '4k': false,
    '2k': false,
    '1080p': false,
    '720p': false,
    '540p': false,
    '360p': false,
  });

  useEffect(() => {
    const getData = async () => {
      const arr = videoUrl.split('/');
      console.log(arr);
      if (arr && arr[5]) {
        const { data } = await axios.get('/utils/vimeo-upload-status', {
          params: {
            url: arr[5],
          },
        });
        setStatus(data);
      }
    };
    getData();
  }, []);

  return (
    <Table variant="simple" size="sm">
      <TableCaption>
        자세한 변환 현황은 Vimeo 관리자 사이트 내에서 확인이 가능합니다.
      </TableCaption>
      <Thead>
        <Tr>
          <Th>화질</Th>
          <Th>변환 완료</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>4k</Td>
          <Td>{status['4k'] ? '완료' : '-'}</Td>
        </Tr>
        <Tr>
          <Td>2k</Td>
          <Td>{status['2k'] ? '완료' : '-'}</Td>
        </Tr>
        <Tr>
          <Td>1080p</Td>
          <Td>{status['1080p'] ? '완료' : '-'}</Td>
        </Tr>
        <Tr>
          <Td>720p</Td>
          <Td>{status['720p'] ? '완료' : '-'}</Td>
        </Tr>
        <Tr>
          <Td>540p</Td>
          <Td>{status['540p'] ? '완료' : '-'}</Td>
        </Tr>
        <Tr>
          <Td>360p</Td>
          <Td>{status['360p'] ? '완료' : '-'}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
