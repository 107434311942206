import { Flex, Box } from '@chakra-ui/layout';
import {
  Table,
  Spacer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { Pagination } from 'antd';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { SearchInput } from 'components/SearchInput';
import { usePagination } from 'hooks/use-pagination';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ContentCategoryModal } from './ContentCategoryModal';
import { ContentTableRow } from './ContentTableRow';

export const ContentList = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [contents, setContents] = useState([]);
  const { currentPage, total, onPageChange, setSearch } = usePagination(
    '/contents',
    setContents,
  );

  const colNames = ['번호', '제목', '저장위치', '길이', '좋아요수', '등록일'];

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader p="6px 0px 22px 0px">
            <Flex width="100%">
              <Box>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  콘텐츠 관리
                </Text>
              </Box>
              <SearchInput setSearch={setSearch}></SearchInput>
              <Spacer></Spacer>
              <Box>
                <ContentCategoryModal></ContentCategoryModal>
              </Box>
              <Box>
                <NavLink to="/admin/contents/write">
                  <Button fontSize="l" colorScheme="green">
                    등록
                  </Button>
                </NavLink>
              </Box>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  {colNames.map((colName, idx) => {
                    return (
                      <Th
                        color="gray.400"
                        key={idx}
                        ps={idx === 0 ? '0px' : null}
                      >
                        {colName}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {contents.map((row, index) => {
                  return (
                    <ContentTableRow
                      index={index}
                      total={total}
                      page={currentPage}
                      key={row.id}
                      content={row}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
          <Pagination
            showSizeChanger={false}
            current={currentPage}
            onChange={onPageChange}
            total={total}
          ></Pagination>
        </Card>
      </Flex>
    </>
  );
};
