import { Button } from '@chakra-ui/button';
import {
  Editable,
  EditablePreview,
  EditableTextarea,
} from '@chakra-ui/editable';
import { useDisclosure } from '@chakra-ui/hooks';
import { DownloadIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Link, Spacer, Text } from '@chakra-ui/layout';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/modal';
import { Select } from '@chakra-ui/select';
import { useToast } from '@chakra-ui/toast';
import { Badge, Descriptions, Image } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { estimateStatus } from 'utils';

function EstimateDetail() {
  const [estimate, setEstimate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const param = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const history = useHistory();

  useEffect(() => {
    const getData = async () => {
      const { estimate_id } = param;
      const { data } = await axios.get('/estimates/' + estimate_id);
      const { data: templateData } = await axios.get(
        '/service-statics/estimate-templates',
      );
      setTemplates(templateData.filter((el) => el.type === data.hardwareType));

      setEstimate(data);
    };
    getData();
  }, []);

  if (!estimate) {
    return null;
  }

  const handleSelect = (e) => {
    const id = e.target.value;
    const template = templates.find((el) => el.id === +id);
    if (!template.fileUrl) {
      toast({
        position: 'top',
        status: 'warning',
        title: '해당 템플릿은 파일을 갖고 있지 않습니다.',
      });
      return;
    }
    setEstimate({
      ...estimate,
      templateFilename: template.fileName,
      templateUrl: template.fileUrl,
    });
  };

  const onConfirm = async () => {
    const { templateFilename, templateUrl } = estimate;
    if (!templateFilename || !templateUrl) {
      toast({
        position: 'top',
        status: 'warning',
        title: '견적서 템플릿을 선택해주세요.',
      });
      onClose();
      return;
    }
    onClose();
    await axios.post(`/estimates/${estimate.id}/confirm`, estimate);
    toast({
      position: 'top',
      status: 'success',
      title: '발송되었습니다.',
    });
    history.push('/admin/estimates');
  };

  const handleDelete = async () => {
    const answer = window.confirm('정말로 삭제하시겠습니까?');

    if (answer) {
      await axios.delete(`/estimates/${estimate.id}`);
      history.replace('/admin/estimates');
      toast({
        position: 'top',
        status: 'success',
        title: '삭제처리 되었습니다.',
      });
    }
  };
  const handleMemo = async (memo) => {
    await axios.patch(`/estimates/${estimate.id}`, { memo });
    toast({
      position: 'top',
      status: 'success',
      title: '메모가 저장되었습니다.',
    });
  };

  const onAdminCheckAbort = async () => {
    if (window.confirm('정말 반려하시겠습니까?')) {
      await axios.post(`/estimates/${estimate.id}/admin-abort`);
      toast({
        position: 'top',
        status: 'success',
        title: '반려되었습니다.',
      });
      history.push('/admin/estimates');
    }
  };
  const onAdminCheckConfirm = async () => {
    await axios.post(`/estimates/${estimate.id}/admin-confirm`);
    toast({
      position: 'top',
      status: 'success',
      title: '승인되었습니다.',
    });
    history.push('/admin/estimates');
  };

  function isUrlImage(url) {
    return url.match(/\.(jpeg|jpg|gif|png)/) != null;
  }

  return (
    <Box mt="100px">
      <Text fontSize="lg" fontWeight="bold">
        주문 정보
      </Text>
      {estimate.status === 'SUBMIT' && (
        <Button colorScheme="green" variant="solid" my="3" onClick={onOpen}>
          견적서 발송
        </Button>
      )}
      <Descriptions bordered>
        <Descriptions.Item label="견적서 파일" span="6">
          <Flex>
            <Center flex={1}>
              {estimate.templateFilename ? (
                <Link href={estimate.templateUrl} isExternal>
                  {estimate.templateFilename ? estimate.templateFilename : '-'}{' '}
                  <ExternalLinkIcon mx="2px" />
                </Link>
              ) : (
                <Text>-</Text>
              )}
            </Center>
            <Box flex={1}>
              <Select
                placeholder="템플릿 선택"
                size="sm"
                onChange={handleSelect}
              >
                {templates.map((el) => {
                  return (
                    <option key={el.id} value={el.id}>
                      {el.title}
                    </option>
                  );
                })}
              </Select>
            </Box>
          </Flex>
        </Descriptions.Item>
        <Descriptions.Item label="설치 검토 첨부파일" span="3">
          <Flex>
            {estimate.fieldPhotos?.map((el) => (
              <Box mr={3}>
                {isUrlImage(el) ? (
                  <Image src={el} width={100} height={100} />
                ) : (
                  <Link download href={el} key={el}>
                    <Box border="1px solid #d9d9d9" w={100} h={100}>
                      <Center h={100}>
                        <DownloadIcon w={10} h={10}></DownloadIcon>
                      </Center>
                    </Box>
                  </Link>
                )}
              </Box>
            ))}
          </Flex>
          {estimate.status === 'PENDING_CHECK_ADMIN' && (
            <Flex>
              <Button
                mr={3}
                colorScheme="green"
                variant="solid"
                onClick={onAdminCheckConfirm}
              >
                승인
              </Button>
              <Button onClick={onAdminCheckAbort}>반려</Button>
            </Flex>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="하드웨어 타입">
          {estimate.hardware}
        </Descriptions.Item>
        <Descriptions.Item label="주문상태">
          {estimateStatus(estimate.status)}
        </Descriptions.Item>
        <Descriptions.Item label="주문번호">{estimate.id}</Descriptions.Item>
        <Descriptions.Item label="주문시간">
          {dayjs(estimate.submitDate).format('YYYY-MM-DD HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label="주문자" span={2}>
          {`[${estimate.writer.businessName}] ${estimate.writer.name}(${estimate.writer.phone})`}
        </Descriptions.Item>
        <Descriptions.Item label="배송주소" span={3}>
          {`${estimate.address || ''} ${
            estimate.detailAddress || ''
          } 특이사항 : ${estimate.extraInfo || '없음'}`}
        </Descriptions.Item>
        <Descriptions.Item label="빔 정보">
          {`${estimate.beamSize || '-'}`}
        </Descriptions.Item>
        <Descriptions.Item label="LED 정보">
          {`가로: ${estimate.ledWidth * 1000 || '-'}mm 세로: ${
            estimate.ledHeight * 1000 || '-'
          }mm`}
        </Descriptions.Item>
        <Descriptions.Item label="확인 관리자">
          {estimate.admin
            ? `${estimate.admin.name} (${dayjs(
                estimate.admin.confirmDate,
              ).format('YYYY-MM-DD HH:mm')})`
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="관리자 메모" span={3}>
          <Box minH="200px">
            <Editable
              placeholder="여기를 눌러서 메모 작성"
              defaultValue={estimate.memo}
              onSubmit={(memo) => handleMemo(memo)}
            >
              <EditablePreview />
              <EditableTextarea rows={10} size="lg" />
            </Editable>
          </Box>
          <Button variant="ghost">메모저장</Button>
        </Descriptions.Item>
        <Descriptions.Item label="견적서 삭제" span={3}>
          <Button colorScheme="red" onClick={handleDelete}>
            삭제
          </Button>
        </Descriptions.Item>
      </Descriptions>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              견적서 발송
            </AlertDialogHeader>

            <AlertDialogBody>정말로 발송 하시겠습니까?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={onConfirm} ml={3}>
                발송
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default EstimateDetail;
