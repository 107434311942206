import { Button, IconButton } from '@chakra-ui/button';
import Upload from 'rc-upload';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { useDisclosure } from '@chakra-ui/hooks';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Box, Center, Flex, SimpleGrid, Spacer, Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/toast';
import { uploadFile } from 'utils';
import { Avatar } from '@chakra-ui/avatar';

function MainpageUsingExample() {
  const [usingExamples, setUsingExamples] = useState([]);
  const [updateData, setUpdateData] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: updateIsOpen,
    onOpen: updateOnOpen,
    onClose: updateOnClose,
  } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    const getMainpageData = async () => {
      const { data } = await axios.get('/service-statics/mainpage');
      setUsingExamples(data.value.usingExamples);
    };
    getMainpageData();
  }, []);

  const saveUsingExample = async () => {
    await axios.patch('/service-statics/mainpage', {
      usingExamples,
    });
    toast({
      position: 'top',
      status: 'success',
      title: '저장 되었습니다.',
    });
  };

  const createUsingExample = async (data) => {
    setUsingExamples([data, ...usingExamples]);
  };

  const updateUsingExample = async (updateIndex, data) => {
    setUsingExamples(
      usingExamples.map((el, index) => {
        if (index === updateIndex) {
          return data;
        }
        return el;
      }),
    );
  };

  const openUpdateModal = (data, index) => {
    setUpdateData({ ...data, index });
    updateOnOpen();
  };

  const deleteUsingExample = async (url) => {
    if (confirm('삭제하시겠습니까?')) {
      setUsingExamples(usingExamples.filter((el) => el.url !== url));
    }
  };

  const move = (url, direction) => {
    const newUsingExamples = [...usingExamples];
    const currentItemIndex = newUsingExamples.map((el) => el.url).indexOf(url);

    if (direction === 'left') {
      if (currentItemIndex === 0) {
        return;
      }
      const temp = newUsingExamples[currentItemIndex - 1];
      newUsingExamples[currentItemIndex - 1] =
        newUsingExamples[currentItemIndex];
      newUsingExamples[currentItemIndex] = temp;
    } else {
      if (currentItemIndex === usingExamples.length - 1) {
        return;
      }
      const temp = newUsingExamples[currentItemIndex + 1];
      newUsingExamples[currentItemIndex + 1] =
        newUsingExamples[currentItemIndex];
      newUsingExamples[currentItemIndex] = temp;
    }
    setUsingExamples(newUsingExamples);
  };

  return (
    <>
      <Flex>
        <Button onClick={onOpen} colorScheme={'red'} size={'md'}>
          새로운 아이템
        </Button>
        <Spacer />
        <Button colorScheme="green" mb={30} onClick={saveUsingExample}>
          변경사항 저장
        </Button>
      </Flex>
      <SimpleGrid columns={3} spacing={5}>
        {usingExamples.map((el, index) => {
          return (
            <ContentCard
              move={move}
              key={el.url}
              index={index}
              deleteUsingExample={deleteUsingExample}
              openUpdateModal={openUpdateModal}
              data={el}
            ></ContentCard>
          );
        })}
        <CreateModal
          isOpen={isOpen}
          onClose={onClose}
          createUsingExample={createUsingExample}
        />
        {updateIsOpen && (
          <UpdateModal
            updateData={updateData}
            isOpen={updateIsOpen}
            onClose={updateOnClose}
            updateUsingExample={updateUsingExample}
          />
        )}
      </SimpleGrid>
    </>
  );
}

function UpdateModal({ isOpen, onClose, updateUsingExample, updateData }) {
  const [url, setUrl] = useState(updateData.url);
  const [name, setName] = useState(updateData.name);
  const [location, setLocation] = useState(updateData.location);
  const [type, setType] = useState(updateData.type);
  const [profileImageUrl, setProfileImageUrl] = useState(
    updateData.profileImageUrl,
  );
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState(
    updateData.thumbnailImageUrl,
  );
  const [urlProgress, setUrlProgress] = useState(null);
  const [thumbnailProgress, setThumbnailProgress] = useState(null);
  const [profileProgress, setProfileProgress] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const changeName = (e) => {
    setName(e.target.value);
  };

  const changeType = (e) => {
    setType(e.target.value);
  };

  const create = () => {
    if (
      !url ||
      !name ||
      !type ||
      !profileImageUrl ||
      !thumbnailImageUrl ||
      !location
    ) {
      toast({
        position: 'top',
        status: 'error',
        title: '모든 항목을 작성해주세요',
      });
    } else {
      updateUsingExample(updateData.index, {
        name,
        url,
        location,
        type,
        profileImageUrl,
        thumbnailImageUrl,
      });
      setName('');
      setUrl('');
      setType('');
      setLocation('');
      setThumbnailImageUrl('');
      setProfileImageUrl('');
      setThumbnailProgress('');
      setProfileProgress('');
      setUrlProgress('');
      setIsUploading(false);
      onClose();
    }
  };

  const setProgress = (text, type) => {
    if (type === 'url') {
      setUrlProgress(text);
    } else if (type === 'thumbnailImageUrl') {
      setThumbnailProgress(text);
    } else {
      setProfileProgress(text);
    }
  };

  const uploadFileToS3 = async ({ file }, type) => {
    setIsUploading(true);
    setProgress(`업로드 준비중`, type);

    const upload = uploadFile(file, file.name);
    upload.on('httpUploadProgress', (progress) => {
      const { loaded, total, Key } = progress;
      const pro = Math.floor((loaded / total) * 100);
      setProgress(`업로드 중 - ${pro}`, type);
      if (pro === 100) {
        setIsUploading(false);
        setProgress(`업로드 완료 - ${file.name}`, type);
        const uploadUrl = `https://beam-monster-backend.s3.ap-northeast-2.amazonaws.com/${Key}`;
        if (type === 'url') {
          setUrl(uploadUrl);
        } else if (type === 'thumbnailImageUrl') {
          setThumbnailImageUrl(uploadUrl);
        } else {
          setProfileImageUrl(uploadUrl);
        }
      }
    });
    await upload.done();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>수정</ModalHeader>
        <ModalBody>
          <FormControl mb={6}>
            <FormLabel>이름</FormLabel>
            <Input value={name} onChange={changeName} />
          </FormControl>
          <FormControl mb={6}>
            <FormLabel>위치</FormLabel>
            <Input
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </FormControl>
          <FormControl mb={6}>
            <FormLabel>타입</FormLabel>
            <Input value={type} onChange={changeType} />
          </FormControl>
          <FormLabel>영상 파일</FormLabel>
          <Upload
            customRequest={(e) => uploadFileToS3(e, 'url')}
            accept="video/*"
          >
            <Button>파일 선택</Button>
          </Upload>
          <Text>{urlProgress && `${urlProgress}`}</Text>
          <FormLabel mt={6}>썸네일 이미지</FormLabel>
          <Upload
            customRequest={(e) => uploadFileToS3(e, 'thumbnailImageUrl')}
            accept="image/*"
          >
            <Button>파일 선택</Button>
          </Upload>
          <Text>{thumbnailProgress && `${thumbnailProgress}`}</Text>
          <FormLabel mt={6}>프로필 이미지</FormLabel>
          <Upload
            customRequest={(e) => uploadFileToS3(e, 'profileImageUrl')}
            accept="image/*"
          >
            <Button>파일 선택</Button>
          </Upload>
          <Text>{profileProgress && `${profileProgress}`}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={create}
            disabled={isUploading}
          >
            저장
          </Button>
          <Button variant="ghost" onClick={onClose}>
            취소
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function CreateModal({ isOpen, onClose, createUsingExample }) {
  const [url, setUrl] = useState('');
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [type, setType] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState('');
  const [urlProgress, setUrlProgress] = useState(null);
  const [thumbnailProgress, setThumbnailProgress] = useState(null);
  const [profileProgress, setProfileProgress] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const changeName = (e) => {
    setName(e.target.value);
  };

  const changeType = (e) => {
    setType(e.target.value);
  };

  const create = () => {
    if (
      !url ||
      !name ||
      !type ||
      !profileImageUrl ||
      !thumbnailImageUrl ||
      !location
    ) {
      toast({
        position: 'top',
        status: 'error',
        title: '모든 항목을 작성해주세요',
      });
    } else {
      createUsingExample({
        name,
        url,
        location,
        type,
        profileImageUrl,
        thumbnailImageUrl,
      });
      setName('');
      setUrl('');
      setType('');
      setLocation('');
      setThumbnailImageUrl('');
      setProfileImageUrl('');
      setThumbnailProgress('');
      setProfileProgress('');
      setUrlProgress('');
      setIsUploading(false);
      onClose();
    }
  };

  const setProgress = (text, type) => {
    if (type === 'url') {
      setUrlProgress(text);
    } else if (type === 'thumbnailImageUrl') {
      setThumbnailProgress(text);
    } else {
      setProfileProgress(text);
    }
  };

  const uploadFileToS3 = async ({ file }, type) => {
    setIsUploading(true);
    setProgress(`업로드 준비중`, type);

    const upload = uploadFile(file, file.name);
    upload.on('httpUploadProgress', (progress) => {
      const { loaded, total, Key } = progress;
      const pro = Math.floor((loaded / total) * 100);
      setProgress(`업로드 중 - ${pro}`, type);
      if (pro === 100) {
        setIsUploading(false);
        setProgress(`업로드 완료 - ${file.name}`, type);
        const uploadUrl = `https://beam-monster-backend.s3.ap-northeast-2.amazonaws.com/${Key}`;
        if (type === 'url') {
          setUrl(uploadUrl);
        } else if (type === 'thumbnailImageUrl') {
          setThumbnailImageUrl(uploadUrl);
        } else {
          setProfileImageUrl(uploadUrl);
        }
      }
    });
    await upload.done();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>신규 생성</ModalHeader>
        <ModalBody>
          <FormControl mb={6}>
            <FormLabel>이름</FormLabel>
            <Input value={name} onChange={changeName} />
          </FormControl>
          <FormControl mb={6}>
            <FormLabel>위치</FormLabel>
            <Input
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </FormControl>
          <FormControl mb={6}>
            <FormLabel>타입</FormLabel>
            <Input value={type} onChange={changeType} />
          </FormControl>
          <FormLabel>영상 파일</FormLabel>
          <Upload
            customRequest={(e) => uploadFileToS3(e, 'url')}
            accept="video/*"
          >
            <Button>파일 선택</Button>
          </Upload>
          <Text>{urlProgress && `${urlProgress}`}</Text>
          <FormLabel mt={6}>썸네일 이미지</FormLabel>
          <Upload
            customRequest={(e) => uploadFileToS3(e, 'thumbnailImageUrl')}
            accept="image/*"
          >
            <Button>파일 선택</Button>
          </Upload>
          <Text>{thumbnailProgress && `${thumbnailProgress}`}</Text>
          <FormLabel mt={6}>프로필 이미지</FormLabel>
          <Upload
            customRequest={(e) => uploadFileToS3(e, 'profileImageUrl')}
            accept="image/*"
          >
            <Button>파일 선택</Button>
          </Upload>
          <Text>{profileProgress && `${profileProgress}`}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={create}
            disabled={isUploading}
          >
            저장
          </Button>
          <Button variant="ghost" onClick={onClose}>
            취소
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ContentCard({
  data,
  openUpdateModal,
  deleteUsingExample,
  move,
  index,
}) {
  return (
    <Box>
      <Box width="100%" border="1px" borderColor={'gray.200'}>
        <video width="100%" controls autoPlay={false}>
          <source src={data.url} />
        </video>
      </Box>
      <Flex>
        <IconButton
          onClick={() => move(data.url, 'left')}
          variant={'ghost'}
          icon={<ArrowLeftIcon />}
        ></IconButton>
        <Spacer />
        <Center h="40px">
          <Avatar src={data.profileImageUrl} size="sm" mr="2" />
          <Text fontSize="sm">
            {data.name}(${data.location})-{data.type}
          </Text>
        </Center>

        <Spacer />
        <IconButton
          onClick={() => move(data.url, 'right')}
          variant={'ghost'}
          icon={<ArrowRightIcon />}
        ></IconButton>
      </Flex>
      <Button
        onClick={() => openUpdateModal(data, index)}
        variant={'solid'}
        colorScheme={'yellow'}
        w={'100%'}
        size={'xs'}
      >
        수정
      </Button>
      <Button
        onClick={() => deleteUsingExample(data.url)}
        variant={'solid'}
        colorScheme={'red'}
        w={'100%'}
        size={'xs'}
      >
        삭제
      </Button>
    </Box>
  );
}

export default MainpageUsingExample;
