import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/layout';
import { Descriptions, Image, Table } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { commaThreeDigit } from 'utils';
import { CreatorOrderStatus } from 'utils';

export default function CreatorOrderDetail() {
  const [order, setOrder] = useState();
  const param = useParams();

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get('/creators/orders/' + param.order_id);
      setOrder(data);
    };
    getData();
  }, []);

  if (!order) return <></>;

  return (
    <Box mt="120px">
      <Descriptions bordered title="판매 상세 정보" size={'small'}>
        <Descriptions.Item label="주문번호">
          {order.merchant_uid}
        </Descriptions.Item>
        <Descriptions.Item label="주문 가격">
          {commaThreeDigit(order.totalPrice)} 원
        </Descriptions.Item>
        <Descriptions.Item label="주문자명">
          <Link to={`/admin/users/${order.buyer.id}`}>
            {`${order.buyer.name}(${order.buyer.phone})`}
            <ExternalLinkIcon mx="2px" />
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="주문상태">
          {CreatorOrderStatus(order.status)}
        </Descriptions.Item>
        <Descriptions.Item label="결제방식">
          {order.paymentMethod}
        </Descriptions.Item>
        <Descriptions.Item label="결제일">
          {dayjs(order.paidDate).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="결제 콘텐츠">
          <Table
            size="small"
            dataSource={order.contents}
            columns={[
              {
                title: '콘텐츠명',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: '가격',
                dataIndex: 'price',
                key: 'price',
                render: (value) => commaThreeDigit(value) + ' 원',
              },
              {
                title: '썸네일',
                dataIndex: 'thumbnailUrl',
                key: 'thumbnailUrl',
                render: (value) => (
                  <>
                    <Image width="100px" src={value}></Image>
                  </>
                ),
              },
            ]}
          />
        </Descriptions.Item>
        <Descriptions.Item span={3} label="세금계산서 등록정보">
          <p>이메일주소: {order.businessEmail || '-'}</p>
          <p>사업자등록번호: {order.registrationNumber || '-'}</p>
          <p>사업자명: {order.businessName || '-'}</p>
          <p>
            사업자등록증 이미지:{' '}
            {order.businessRegistrationImgUrl ? (
              <a href={order.businessRegistrationImgUrl} download>
                다운로드
              </a>
            ) : (
              '-'
            )}
          </p>
        </Descriptions.Item>
      </Descriptions>
    </Box>
  );
}
