import { Button } from '@chakra-ui/button';
import { AddIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Box, Container, Flex, Heading, Spacer, Text } from '@chakra-ui/layout';
import {
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
} from '@chakra-ui/table';
import { Textarea } from '@chakra-ui/textarea';
import { useToast } from '@chakra-ui/toast';
import { Card } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { uploadFile } from 'utils';

export default function CreatorList() {
  const [creator, setCreator] = useState({
    name: '',
    description: '',
    profileImageUrl: '',
  });
  const [updateCreator, setUpdateCreator] = useState(null);
  const [creators, setCreators] = useState([]);
  const toast = useToast();

  const getData = async () => {
    const { data } = await axios.get('/creators');
    setCreators(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCreate = async () => {
    const { name, description, profileImageUrl } = creator;
    if (!name || !description || !profileImageUrl) {
      return toast({
        title: '모든 항목을 입력해주세요.',
        status: 'warning',
        isClosable: true,
      });
    }

    await axios.post('/creators', {
      name,
      description,
      profileImageUrl,
    });
    setCreator({ name: '', description: '', profileImageUrl: '' });
    toast({
      title: '생성 되었습니다.',
      status: 'success',
      isClosable: true,
    });
    getData();
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const upload = uploadFile(file, '프로필이미지.jpg', false);
    upload.on('httpUploadProgress', (progress) => {
      const { loaded, total, Key } = progress;
      const pro = Math.floor((loaded / total) * 100);
      if (pro === 100) {
        if (updateCreator) {
          setUpdateCreator({
            ...updateCreator,
            profileImageUrl: `https://beam-monster-backend.s3.ap-northeast-2.amazonaws.com/${Key}`,
          });
        } else {
          setCreator({
            ...creator,
            profileImageUrl: `https://beam-monster-backend.s3.ap-northeast-2.amazonaws.com/${Key}`,
          });
        }
      }
    });
    await upload.done();
  };

  const handleUpdateSelect = (creator) => {
    setUpdateCreator(creator);
  };

  const onUpdateSubmit = async () => {
    const { name, description, profileImageUrl } = updateCreator;

    await axios.patch('/creators/' + updateCreator.id, {
      name,
      description,
      profileImageUrl,
    });
    setUpdateCreator({ name: '', description: '', profileImageUrl: '' });
    toast({
      title: '수정 되었습니다.',
      status: 'success',
      isClosable: true,
    });
    getData();
  };

  const handleUpdateCancel = () => {
    setUpdateCreator(null);
  };

  const handleDelete = async (creator) => {};

  return (
    <Container mt="80px" centerContent={false} maxWidth="100%">
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
        <Heading mb="30px" size="md">
          작가 관리
        </Heading>
        <Box mt={10}>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>번호</Th>
                  <Th>프로필이미지</Th>
                  <Th>이름</Th>
                  <Th>설명</Th>
                  <Th>좋아요 수</Th>
                  <Th>판매 수</Th>
                  <Th>기타</Th>
                </Tr>
              </Thead>
              <Tbody>
                {creators.map((el) => {
                  return (
                    <Tr key={el.id}>
                      <Td>{el.id}</Td>
                      <Td>
                        <img
                          src={el.profileImageUrl}
                          style={{ width: 50, height: 50 }}
                        />
                      </Td>
                      <Td>{el.name}</Td>
                      <Td style={{ whiteSpace: 'pre-line' }}>
                        {el.description}
                      </Td>
                      <Td>{el.likeCount}</Td>
                      <Td>{el.sellCount}</Td>
                      <Td>
                        <Button onClick={() => handleUpdateSelect(el)}>
                          수정
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Box
            mt="10"
            borderWidth="1px"
            borderRadius="lg"
            p="6"
            backgroundColor="gray.50"
          >
            {updateCreator ? (
              <>
                <Heading mb="20px" size="md">
                  작가 수정
                </Heading>
                <label>작가명</label>
                <Input
                  mb="4"
                  value={updateCreator.name}
                  onChange={(e) =>
                    setUpdateCreator({ ...updateCreator, name: e.target.value })
                  }
                  backgroundColor="white"
                />
                <label>작가설명</label>
                <Textarea
                  mb="4"
                  backgroundColor="white"
                  value={updateCreator.description}
                  onChange={(e) =>
                    setUpdateCreator({
                      ...updateCreator,
                      description: e.target.value,
                    })
                  }
                />
                <label>프로필이미지</label>
                <br />
                <input
                  type="file"
                  onChange={handleUpload}
                  accept="image/*"
                  onClick={(e) => (e.target.value = null)}
                />
                {updateCreator.profileImageUrl && (
                  <img
                    src={updateCreator.profileImageUrl}
                    style={{ width: 100, height: 100 }}
                  />
                )}
                <Flex>
                  <Button
                    mt="4"
                    style={{ width: 100 }}
                    colorScheme="green"
                    onClick={onUpdateSubmit}
                  >
                    수정
                  </Button>
                  <Button
                    mt="4"
                    style={{ width: 100 }}
                    onClick={handleUpdateCancel}
                    variant="ghost"
                  >
                    취소
                  </Button>
                  <Spacer />
                  <Button
                    mt="4"
                    style={{ width: 100 }}
                    onClick={handleUpdateCancel}
                    colorScheme="red"
                    variant="ghost"
                  >
                    삭제
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <Heading mb="20px" size="md">
                  새로운 작가 생성
                </Heading>
                <label>작가명</label>
                <Input
                  mb="4"
                  value={creator.name}
                  onChange={(e) =>
                    setCreator({ ...creator, name: e.target.value })
                  }
                  backgroundColor="white"
                />
                <label>작가설명</label>
                <Textarea
                  mb="4"
                  backgroundColor="white"
                  value={creator.description}
                  onChange={(e) =>
                    setCreator({ ...creator, description: e.target.value })
                  }
                />
                <label>프로필이미지</label>
                <br />
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleUpload}
                  onClick={(e) => (e.target.value = null)}
                />
                {creator.profileImageUrl && (
                  <img
                    src={creator.profileImageUrl}
                    style={{ width: 100, height: 100 }}
                  />
                )}
                <div>
                  <Button
                    mt="4"
                    style={{ width: 100 }}
                    colorScheme="green"
                    onClick={handleCreate}
                  >
                    작가생성
                  </Button>
                </div>
              </>
            )}
          </Box>
        </Box>
      </Card>
    </Container>
  );
}
