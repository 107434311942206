import { Flex, Box } from '@chakra-ui/layout';
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { Pagination, Select } from 'antd';
import Input from 'antd/lib/input/Input';
import axios from 'axios';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { usePagination } from 'hooks/use-pagination';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AdvertiseStoreTableRow from './AdvertiseStoreTableRow';

export const AdvertiseStoreList = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [stores, setStores] = useState([]);
  const [filter, setFilter] = useState({
    locations: [],
    categories: [],
    searchWord: '',
    defaultCategories: [],
    defaultLocations: [
      '서울',
      '경기',
      '인천',
      '대전',
      '세종',
      '충남',
      '충북',
      '강원',
      '부산',
      '대구',
      '울산',
      '경남',
      '경북',
      '광주',
      '전남',
      '전북',
      '제주',
    ],
  });

  const { currentPage, total, onPageChange, setSearch, setQuerystring } =
    usePagination('/admin/store-users', setStores);

  useEffect(() => {
    const getDefaultStoreUserCategory = async () => {
      const res = await axios.get('/service-statics/business-categories');
      const data = res.data.value.data;
      setFilter({
        ...filter,
        defaultCategories: data.map((el) => ({
          label: el.category,
          value: el.category,
        })),
      });
    };
    getDefaultStoreUserCategory();
  }, []);

  const colNames = [
    '업체명',
    '지역',
    '업종',
    '구좌A',
    '구좌B',
    '구좌C',
    '구좌D',
    '구좌E',
    '구좌F',
  ];

  const onExcelDownloadClick = () => {
    console.log('야호');
  };

  const goDetail = (id) => {
    history.push('/admin/advertise/stores/' + id);
  };

  const handleCategoryFilterChange = (categories) => {
    setFilter({ ...filter, categories });
  };

  const handleLocationFilterChange = (locations) => {
    setFilter({ ...filter, locations });
  };

  const handleFilterSearch = () => {
    setQuerystring(
      `search=${filter.searchWord}&locations=[${filter.locations.map(
        (el) => `"${el}"`,
      )}]&categories=[${filter.categories.map((el) => `"${el}"`)}]`,
    );
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader p="6px 0px 22px 0px">
            <Flex width="100%">
              <Box>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  광고 구좌 목록
                </Text>
              </Box>
            </Flex>
          </CardHeader>
          <CardHeader>
            <div
              style={{
                display: 'block',
                width: '100%',
                maxWidth: '800px',
                border: '1px solid silver',
                padding: '1rem 2rem',
              }}
            >
              <div>
                <p>업종명(옵션)</p>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="카테고리 검색"
                  onChange={handleCategoryFilterChange}
                  options={filter.defaultCategories}
                />
              </div>
              <div>
                <p>지역(옵션)</p>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="지역검색"
                  onChange={handleLocationFilterChange}
                  options={filter.defaultLocations.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                />
              </div>
              <div>
                <p>업체명</p>
                <Input
                  placeholder="업체명"
                  value={filter.searchWord}
                  onChange={(e) =>
                    setFilter({ ...filter, searchWord: e.target.value })
                  }
                ></Input>
              </div>
              <Button
                size="sm"
                style={{ width: '100%', border: '0', marginTop: '4px' }}
                colorScheme="blue"
                onClick={handleFilterSearch}
              >
                검색
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <p style={{ marginTop: '50px' }}>
              * 금일 송출되고 있는 광고 구좌가 표시됩니다. 이전, 이후의 광고
              결제건들을 보려면 업체를 눌러서 상세페이지로 이동하세요.
            </p>
          </CardBody>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  {colNames.map((colName, idx) => {
                    return (
                      <Th
                        color="gray.400"
                        key={idx}
                        ps={idx === 0 ? '0px' : null}
                      >
                        {colName}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {stores.map((row) => {
                  return (
                    <Tr key={row.id} onClick={() => goDetail(row.id)}>
                      <AdvertiseStoreTableRow data={row} />
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
          <Box mt="5">
            <Pagination
              showSizeChanger={false}
              current={currentPage}
              onChange={onPageChange}
              total={total}
            ></Pagination>
          </Box>
        </Card>
      </Flex>
    </>
  );
};
