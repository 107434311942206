// Chakra imports
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Input,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Empty, List } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import axios from 'axios';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
// assets
import BarChart from 'components/Charts/BarChart';
import LineChart from 'components/Charts/LineChart';
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
} from 'components/Icons/Icons.js';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { commaThreeDigit } from 'utils';
import ActiveUsers from './components/ActiveUsers';
import MiniStatistics from './components/MiniStatistics';
import SalesOverview from './components/SalesOverview';

export default function Dashboard() {
  const [todayData, setTodayData] = useState({});
  const [totalData, setTotalData] = useState({});
  const [weekData, setWeekData] = useState({});
  const [monthData, setMonthData] = useState({});
  const [loading, setLoading] = useState(true);
  const [contentRankRange, setContentRankRange] = useState([
    dayjs().subtract(30, 'd').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ]);
  const [creatorRank, setCreatorRank] = useState([]);
  const [contentRank, setContentRank] = useState([]);
  const iconBoxInside = useColorModeValue('white', 'white');

  const getCreatorData = async () => {
    const { data } = await axios.get('creators/admin-creator-mainpage', {
      params: {
        creatorStartDate: contentRankRange[0],
        creatorEndDate: contentRankRange[1],
        contentStartDate: contentRankRange[0],
        contentEndDate: contentRankRange[1],
      },
    });
    const { creatorRank, contentRank } = data;
    setCreatorRank(creatorRank);
    setContentRank(contentRank);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res = await axios.get('logs/admin-dashboard');
      const {
        todayCount,
        totalCount,
        recentMonthlyCounts,
        recentWeeklyCounts,
      } = res.data;
      setTodayData(todayCount);
      setTotalData(totalCount);
      setWeekData(recentWeeklyCounts);
      setMonthData(recentMonthlyCounts);
      setLoading(false);
    };

    getData();
    getCreatorData();
  }, []);

  const updateRank = () => {
    const a = dayjs(contentRankRange[0], 'YYYY-MM-DD').isValid();
    const b = dayjs(contentRankRange[1], 'YYYY-MM-DD').isValid();
    if (!a || !b) {
      return alert('시작일 종료일을 채워주세요.');
    }
    getCreatorData();
  };

  if (loading) {
    return null;
  }

  return (
    <Flex flexDirection="column" pt={{ base: '120px', md: '75px' }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
        <MiniStatistics
          title={'금일 컨텐트 시청 횟수'}
          amount={todayData.contentView}
          icon={<WalletIcon h={'24px'} w={'24px'} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={'금일 신규 유저'}
          amount={todayData.userRegister}
          icon={<GlobeIcon h={'24px'} w={'24px'} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={'금일 견적서 완료'}
          amount={todayData.estimateComplete}
          icon={<DocumentIcon h={'24px'} w={'24px'} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={'금일 계약 완료'}
          amount={todayData.contractComplete}
          icon={<CartIcon h={'24px'} w={'24px'} color={iconBoxInside} />}
        />
      </SimpleGrid>
      <Grid
        templateColumns={{ sm: '1fr', lg: '1.3fr 1.7fr' }}
        templateRows={{ sm: 'repeat(2, 1fr)', lg: '1fr' }}
        gap="24px"
        my="26px"
      >
        <BarChart data={weekData} rowSpan={2} />
        <SalesOverview chart={<LineChart data={monthData} />} />
      </Grid>
      <ActiveUsers data={totalData} title={'전체 정보'} percentage={23} />
      <Flex mt={10} gap="2" justifyContent="center" width="100%" align="center">
        <Box>
          <Input
            placeholder="시작"
            size="md"
            type="date"
            onChange={(e) =>
              setContentRankRange([
                dayjs(e.target.value).format('YYYY-MM-DD'),
                contentRankRange[1],
              ])
            }
            value={contentRankRange[0]}
          />
        </Box>
        <Box>~</Box>
        <Box>
          <Input
            placeholder="종료"
            size="md"
            type="date"
            onChange={(e) => {
              setContentRankRange([
                contentRankRange[0],
                dayjs(e.target.value).format('YYYY-MM-DD'),
              ]);
            }}
            value={contentRankRange[1]}
          />
        </Box>
        <Box>
          <Button size="md" onClick={updateRank} colorScheme="green">
            기간 업데이트
          </Button>
        </Box>
      </Flex>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing="24px">
        <Box flex={1}>
          <Card minH="83px">
            <CardHeader>
              <Flex justifyContent="space-between" w="100%">
                <Box>
                  <Text fontSize="lg" fontWeight="bold">
                    작가 판매 순위
                  </Text>
                </Box>
              </Flex>
            </CardHeader>
            <CardBody mt={2}>
              {creatorRank.length === 0 ? (
                <div style={{ width: '100%' }}>
                  <div style={{ margin: 'auto' }}>
                    <Empty description="정보가 없습니다" />
                  </div>
                </div>
              ) : (
                <>
                  <List
                    style={{ width: '100%' }}
                    dataSource={creatorRank}
                    renderItem={(item) => (
                      <List.Item key={item.creator.id}>
                        <List.Item.Meta
                          avatar={<Avatar src={item.creator.profileImageUrl} />}
                          title={item.creator.name}
                          description={
                            commaThreeDigit(item.totalPrice) +
                            ` 원 (주문횟수 ${item.count} 건)`
                          }
                        />
                      </List.Item>
                    )}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </Box>
        <Box flex={1}>
          <Card minH="83px">
            <CardHeader>
              <Flex justifyContent="space-between" w="100%">
                <Box>
                  <Text fontSize="lg" fontWeight="bold">
                    작가 콘텐츠 판매 순위
                  </Text>
                </Box>
              </Flex>
            </CardHeader>
            <CardBody mt={2}>
              {contentRank.length === 0 ? (
                <div style={{ width: '100%' }}>
                  <div style={{ margin: 'auto' }}>
                    <Empty description="정보가 없습니다" />
                  </div>
                </div>
              ) : (
                <List
                  style={{ width: '100%' }}
                  dataSource={contentRank}
                  renderItem={(item) => (
                    <List.Item key={item.content.id}>
                      <List.Item.Meta
                        avatar={<Avatar src={item.content.thumbnailUrl} />}
                        title={item.content.title}
                        description={
                          commaThreeDigit(item.totalPrice) +
                          ` 원 (주문횟수 ${item.count} 건)`
                        }
                      />
                    </List.Item>
                  )}
                />
              )}
            </CardBody>
          </Card>
        </Box>
      </SimpleGrid>
    </Flex>
  );
}
