import { Flex, Box } from '@chakra-ui/layout';
import {
  Table,
  Spacer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import axios from 'axios';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { useAuth } from 'hooks/use-auth';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AdminUserTableRow } from './AdminUserTableRow';

export const AdminUserList = () => {
  const [adminUsers, setAdminUsers] = useState([]);
  const textColor = useColorModeValue('gray.700', 'white');

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get('/admin/users');
      setAdminUsers(data);
    };
    getData();
  }, []);

  const colNames = ['번호', '프로필', '계정명', '연락처', '권한', ''];

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader p="6px 0px 22px 0px">
            <Flex width="100%">
              <Box>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  어드민 유저 관리
                </Text>
              </Box>
              <Spacer></Spacer>
              <Box>
                <NavLink to="/admin/admin-users/write">
                  <Button fontSize="l">등록</Button>
                </NavLink>
              </Box>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  {colNames.map((colName, idx) => {
                    return (
                      <Th color="gray.400" key={idx}>
                        {colName}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {adminUsers.map((row) => {
                  return <AdminUserTableRow key={row.id} adminUser={row} />;
                })}
              </Tbody>
            </Table>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};
