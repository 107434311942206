import { Td } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { AdvertiseOrderStatus } from 'utils';

function AdvertiseOrderTableRow(props) {
  const { data } = props;

  return (
    <>
      <Td>{data.merchant_uid}</Td>
      <Td>{data.buyer.name}</Td>
      <Td>{data.category}</Td>
      <Td>{data.advertises.length}</Td>
      <Td>{data.price}</Td>
      <Td>{dayjs(data.paidDate).format('YYYY-MM-DD HH:mm')}</Td>
      <Td>{AdvertiseOrderStatus(data.status)}</Td>
      <Td>{data.isConfirm ? '완료' : '대기'}</Td>
    </>
  );
}

export default AdvertiseOrderTableRow;
