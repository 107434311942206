// import
import Dashboard from 'views/Dashboard/Dashboard';

import { HomeIcon, PersonIcon, RocketIcon } from 'components/Icons/Icons';
import { SettingsIcon } from 'components/Icons/Icons';
import { SupportIcon } from 'components/Icons/Icons';
import { HelpIcon } from 'components/Icons/Icons';
import { ContentList } from 'views/Admin/Content/ContentList';
import { AfterServiceList } from 'views/Admin/AfterService/AfterServiceList';
import { UserList } from 'views/Admin/User/UserList';
import { AdminUserList } from 'views/Admin/AdminUser/AdminUserList';
import { EstimateList } from 'views/Admin/Estimate/EstimateList';
import { ContractList } from 'views/Admin/Contract/ContractList';
import FooterInfo from 'views/Admin/StaticServiceInfo/FooterInfo';
import DynamicInfo from 'views/Admin/StaticServiceInfo/DynamicInfo';
import { ContactList } from 'views/Admin/Contact/ContactList';
import { InstallSchedule } from 'views/Admin/InstallSchedule';
import { ServiceManagerList } from 'views/Admin/ServiceManager/ServiceManagerList';
import { AdvertiseOrderList } from 'views/Admin/Advertise/AdvertiseOrderList';
import { AdvertiseStoreList } from 'views/Admin/Advertise/Stores/AdvertiseStoreList';
import { StarIcon, SunIcon } from '@chakra-ui/icons';
import CreatorContentList from 'views/Admin/CreatorContent/CreatorContentList';
import CreatorOrderList from 'views/Admin/CreatorContent/CreatorOrderList';

var dashRoutes = [
  {
    path: '/dashboard',
    name: '대시보드',
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: '/admin',
  },
  {
    path: '/contents',
    name: '빔몬스터 콘텐츠 관리',
    icon: <RocketIcon color="inherit" />,
    component: ContentList,
    layout: '/admin',
  },
  {
    path: '/creator-contents',
    name: '작가 콘텐츠 관리',
    icon: <SunIcon color="inherit" />,
    component: CreatorContentList,
    layout: '/admin',
  },
  {
    path: '/creator-content-orders',
    name: '작가 콘텐츠 판매내역',
    icon: <StarIcon color="inherit" />,
    component: CreatorOrderList,
    layout: '/admin',
  },
  {
    path: '/after-services',
    name: 'A/S 관리',
    icon: <HelpIcon color="inherit" />,
    component: AfterServiceList,
    layout: '/admin',
  },
  {
    path: '/contacts',
    name: '제휴문의',
    icon: <HelpIcon color="inherit" />,
    component: ContactList,
    layout: '/admin',
  },
  {
    name: '유저 관리',
    category: 'user',
    collapse: true,
    views: [
      {
        path: '/users',
        name: '일반 유저',
        icon: <PersonIcon color="inherit" />,
        component: UserList,
        layout: '/admin',
      },
      {
        path: '/admin-users',
        name: '어드민 유저',
        icon: <SupportIcon color="inherit" />,
        component: AdminUserList,
        layout: '/admin',
      },
      {
        path: '/service-managers',
        name: '서비스 기사 관리',
        icon: <SupportIcon color="inherit" />,
        component: ServiceManagerList,
        layout: '/admin',
      },
    ],
  },
  {
    name: '솔루션 관리',
    category: 'solution',
    collapse: true,
    views: [
      {
        path: '/estimates',
        name: '견적서',
        icon: <RocketIcon color="inherit" />,
        component: EstimateList,
        layout: '/admin',
      },
      {
        path: '/contracts',
        name: '계약서',
        icon: <SupportIcon color="inherit" />,
        component: ContractList,
        layout: '/admin',
      },
      {
        path: '/schedules',
        name: '방문예정스케쥴',
        icon: <PersonIcon color="inherit" />,
        component: InstallSchedule,
        layout: '/admin',
      },
    ],
  },
  {
    name: '사이트 관리',
    category: 'service-static',
    collapse: true,
    views: [
      {
        path: '/static-service-info/dynamic',
        name: '동적 정보',
        icon: <SupportIcon color="inherit" />,
        component: DynamicInfo,
        layout: '/admin',
      },
      {
        path: '/static-service-info/footer',
        name: '사이트 정보',
        icon: <SettingsIcon color="inherit" />,
        component: FooterInfo,
        layout: '/admin',
      },
    ],
  },
  {
    name: '광고 관리',
    category: 'advertise',
    collapse: true,
    views: [
      {
        path: '/advertise/orders',
        name: '판매 내역',
        icon: <SettingsIcon color="inherit" />,
        component: AdvertiseOrderList,
        layout: '/admin',
      },
      {
        path: '/advertise/stores',
        name: '구좌 조회',
        icon: <SupportIcon color="inherit" />,
        component: AdvertiseStoreList,
        layout: '/admin',
      },
    ],
  },
];
export default dashRoutes;
