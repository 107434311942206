import { Button } from '@chakra-ui/button';
import { Input } from '@chakra-ui/input';
import { Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Textarea } from '@chakra-ui/textarea';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import dayjs from 'dayjs';
import { useAuth } from 'hooks/use-auth';
import { useEffect, useState } from 'react';

const ModalType = {
  create: 'CREATE',
  update: 'UPDATE',
  read: 'READ',
};

export const InstallScheduleModal = (props) => {
  const isShow = props.isShow | false;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(ModalType.create);
  const { user } = useAuth();
  const [schedule, setSchedule] = useState({});
  const toast = useToast();

  useEffect(() => {
    if (isShow) {
      setIsModalOpen(true);
      if (props.selected_id) {
        getSchedule();
      } else {
        setModalType(ModalType.create);
        setSchedule({
          id: props.selected_id,
          title: '',
          memo: '',
          startDate: props.selectedDate,
          endDate: props.selectedDate,
          writer: null,
        });
      }
    }
  }, [isShow]);
  const getSchedule = async () => {
    const res = await axios.get(`/schedules/${props.selected_id}`);
    const data = res.data;

    if (data) {
      setSchedule({
        ...data,
        startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
      });
      if (data.writer.id === user.id) {
        setModalType(ModalType.update);
      } else {
        setModalType(ModalType.read);
      }
    }
  };

  const onClose = () => {
    setIsModalOpen(false);
    props.closeModal({ reload: true });
    setSchedule({
      id: null,
      title: '',
      memo: '',
      startDate: null,
      endDate: null,
      writer: null,
    });
  };

  const onChangeTitle = (e) => {
    setSchedule({
      ...schedule,
      title: e.target.value,
    });
  };
  const onChangeMemo = (e) => {
    setSchedule({
      ...schedule,
      memo: e.target.value,
    });
  };
  const onChangeStartDate = (e) => {
    const date = dayjs(e.target.value, 'YYYY-MM-DD');
    setSchedule({
      ...schedule,
      startDate: date.format('YYYY-MM-DD'),
    });
  };
  const onChangeEndDate = (e) => {
    const date = dayjs(e.target.value, 'YYYY-MM-DD');
    setSchedule({
      ...schedule,
      endDate: date.format('YYYY-MM-DD'),
    });
  };

  const onSubmit = async () => {
    if (!schedule.title || !schedule.startDate || !schedule.endDate) {
      return toast({
        title: '메모를 제외한 모든 항목을 입력해주세요.',
        status: 'warning',
        isClosable: true,
      });
    }
    const isStartDateValid = dayjs(schedule.startDate, 'YYYY-MM-DD').isValid();
    const isEndDateValid = dayjs(schedule.endDate, 'YYYY-MM-DD').isValid();
    if (!isStartDateValid || !isEndDateValid) {
      return toast({
        title: '날짜를 정확히 입력해주세요.',
        status: 'warning',
        isClosable: true,
      });
    }

    try {
      if (modalType === ModalType.create) {
        await axios.post('/schedules', {
          ...schedule,
          startDate: dayjs(schedule.startDate, 'YYYY-MM-DD').toDate(),
          endDate: dayjs(schedule.endDate, 'YYYY-MM-DD').toDate(),
        });
      } else {
        await axios.patch(`/schedules/${schedule.id}`, {
          ...schedule,
          startDate: dayjs(schedule.startDate, 'YYYY-MM-DD').toDate(),
          endDate: dayjs(schedule.endDate, 'YYYY-MM-DD').toDate(),
        });
      }

      onClose();
      return toast({
        title: '저장되었습니다',
        status: 'success',
        isClosable: true,
      });
    } catch (e) {
      alert('서버에러가 발생했습니다');
    }
  };

  const onDelete = async () => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        await axios.delete(`/schedules/${schedule.id}`);

        onClose();
        return toast({
          title: '삭제되었습니다.',
          status: 'success',
          isClosable: true,
        });
      } catch (e) {
        alert('서버에러가 발생했습니다');
      }
    }
  };

  return (
    <>
      <Modal title="스케쥴" isOpen={isModalOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            스케쥴
            {modalType === ModalType.read && ' 조회'}
            {modalType === ModalType.create && ' 생성'}
            {modalType === ModalType.update && ' 수정'}
          </ModalHeader>
          <ModalBody>
            {schedule.writer && (
              <Text mb={4}>작성 - {schedule.writer.name}</Text>
            )}
            <label>제목</label>
            <Input
              placeholder="제목"
              mb="4"
              value={schedule.title}
              onChange={onChangeTitle}
            ></Input>
            <label>시작일</label>
            <Input
              defaultValue={schedule.startDate}
              onChange={onChangeStartDate}
              placeholder="시작일"
              mb="4"
              type="date"
            />
            <label>종료일</label>
            <Input
              defaultValue={schedule.endDate}
              onChange={onChangeEndDate}
              placeholder="종료일"
              mb="4"
              type="date"
            />
            <label>메모</label>
            <Textarea
              value={schedule.memo}
              onChange={onChangeMemo}
              placeholder="내용"
            ></Textarea>
          </ModalBody>

          <ModalFooter>
            {modalType === ModalType.update && (
              <Button mr={3} onClick={onDelete}>
                삭제
              </Button>
            )}
            {modalType !== ModalType.read && (
              <Button colorScheme="red" mr={3} onClick={onSubmit}>
                {modalType === ModalType.update && '수정'}
                {modalType === ModalType.create && '저장'}
              </Button>
            )}
            <Button variant="ghost" onClick={onClose}>
              취소
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
