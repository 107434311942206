import { Button, IconButton } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { useDisclosure } from '@chakra-ui/hooks';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Flex, Spacer, Stack, Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/toast';
import {
  Table,
  Tbody,
  Td,
  Tr,
  Th,
  Thead,
  TableContainer,
} from '@chakra-ui/table';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import { Textarea } from '@chakra-ui/textarea';

function FAQ() {
  const [faqs, setFaqs] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    const getFaqs = async () => {
      const { data } = await axios.get('/service-statics/faqs', {
        params: {
          limit: 10000,
        },
      });
      setFaqs(data.data);
    };
    getFaqs();
  }, []);

  const saveFaqs = async () => {
    await axios.patch('/service-statics/faqs', {
      data: faqs.map((el) => {
        delete el.idx;
        return el;
      }),
    });
    toast({
      position: 'top',
      status: 'success',
      title: '저장 되었습니다.',
    });
  };

  const createFaq = async (data) => {
    setFaqs(faqs.concat(data));
  };

  return (
    <>
      <Flex>
        <Button onClick={onOpen} colorScheme={'red'} size={'md'}>
          새로운 아이템
        </Button>
        <Spacer />
        <Button colorScheme="green" mb={30} onClick={saveFaqs}>
          변경사항 저장
        </Button>
      </Flex>
      <FAQTable faqs={faqs} setFaqs={setFaqs}></FAQTable>
      <CreateModal isOpen={isOpen} onClose={onClose} createFaq={createFaq} />
    </>
  );
}

function CreateModal({ isOpen, onClose, createFaq }) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [isUsingOnMainpage, setIsUsingOnMainpage] = useState(null);
  const toast = useToast();

  const resetState = () => {
    setContent('');
    setTitle('');
    setCategory('');
    setIsUsingOnMainpage(null);
  };

  const create = () => {
    if (!title || !content || !category || isUsingOnMainpage === null) {
      toast({
        position: 'top',
        status: 'error',
        title: '모든 항목을 작성해주세요.',
      });
    } else {
      createFaq({ content, title, category, isUsingOnMainpage });
      resetState();
      onClose();
    }
  };

  const handleRadio = (value) => {
    if (value === 'true') {
      setIsUsingOnMainpage(true);
    } else {
      setIsUsingOnMainpage(false);
    }
  };

  return (
    <Modal
      size={'2xl'}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>신규 생성</ModalHeader>
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel>제목</FormLabel>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </FormControl>
          <FormControl mb={3}>
            <FormLabel>카테고리</FormLabel>
            <Input
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </FormControl>
          <FormLabel>메인페이지 표시</FormLabel>
          <RadioGroup
            mb={3}
            onChange={handleRadio}
            value={isUsingOnMainpage?.toString()}
          >
            <Stack direction="row">
              <Radio isChecked={isUsingOnMainpage} value="true">
                사용
              </Radio>
              <Radio isChecked={!isUsingOnMainpage} value="false">
                미사용
              </Radio>
            </Stack>
          </RadioGroup>
          <FormControl>
            <FormLabel>내용</FormLabel>
            <Textarea
              rows={10}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={create}>
            저장
          </Button>
          <Button variant="ghost" onClick={onClose}>
            취소
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function FAQTable({ faqs, setFaqs }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updateFaqData, setUpdateFaqData] = useState(null);

  const updateFaq = (faq) => {
    const updatedIndex = faq.idx;
    setFaqs(faqs.map((el, idx) => (idx === updatedIndex ? faq : el)));
    setUpdateFaqData(null);
  };

  const deleteFaq = async (targetIndex) => {
    setFaqs(faqs.filter((_el, idx) => targetIndex !== idx));
  };

  const move = (currentItemIndex, direction) => {
    const newFaqs = [...faqs];

    if (direction === 'left') {
      if (currentItemIndex === 0) {
        return;
      }
      const temp = newFaqs[currentItemIndex - 1];
      newFaqs[currentItemIndex - 1] = newFaqs[currentItemIndex];
      newFaqs[currentItemIndex] = temp;
    } else {
      if (currentItemIndex === faqs.length - 1) {
        return;
      }
      const temp = newFaqs[currentItemIndex + 1];
      newFaqs[currentItemIndex + 1] = newFaqs[currentItemIndex];
      newFaqs[currentItemIndex] = temp;
    }
    setFaqs(newFaqs);
  };

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>카테고리</Th>
            <Th>제목</Th>
            <Th>콘텐츠</Th>
            <Th>메인페이지 표시 유무</Th>
            <Th>기능</Th>
          </Tr>
        </Thead>
        <Tbody>
          {faqs.map((el, idx) => {
            return (
              <Tr key={el.title + idx}>
                <Td>{el.category}</Td>
                <Td>{el.title}</Td>
                <Td maxWidth={300}>
                  <Text isTruncated>{el.content}</Text>
                </Td>
                <Td>{el.isUsingOnMainpage.toString() === 'true' && 'O'}</Td>
                <Td>
                  <IconButton
                    onClick={() => move(idx, 'left')}
                    size={'sm'}
                    mr={1}
                    icon={<TriangleUpIcon />}
                  />
                  <IconButton
                    onClick={() => move(idx, 'right')}
                    size={'sm'}
                    mr={2}
                    icon={<TriangleDownIcon />}
                  />
                  <Button
                    size="sm"
                    mr={2}
                    onClick={() => {
                      setUpdateFaqData({ ...el, idx });
                      onOpen(true);
                    }}
                  >
                    수정
                  </Button>
                  <Button
                    colorScheme={'red'}
                    size="sm"
                    onClick={() => deleteFaq(idx)}
                  >
                    삭제
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      {updateFaqData && (
        <ModifyModal
          isOpen={isOpen}
          onClose={onClose}
          updateFaqData={updateFaqData}
          updateFaq={updateFaq}
        />
      )}
    </TableContainer>
  );
}

function ModifyModal({ isOpen, onClose, updateFaqData, updateFaq }) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [isUsingOnMainpage, setIsUsingOnMainpage] = useState(null);
  const toast = useToast();

  useEffect(() => {
    setTitle(updateFaqData?.title);
    setContent(updateFaqData?.content);
    setIsUsingOnMainpage(updateFaqData?.isUsingOnMainpage);
    setCategory(updateFaqData?.category);
  }, [updateFaqData]);

  const resetState = () => {
    setContent('');
    setTitle('');
    setCategory('');
    setIsUsingOnMainpage(null);
  };

  const update = () => {
    if (!title || !content || !category || isUsingOnMainpage === null) {
      toast({
        position: 'top',
        status: 'error',
        title: '모든 항목을 작성해주세요.',
      });
    } else {
      updateFaq({
        content,
        title,
        category,
        isUsingOnMainpage,
        idx: updateFaqData.idx,
      });
      resetState();
      onClose();
    }
  };

  const handleRadio = (value) => {
    if (value === 'true') {
      setIsUsingOnMainpage(true);
    } else {
      setIsUsingOnMainpage(false);
    }
  };

  return (
    <Modal
      size={'2xl'}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>신규 생성</ModalHeader>
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel>제목</FormLabel>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </FormControl>
          <FormControl mb={3}>
            <FormLabel>카테고리</FormLabel>
            <Input
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </FormControl>
          <FormLabel>메인페이지 표시</FormLabel>
          <RadioGroup
            mb={3}
            onChange={handleRadio}
            value={isUsingOnMainpage?.toString()}
          >
            <Stack direction="row">
              <Radio isChecked={isUsingOnMainpage} value="true">
                사용
              </Radio>
              <Radio isChecked={!isUsingOnMainpage} value="false">
                미사용
              </Radio>
            </Stack>
          </RadioGroup>
          <FormControl>
            <FormLabel>내용</FormLabel>
            <Textarea
              rows={10}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={update}>
            수정완료
          </Button>
          <Button variant="ghost" onClick={onClose}>
            취소
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default FAQ;
