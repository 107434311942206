import React, { Component } from 'react';
import Card from 'components/Card/Card';
import Chart from 'react-apexcharts';
import { barChartData, barChartOptions } from 'variables/charts';
import { Text } from '@chakra-ui/layout';

class BarChart extends Component {
  constructor(props) {
    super(props);
    const { data } = props;

    this.state = {
      data: data,
      chartData: [],
      chartOptions: {
        chart: {
          stacked: true,
          events: {
            click: (event, chartContext, config) => {
              event.stopPropagation();
            },
            markerClick: (event, chartContext, config) => {
              event.stopPropagation();
            },
            legendClick: (event, chartContext, config) => {
              event.stopPropagation();
            },
          },
        },
        xaxis: {
          categories: [
            '6일 전',
            '5일 전',
            '4일 전',
            '3일 전',
            '2일 전',
            '1일 전',
            '금일',
          ],
        },
      },
    };
  }

  componentDidMount() {
    const chartData = [];

    for (const key in this.state.data) {
      let name;
      let data = [];
      if (this.state.data[key].length < 7) {
        for (let i = 0; i < 7 - this.state.data[key].length; i++) {
          data.push('0');
        }
      }
      data = this.state.data[key].concat(data);
      if (key === 'contentView') {
        name = '콘텐츠 시청(단위 백)';
        data = data.map((el) => Math.ceil(el / 100));
      } else if (key === 'userRegister') {
        name = '신규 가입';
      } else if (key === 'estimateComplete') {
        name = '견적서 완료';
      } else {
        name = '계약서 완료';
      }
      chartData.push({
        name,
        data,
      });
    }

    this.setState({
      ...this.state,
      chartData,
    });
  }

  render() {
    return (
      <>
        <Card py="1rem" width="100%" position="relative">
          <Chart
            options={this.state.chartOptions}
            series={this.state.chartData}
            type="bar"
            width="100%"
            height="100%"
          />
        </Card>
      </>
    );
  }
}

export default BarChart;
