import { Flex, Box } from '@chakra-ui/layout';
import {
  Table,
  Spacer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  InputRightElement,
  InputGroup,
  Input,
} from '@chakra-ui/react';
import moment from 'moment';
import { DatePicker, Pagination, Select } from 'antd';
import axios from 'axios';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { SearchInput } from 'components/SearchInput';
import { usePagination } from 'hooks/use-pagination';
import { useState } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import { UserTableRow } from './UserTableRow';
const { RangePicker } = DatePicker;

export const UserList = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [users, setUsers] = useState([]);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const { currentPage, total, onPageChange, setQuery } = usePagination(
    '/users',
    setUsers,
  );
  const [searchObj, setSearchObj] = useState({
    removed: null,
    startDate: '',
    endDate: '',
  });

  const colNames = [
    '번호',
    '이름',
    '이메일',
    '연락처',
    '견적상태',
    '계약상태',
    '광고 계약 매장',
    '마지막 활동일',
  ];

  const excelUsers = async () => {
    setDownloadStatus('데이터베이스 조회 중...');
    const { data } = await axios.get('users', { params: { limit: 10000000 } });
    setDownloadStatus('엑셀 파일 생성 중...');
    const worksheet = utils.json_to_sheet(data.data);
    utils.sheet_add_aoa(
      worksheet,
      [
        [
          '유저번호',
          '가입일',
          '최근정보업데이트일',
          '이메일',
          '이메일인증여부',
          '회원명',
          '핸드폰번호',
          '프로필이미지URL',
          '매장 이미지URL',
          '성별',
          '생년월일',
          '마케팅동의여부',
          '사업자카테고리',
          '사업자번호',
          '사업자명',
          '사업자주소',
          '사업자주소디테일',
          '매장오픈시각',
          '매장종료시각',
          '매장운영특이사항',
          '매장바쁜시간',
          '최대수용인원',
          '일일방문자수',
          '방문자연령대',
          '광고 계약 여부',
          '',
        ],
      ],
      { origin: 'A1' },
    );
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, '유저정보');
    writeFileXLSX(workbook, '전체 유저정보.xlsx');
    setDownloadStatus(null);
  };

  const onClickSearch = (searchWord) => {
    setQuery({
      ...(searchObj.removed !== null && { removed: searchObj.removed }),
      startDate: searchObj.startDate
        ? searchObj.startDate.format('YYYY-MM-DD')
        : null,
      endDate: searchObj.endDate
        ? searchObj.endDate.format('YYYY-MM-DD')
        : null,
      search: searchWord,
      page: 1,
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader p="6px 0px 22px 0px">
            <Flex width="100%" alignItems="center">
              <Box mr="20">
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  유저 관리
                </Text>
              </Box>
              <Select
                style={{ width: 120, marginRight: '5px' }}
                onChange={(value) => {
                  setSearchObj({ ...searchObj, removed: value });
                }}
                allowClear
                onClear={() => setSearchObj({ ...searchObj, removed: null })}
                placeholder="상태"
                options={[
                  { value: null, label: '전체' },
                  { value: 't', label: '탈퇴유저' },
                  { value: 'f', label: '활성유저' },
                ]}
              />
              <RangePicker
                allowClear={false}
                value={[
                  searchObj.startDate ? moment(searchObj.startDate) : '',
                  searchObj.endDate ? moment(searchObj.endDate) : '',
                ]}
                onChange={([start, end]) =>
                  setSearchObj({
                    ...searchObj,
                    startDate: moment(start),
                    endDate: moment(end),
                  })
                }
                placeholder={['검색시작일', '검색종료일']}
              />
              <SearchInput setSearch={onClickSearch}></SearchInput>
              <span>총 숫자: {total}</span>
              <Spacer></Spacer>
              <Box>
                <Button onClick={excelUsers} size="sm">
                  {downloadStatus || '유저 엑셀 다운로드'}
                </Button>
              </Box>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  {colNames.map((colName, idx) => {
                    return (
                      <Th
                        color="gray.400"
                        key={idx}
                        ps={idx === 0 ? '0px' : null}
                      >
                        {colName}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {users.map((row) => {
                  return <UserTableRow key={`${row.id}`} user={row} />;
                })}
              </Tbody>
            </Table>
          </CardBody>
          <Box mt="5">
            <Pagination
              showSizeChanger={false}
              current={currentPage}
              onChange={onPageChange}
              total={total}
            ></Pagination>
          </Box>
        </Card>
      </Flex>
    </>
  );
};
