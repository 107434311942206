import { Button } from '@chakra-ui/button';
import { Editable, EditableInput, EditablePreview } from '@chakra-ui/editable';
import { Box, Link, Text } from '@chakra-ui/layout';
import Upload from 'rc-upload';
import { Table, Th, Tr, Thead, Tbody, Td } from '@chakra-ui/table';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { uploadFile } from 'utils';
import { Tag } from '@chakra-ui/tag';

export const EstimateTemplateWrite = () => {
  const [estimateTemplates, setEstimateTemplates] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get('/service-statics/estimate-templates');
      setEstimateTemplates(data);
    };
    getData();
  }, []);

  const handleTitle = async (id, title) => {
    const shouldUpdate = checkShouldUpdate({ id, value: title, key: 'title' });
    if (!shouldUpdate) {
      return;
    }
    const updatedTemplates = estimateTemplates.map((el) => {
      if (el.id === id) {
        el.title = title;
      }
      return el;
    });
    setEstimateTemplates(updatedTemplates);
    await updateTemplate(updatedTemplates);
  };

  const checkShouldUpdate = (data) => {
    const { id, value, key } = data;
    const template = estimateTemplates.find((el) => el.id === id);

    if (template[key] === value) {
      return false;
    }
    return true;
  };

  const updateTemplate = async (templates) => {
    await axios.patch('/service-statics/estimate-templates', {
      data: templates,
    });
    toast({
      position: 'top',
      status: 'success',
      title: '저장되었습니다.',
    });
  };

  const handleUpload = async (id, fileName, url, isRental) => {
    const shouldUpdate = checkShouldUpdate({ id, value: url, key: 'fileUrl' });
    if (!shouldUpdate) {
      return;
    }
    const updatedTemplates = estimateTemplates.map((el) => {
      if (el.id === id) {
        if (isRental) {
          el.fileNameRental = fileName;
          el.fileUrlRental = url;
        } else {
          el.fileNameOnce = fileName;
          el.fileUrlOnce = url;
        }
      }
      return el;
    });
    setEstimateTemplates(updatedTemplates);
    await updateTemplate(updatedTemplates);
  };

  const uploadFileToS3 = async (data, id, isRental) => {
    const { file } = data;
    const upload = uploadFile(file, file.name);
    upload.on('httpUploadProgress', (progress) => {
      const { loaded, total, Key } = progress;
      const pro = Math.floor((loaded / total) * 100);
      if (pro === 100) {
        handleUpload(
          id,
          file.name,
          `https://beam-monster-backend.s3.ap-northeast-2.amazonaws.com/${Key}`,
          isRental,
        );
      }
    });
    await upload.done();
  };

  const addComma = (number) => {
    const strArr = [...number.toString()].reverse();
    const commaStr = [];
    for (let i = 0; i < strArr.length; i++) {
      if (i % 3 === 0 && i !== 0 && i !== strArr.length - 1) {
        commaStr.push(',');
      }
      commaStr.push(strArr[i]);
    }
    return commaStr.reverse().join('');
  };

  return (
    <Box mt="80px">
      <Text fontSize={'lg'}>견적서 템플릿 관리</Text>
      <Text fontSize="small" color="grey" mb="30px">
        ㎡ 이하(조건), 빔 대수(조건) 에 의해 자동으로 견적서 파일이 발송됩니다.
      </Text>
      <Box>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>견적서 이름</Th>
              <Th>타입</Th>
              <Th>솔루션 타입</Th>
              <Th>일시불 가격</Th>
              <Th>토탈 할부 가격</Th>
              <Th>m^2 이하(조건)</Th>
              <Th>빔 대수(조건)</Th>
              <Th>일시불견적서파일</Th>
              <Th>할부견적서파일</Th>
            </Tr>
          </Thead>
          <Tbody>
            {estimateTemplates.map((el) => {
              return (
                <Tr key={el.id}>
                  <Td>{el.title}</Td>
                  <Td>
                    {el.hardwareType === 'LED' ? (
                      <Tag size="sm" colorScheme="red">
                        LED
                      </Tag>
                    ) : (
                      <Tag size="sm" colorScheme="blue">
                        빔
                      </Tag>
                    )}
                  </Td>
                  <Td>
                    {el.solutionType === 'BASIC' ? (
                      <Tag size="sm" colorScheme="gray">
                        베이직
                      </Tag>
                    ) : el.solutionType === 'STANDARD' ? (
                      <Tag size="sm" colorScheme="whatsapp">
                        스탠다드
                      </Tag>
                    ) : (
                      <Tag size="sm" colorScheme="orange">
                        프리미엄
                      </Tag>
                    )}
                  </Td>
                  <Td>{addComma(el.priceOnce)}</Td>
                  <Td>{addComma(el.priceMonthly)}</Td>
                  <Td>
                    {el.maxAreaSquareMeter
                      ? `${el.maxAreaSquareMeter} ㎡`
                      : '-'}
                  </Td>
                  <Td>{el.beamCount || '-'}</Td>
                  <Td>
                    <Upload
                      customRequest={(...args) =>
                        uploadFileToS3(...args, el.id, false)
                      }
                    >
                      <Button size="sm">업로드</Button>
                    </Upload>
                    <Link href={el.fileUrlOnce} download target="_blank">
                      <Button
                        size="sm"
                        colorScheme="orange"
                        disabled={!el.fileUrlOnce}
                      >
                        보기
                      </Button>
                    </Link>
                  </Td>
                  <Td>
                    <Upload
                      customRequest={(...args) =>
                        uploadFileToS3(...args, el.id, true)
                      }
                    >
                      <Button size="sm">업로드</Button>
                    </Upload>
                    <Link href={el.fileUrlRental} download target="_blank">
                      <Button
                        size="sm"
                        colorScheme="orange"
                        disabled={!el.fileUrlRental}
                      >
                        보기
                      </Button>
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};
