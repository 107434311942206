import { Button } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Flex } from '@chakra-ui/layout';
import {
  Table,
  Spacer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
} from '@chakra-ui/react';
import { Pagination } from 'antd';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { SearchInput } from 'components/SearchInput';
import dayjs from 'dayjs';
import { usePagination } from 'hooks/use-pagination';
import { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { ContentCategoryModal } from '../Content/ContentCategoryModal';

const colNames = [
  '번호',
  '제목',
  '길이',
  '작가명',
  '판매수',
  '좋아요수',
  '등록일',
];
export default function CreatorContentList() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [contents, setContents] = useState([]);
  const { currentPage, total, onPageChange, setSearch } = usePagination(
    '/contents?isCreatorContent=true',
    setContents,
  );

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex width="100%">
            <Box>
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                작가 콘텐츠 관리
              </Text>
            </Box>
            <SearchInput setSearch={setSearch}></SearchInput>
            <Spacer></Spacer>
            <Box>
              <ContentCategoryModal></ContentCategoryModal>
            </Box>
            <Box>
              <NavLink to="/admin/creator-contents/creators">
                <Button fontSize="l" mr="4" colorScheme="red">
                  작가관리
                </Button>
              </NavLink>
              <NavLink to="/admin/creator-contents/write">
                <Button fontSize="l" colorScheme="green">
                  등록
                </Button>
              </NavLink>
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                {colNames.map((colName, idx) => {
                  return (
                    <Th
                      color="gray.400"
                      key={idx}
                      ps={idx === 0 ? '0px' : null}
                    >
                      {colName}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {contents.map((content, index) => (
                <Tr
                  onClick={() =>
                    history.push(`/admin/creator-contents/write`, { content })
                  }
                >
                  <Td>
                    {total - (currentPage - 1) * 10 - index < 0
                      ? ''
                      : total - (currentPage - 1) * 10 - index}
                  </Td>
                  <Td>{content.title}</Td>
                  <Td>{content.videoLength}</Td>
                  <Td>{content.creator.name}</Td>
                  <Td>{content.sellCount}</Td>
                  <Td>{content.likeCount}</Td>
                  <Td>{dayjs(content.createdAt).format('YYYY-MM-DD')}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
        <Pagination
          showSizeChanger={false}
          current={currentPage}
          onChange={onPageChange}
          total={total}
        ></Pagination>
      </Card>
    </Flex>
  );
}
