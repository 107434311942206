import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { useDisclosure } from '@chakra-ui/hooks';
import { Image } from '@chakra-ui/image';
import { Input } from '@chakra-ui/input';
import { Box, Container, Heading, HStack, Text } from '@chakra-ui/layout';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/modal';
import { NumberInput, NumberInputField } from '@chakra-ui/number-input';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import { Select } from '@chakra-ui/select';
import { Textarea } from '@chakra-ui/textarea';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import Card from 'components/Card/Card';
import Upload from 'rc-upload';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { koreanWon } from 'utils';
import { uploadFile } from 'utils';

export default function CreatorContentWrite() {
  const [title, setTitle] = useState('');
  const [grade, setGrade] = useState('A');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [freeVideoUrl, setFreeVideoUrl] = useState('');
  const [category, setCategory] = useState('');
  const [progress, setProgress] = useState(null);
  const [freeProgress, setFreeProgress] = useState(null);
  const [onUploading, setOnUploading] = useState(false);
  const [onUploading1, setOnUploading1] = useState(false);
  const [onUploading2, setOnUploading2] = useState(false);
  const [signedUrl, setSignedUrl] = useState('');
  const [freeSignedUrl, setFreeSignedUrl] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdateVideo, setIsUpdateVideo] = useState(false);
  const [isUpdateFreeVideo, setIsUpdateFreeVideo] = useState(false);
  const [categories, setCategories] = useState([]);
  const [creators, setCreators] = useState([]);
  const [creatorInfo, setCreatorInfo] = useState({
    creator: '',
    price: '',
    displayPrice: '',
    shortDescription: '',
    description: '',
  });
  const history = useHistory();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  useEffect(() => {
    const getCreators = async () => {
      const { data: creators } = await axios.get('/creators');
      setCreators(creators);
    };
    getCreators();
    const getCategories = async () => {
      const { data: categories } = await axios.get('contents/categories');
      setCategories(categories.map((el) => el.category));
    };
    getCategories();
    const { state } = location;
    if (state && state.content) {
      const getContent = async () => {
        const { data: content } = await axios.get(
          'contents/' + state.content.id,
        );
        setIsEdit(true);
        setTitle(content.title);
        setGrade(content.grade);
        setVideoUrl(content.videoUrl);
        setCategory(content.category);
        setFreeVideoUrl(content.freeVideoUrl);
        setThumbnailUrl(content.thumbnailUrl);
        setSignedUrl(content.videoUrl);
        setFreeSignedUrl(content.freeVideoUrl);
        setCreatorInfo({
          creator: content.creator.id,
          price: content.price,
          displayPrice: content.displayPrice,
          shortDescription: content.shortDescription,
          description: content.description,
        });
      };
      getContent();
    }
  }, []);

  const uploadFreeFileToS3 = async ({ file }) => {
    const isProd = process.env.NODE_ENV === 'production';

    const FOLDER_URI = isProd
      ? '/users/202267125/projects/18201592'
      : '/users/202267125/projects/20925269';

    setFreeProgress(`업로드 중`);
    setOnUploading2(true);
    const formData = new FormData();
    formData.append('files', file);
    formData.append('folder_uri', FOLDER_URI);
    formData.append('name', file.name);

    try {
      const { data: url } = await axios.post(
        '/utils/create-vimeo-video',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      setOnUploading2(false);
      setFreeProgress(`업로드 완료 - ${file.name}`);
      setIsUpdateFreeVideo(true);
      setFreeVideoUrl(url.toString());
    } catch (e) {
      console.log(e);
    }
  };

  const uploadFileToS3 = async ({ file }) => {
    const isProd = process.env.NODE_ENV === 'production';

    const FOLDER_URI = isProd
      ? '/users/202267125/projects/18201589'
      : '/users/202267125/projects/20925269';

    setProgress(`업로드 중`);
    setOnUploading(true);

    const formData = new FormData();
    formData.append('files', file);
    formData.append('folder_uri', FOLDER_URI);
    formData.append('name', file.name);

    try {
      const { data: url } = await axios.post(
        '/utils/create-vimeo-video',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      setOnUploading(false);
      setProgress(`업로드 완료 - ${file.name}`);
      setIsUpdateVideo(true);
      setVideoUrl(url.toString());
    } catch (e) {
      console.log(e);
    }
  };

  const create = async () => {
    const isCreatorTextValid =
      creatorInfo.price &&
      creatorInfo.creator &&
      creatorInfo.shortDescription &&
      creatorInfo.description &&
      creatorInfo.displayPrice;
    if (!isCreatorTextValid) {
      toast({
        position: 'top',
        status: 'error',
        title: '작가와 판매가격 항목을 작성해주세요.',
      });
      return;
    }
    if (
      !category ||
      !title ||
      !grade ||
      !thumbnailUrl ||
      !videoUrl ||
      !freeVideoUrl
    ) {
      toast({
        position: 'top',
        status: 'error',
        title: '모든 항목을 작성해주세요.',
      });
      return;
    }
    if (!isEdit) {
      await axios.post('/admin/contents', {
        ...creatorInfo,
        title,
        grade,
        videoUrl,
        freeVideoUrl,
        thumbnailUrl,
        category,
      });
    } else {
      const data = {
        ...creatorInfo,
        title,
        grade,
        thumbnailUrl,
        category,
      };

      if (isUpdateVideo) {
        data.videoUrl = videoUrl;
      }
      if (isUpdateFreeVideo) {
        data.freeVideoUrl = freeVideoUrl;
      }
      await axios.patch('/admin/contents/' + location.state.content.id, data);
    }
    setTitle('');
    setGrade('');
    setProgress('');
    setCategory('');
    setOnUploading(false);
    setOnUploading1(false);
    setOnUploading2(false);
    setVideoUrl('');
    setFreeVideoUrl('');
    setSignedUrl('');
    setFreeSignedUrl('');
    setThumbnailUrl('');
    setCreatorInfo({
      creator: '',
      price: '',
      displayPrice: '',
      shortDescription: '',
      description: '',
    });
    history.push('/admin/creator-contents');
  };

  const handleUpload = async (file, type) => {
    if (type === 'thumbnailUrl') {
      setOnUploading1(true);
    }
    const formData = new FormData();
    formData.append('files', file);
    const res = await axios.post('/utils/upload-files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const { url } = res.data[0];
    if (type === 'thumbnailUrl') {
      setThumbnailUrl(url);
      setOnUploading1(false);
    } else if (type === 'freeVideoUrl') {
      setFreeVideoUrl(null);
      setTimeout(() => {
        setFreeVideoUrl(url);
      }, 100);
    }
  };

  const getSignedUrl = async (url) => {
    const { data } = await axios.post('admin/contents/signed-url', { url });
    return data;
  };

  const onDeleteContent = async () => {
    await axios.delete('contents/' + location.state.content.id);
    toast({
      title: '삭제되었습니다',
      status: 'success',
      isClosable: true,
    });
    onClose();
    history.push('/admin/contents');
  };

  return (
    <Container mt="80px" centerContent={false} maxWidth="100%">
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
        <Heading mb="30px" size="md">
          작가 콘텐츠 등록
        </Heading>
        <form>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              작가 선택
            </FormLabel>
            <Select
              placeholder="작가 선택"
              defaultValue={creatorInfo.creator}
              value={creatorInfo.creator}
              onChange={(e) =>
                setCreatorInfo({ ...creatorInfo, creator: +e.target.value })
              }
              maxWidth={500}
            >
              {creators.map((el) => {
                return (
                  <option key={el.id} value={el.id}>
                    {el.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              할인 전 가격(표시 가격)
            </FormLabel>
            <NumberInput value={creatorInfo.displayPrice}>
              <NumberInputField
                placeholder="원 단위로 숫자만 입력해주세요"
                value={creatorInfo.displayPrice}
                onChange={(e) => {
                  if (isNaN(+e.target.value)) {
                    return;
                  }
                  setCreatorInfo({
                    ...creatorInfo,
                    displayPrice: +e.target.value,
                  });
                }}
              />
            </NumberInput>
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              판매가격(실제 판매가)
            </FormLabel>
            <NumberInput value={creatorInfo.price}>
              <NumberInputField
                placeholder="원 단위로 숫자만 입력해주세요"
                value={creatorInfo.price}
                onChange={(e) =>
                  setCreatorInfo({
                    ...creatorInfo,
                    price: +e.target.value,
                  })
                }
              />
            </NumberInput>
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              간단 작품설명
            </FormLabel>

            <Textarea
              value={creatorInfo.shortDescription}
              onChange={(e) =>
                setCreatorInfo({
                  ...creatorInfo,
                  shortDescription: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              상세 작품설명
            </FormLabel>
            <Textarea
              value={creatorInfo.description}
              onChange={(e) =>
                setCreatorInfo({
                  ...creatorInfo,
                  description: e.target.value,
                })
              }
            />
          </FormControl>

          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              콘텐츠 이름
            </FormLabel>
            <Input
              size="sm"
              borderRadius="15px"
              fontSize="md"
              maxWidth={500}
              type="text"
              placeholder="콘텐츠 명"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              콘텐츠 카테고리
            </FormLabel>
            <Select
              placeholder="카테고리"
              defaultValue={category}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              maxWidth={500}
            >
              {categories.map((el) => (
                <option value={el} key={el}>
                  {el}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              작가 콘텐츠 등급
            </FormLabel>
            <RadioGroup
              value={grade}
              defaultValue={'A'}
              onChange={(value) => setGrade(value)}
            >
              <HStack spacing="24px">
                <Radio value="A">A</Radio>
                <Radio value="B">B</Radio>
                <Radio value="C">C</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              메인 이미지
            </FormLabel>
            <Input
              size="sm"
              border={false}
              type="file"
              multiple={false}
              onChange={(e) => handleUpload(e.target.files[0], 'thumbnailUrl')}
              accept="image/*"
            />
          </FormControl>
          {thumbnailUrl && (
            <>
              미리보기
              <Image boxSize="200px" src={thumbnailUrl} />
            </>
          )}
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" mt="50px" fontWeight="normal">
              콘텐츠 비디오 파일
            </FormLabel>
            <Upload customRequest={uploadFileToS3} accept="video/*">
              <Button>파일 선택</Button>
            </Upload>
            <Text>{progress && `${progress}`}</Text>
          </FormControl>
          {signedUrl && (
            <>
              콘텐츠 비디오
              <video width="200px" controls autoPlay={false} preload="metadata">
                <source src={signedUrl} />
              </video>
            </>
          )}
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" mt="50px" fontWeight="normal">
              미리보기 비디오 파일
            </FormLabel>
            <Upload customRequest={uploadFreeFileToS3} accept="video/*">
              <Button>파일 선택</Button>
            </Upload>
            <Text>{freeProgress && `${freeProgress}`}</Text>
          </FormControl>
          {freeSignedUrl && (
            <>
              업로드 미리보기 비디오
              <video width="200px" controls autoPlay={false} preload="metadata">
                <source src={freeSignedUrl} />
              </video>
            </>
          )}
        </form>
        <FormControl>
          <Button
            disabled={onUploading || onUploading1 || onUploading2}
            fontSize="16px"
            type="submit"
            bg="red.300"
            w="100%"
            h="45"
            mb="20px"
            color="white"
            mt="20px"
            _hover={{
              bg: 'red.200',
            }}
            onClick={create}
            _active={{
              bg: 'red.400',
            }}
          >
            {(onUploading || onUploading1 || onUploading2) &&
              `파일 업로드 중 입니다 - `}
            {isEdit ? '수정' : '저장'}
          </Button>
        </FormControl>
        {location.state && location.state.content?.id && (
          <Box textAlign="right">
            <Button
              colorScheme="grey"
              color="red"
              width="100px"
              onClick={onOpen}
            >
              삭제
            </Button>
          </Box>
        )}
      </Card>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              콘텐츠삭제
            </AlertDialogHeader>

            <AlertDialogBody>정말로 삭제하시겠습니까?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={onDeleteContent} ml={3}>
                삭제
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
}
