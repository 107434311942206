import { useColorModeValue } from '@chakra-ui/color-mode';
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/table';
import { LinkIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AdvertiseOrderStatus } from 'utils';
import dayjs from 'dayjs';
import { Button } from '@chakra-ui/button';

export default function StoreOrderTable({ storeUserId, handleUpdateAdSelect }) {
  const [advertises, setAdvertises] = useState([]);

  let slotCount = 0;

  useEffect(() => {
    getAds();
  }, []);

  const getAds = async () => {
    const { data } = await axios.get(
      `/advertises/stores/${storeUserId}/advertises`,
    );
    const runningAds = [];
    const notRunningAds = [];

    for (let i = 0; i < data.length; i++) {
      const ad = data[i];
      if (ad.status === 'RUNNING') {
        runningAds.push(ad);
      } else {
        notRunningAds.push(ad);
      }
    }
    setAdvertises(runningAds.concat(notRunningAds));
  };

  const textColor = useColorModeValue('gray.700', 'white');
  const colNames = [
    '주문번호',
    '구매자',
    '구좌',
    '광고시작',
    '광고종료',
    '광고상태',
    '주문금액',
    '결제일자',
  ];

  const displaySlot = (row) => {
    const { status } = row;
    if (status !== 'RUNNING') {
      return '-';
    }
    const slot = ['A', 'B', 'C', 'D', 'E', 'F'];
    return slot[slotCount++];
  };

  return (
    <>
      <Table variant="simple" color={textColor} style={{ marginTop: '10px' }}>
        <Thead>
          <Tr my=".8rem" pl="0px" color="gray.400">
            {colNames.map((colName, idx) => {
              return (
                <Th color="gray.400" key={idx}>
                  {colName}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {advertises.map((row) => {
            return (
              <Tr key={row.id}>
                <Td>
                  <Link to={`/admin/advertise/orders/${row.order.id}`}>
                    {row.order.merchant_uid}
                    <LinkIcon />
                  </Link>
                </Td>
                <Td>
                  {row.order.buyer ? (
                    <Link to={`/admin/users/${row.order.buyer.id}`}>
                      {row.order.buyer.name}
                    </Link>
                  ) : (
                    <span style={{ color: 'green' }}>어드민</span>
                  )}
                </Td>
                <Td>{displaySlot(row)}</Td>
                <Td>{row.startDate}</Td>
                <Td>{row.endDate}</Td>
                <Td>{AdvertiseOrderStatus(row.status)}</Td>
                <Td>{row.order.price}</Td>
                <Td>{dayjs(row.order.paidDate).format('YYYY-MM-DD')}</Td>
                <Td>
                  <Button
                    onClick={() =>
                      handleUpdateAdSelect({
                        ...row,
                        videoUrl: row.videoUrl,
                      })
                    }
                  >
                    수정
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}
