import { Button } from '@chakra-ui/button';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { useState } from 'react';

export const SearchInput = ({ setSearch }) => {
  const [searchWord, setSearchWord] = useState('');
  const onSearch = (e) => {
    if (e.keyCode === 13 || !e.keyCode) {
      setSearch(searchWord);
    }
  };
  return (
    <InputGroup size="sm" width={300} ml={5}>
      <Input
        onChange={(e) => setSearchWord(e.target.value)}
        value={searchWord}
        pr="4.5rem"
        placeholder="검색어"
        onKeyUp={onSearch}
      />
      <InputRightElement width="4.5rem">
        <Button borderRadius={0} width="120px" size="sm" onClick={onSearch}>
          검색
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
