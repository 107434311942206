import { Button } from '@chakra-ui/button';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Link,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Select } from '@chakra-ui/select';
import { Textarea } from '@chakra-ui/textarea';
import { useToast } from '@chakra-ui/toast';
import { DatePicker, Image } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export function AfterServiceDetailModal({ isOpen, onClose: closeModal, data }) {
  const [managerPhone, setManagerPhone] = useState('');
  const [managerName, setManagerName] = useState('');
  const [managerProfileImageUrl, setManagerProfileImageUrl] = useState('');
  const [visitDate, setVisitDate] = useState(null);
  const [response, setResponse] = useState('');
  const [managers, setManagers] = useState([]);
  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      const { data: managerData } = await axios.get(
        '/service-statics/service-managers',
      );

      setManagers(managerData.value.data);
    };
    getData();
  }, []);
  useEffect(() => {
    if (data) {
      const {
        visitDate,
        managerName,
        managerPhone,
        response,
        managerProfileImageUrl,
      } = data;
      if (visitDate) {
        setVisitDate(moment(data.visitDate));
      }
      setManagerName(managerName);
      setManagerPhone(managerPhone);
      setManagerProfileImageUrl(managerProfileImageUrl);
      setResponse(response);
    }
  }, [data]);

  const handleSelect = (e) => {
    if (!e.target.value) {
      setManagerPhone(null);
      setManagerName(null);
      setManagerProfileImageUrl(null);
      return;
    }
    const manager = managers.find((el) => el.name === e.target.value);
    setManagerPhone(manager.phone || '');
    setManagerName(manager.name || '');
    setManagerProfileImageUrl(manager.profileImageUrl || '');
  };

  const handleVisitDate = (e) => {
    setVisitDate(e);
  };

  const onClose = () => {
    setManagerPhone(null);
    setManagerName(null);
    setManagerName(null);
    setVisitDate(null);
    setResponse(null);
    closeModal();
  };

  const changeSchedule = async () => {
    if (!managerName || !visitDate) {
      toast({
        title: '방문일과 매니저를 선택해주세요.',
        status: 'warning',
        isClosable: true,
      });
      return;
    }
    await axios.patch('/after-services/' + data.id + '/schedules', {
      managerPhone,
      managerProfileImageUrl,
      managerName,
      visitDate: visitDate.toDate(),
    });
    toast({
      title: '저장되었습니다.',
      status: 'success',
      isClosable: true,
    });
    onClose();
  };

  const onSave = async () => {
    if (!response) {
      toast({
        title: '답변을 작성해주세요',
        status: 'warning',
        isClosable: true,
      });
      return;
    }
    await axios.patch('/after-services/' + data.id, {
      managerPhone,
      managerProfileImageUrl,
      managerName,
      response,
      ...(visitDate && { visitDate: visitDate.toDate() }),
    });
    toast({
      title: '저장되었습니다.',
      status: 'success',
      isClosable: true,
    });
    onClose();
  };

  if (!data) {
    return <></>;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>답변하기</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text fontSize="lg">요청내용</Text>
              요청자:
              <Link
                onClick={() => history.push(`/admin/users/${data.writer.id}`)}
              >
                {data.writer.name}({data.writer.phone}){' '}
                <ExternalLinkIcon mb="5px" />
              </Link>
              <Textarea disabled={true} value={data.content}></Textarea>
            </Box>
            <OrderedList>
              {data.files.map((url) => {
                return (
                  <ListItem>
                    <a href={url} download>
                      {url.split('/').pop()}
                    </a>
                  </ListItem>
                );
              })}
            </OrderedList>
            <Divider my={4}></Divider>
            <Text fontSize="lg">답변</Text>
            <DatePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              format="YYYY/MM/DD HH:mm"
              showTime
              value={visitDate}
              onOk={handleVisitDate}
            />
            <Select
              placeholder="서비스 기사"
              size="sm"
              onChange={handleSelect}
              value={managerName}
              mt={1}
            >
              {managers.map((el) => {
                return (
                  <option key={el.phone} value={el.name}>
                    {`${el.name} (${el.phone})`}
                  </option>
                );
              })}
            </Select>
            <Text>
              신청 주소:{' '}
              {`${data.writer.businessAddress || ''} ${
                data.writer.businessDetailAddress || ''
              }`}
            </Text>

            <Textarea
              mt={5}
              value={response}
              onChange={(e) => setResponse(e.target.value)}
              placeholder="답변 작성"
              disabled={data.status === 'DONE'}
            ></Textarea>
            <Text fontWeight="light">
              서비스기사, 방문일자 설정은 <strong>필수가 아닙니다.</strong>
            </Text>
          </ModalBody>

          <ModalFooter>
            {data.status === 'DONE' && (
              <>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={changeSchedule}
                >
                  스케쥴 수정
                </Button>
              </>
            )}
            <Button
              colorScheme="blue"
              mx={3}
              onClick={onSave}
              disabled={data.status === 'DONE'}
            >
              완료처리
            </Button>
            <Button variant="ghost" onClick={onClose}>
              취소
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
