import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { useDisclosure } from '@chakra-ui/hooks';
import { Image } from '@chakra-ui/image';
import { Input } from '@chakra-ui/input';
import {
  Box,
  Container,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/layout';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/modal';
import { NumberInput, NumberInputField } from '@chakra-ui/number-input';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import { Select } from '@chakra-ui/select';
import { Skeleton } from '@chakra-ui/skeleton';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import Card from 'components/Card/Card';
import dayjs from 'dayjs';
import Upload from 'rc-upload';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { uploadFile } from 'utils';
import { ContentVimeoStatus } from './ContentVimeoStatus';

export const ContentWrite = () => {
  const [title, setTitle] = useState('');
  const [resolution, setResolution] = useState('4K');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [freeVideoUrl, setFreeVideoUrl] = useState('');
  const [category, setCategory] = useState('');
  const [progress, setProgress] = useState(null);
  const [freeProgress, setFreeProgress] = useState(null);
  const [onUploading, setOnUploading] = useState(false);
  const [onUploading1, setOnUploading1] = useState(false);
  const [onUploading2, setOnUploading2] = useState(false);
  const [signedUrl, setSignedUrl] = useState('');
  const [freeSignedUrl, setFreeSignedUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdateVideo, setIsUpdateVideo] = useState(false);
  const [isUpdateFreeVideo, setIsUpdateFreeVideo] = useState(false);
  const [categories, setCategories] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  useEffect(() => {
    const getCategories = async () => {
      const { data: categories } = await axios.get('contents/categories');
      setCategories(categories.map((el) => el.category));
    };
    getCategories();
    const { state } = location;
    if (state && state.content) {
      const getContent = async () => {
        setIsLoading(true);
        const { data: content } = await axios.get(
          'contents/' + state.content.id,
        );
        setIsLoading(false);
        setIsEdit(true);
        setTitle(content.title);
        setResolution(content.resolution);
        setVideoUrl(content.videoUrl);
        setCategory(content.category);
        setFreeVideoUrl(content.freeVideoUrl);
        setThumbnailUrl(content.thumbnailUrl);

        if (
          dayjs(content.createdAt).isAfter(dayjs().subtract(10, 'm')) &&
          (!content.videoUrl || !content.freeVideoUrl)
        ) {
          toast({
            position: 'top',
            status: 'warning',
            title:
              'Vimeo 업로드 이 후 변환작업에는 용량에 따라 최대 30분이상 소모될 수 있습니다. 그 전까지는 영상을 확인 할 수 없습니다.',
          });
          return;
        }

        if (!content.videoUrl) {
          toast({
            position: 'top',
            status: 'error',
            title:
              '콘텐츠 비디오 파일 원본 데이터가 삭제되었습니다. 다시 업로드를 해주세요.',
          });
        }
        if (!content.freeVideoUrl) {
          toast({
            position: 'top',
            status: 'error',
            title:
              '미리보기 비디오 파일 원본 데이터가 삭제되었습니다. 다시 업로드를 해주세요.',
          });
        }

        setSignedUrl(content.videoUrl);
        setFreeSignedUrl(content.freeVideoUrl);
      };
      getContent();
    }
  }, []);

  const uploadFreeFileToS3 = async ({ file }) => {
    const isProd = process.env.NODE_ENV === 'production';

    const FOLDER_URI = isProd
      ? '/users/202267125/projects/18201592'
      : '/users/202267125/projects/20925269';

    setFreeProgress(`업로드 중`);
    setOnUploading2(true);
    const formData = new FormData();
    formData.append('files', file);
    formData.append('folder_uri', FOLDER_URI);
    formData.append('name', file.name);

    try {
      const { data: url } = await axios.post(
        '/utils/create-vimeo-video',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      setOnUploading2(false);
      setFreeProgress(`업로드 완료 - ${file.name}`);
      setIsUpdateFreeVideo(true);
      setFreeVideoUrl(url.toString());
    } catch (e) {
      console.log(e);
    }
  };

  const uploadFileToS3 = async ({ file }) => {
    const isProd = process.env.NODE_ENV === 'production';

    const FOLDER_URI = isProd
      ? '/users/202267125/projects/18201589'
      : '/users/202267125/projects/20925269';

    setProgress(`업로드 중`);
    setOnUploading(true);

    const formData = new FormData();
    formData.append('files', file);
    formData.append('folder_uri', FOLDER_URI);
    formData.append('name', file.name);

    try {
      const { data: url } = await axios.post(
        '/utils/create-vimeo-video',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      setOnUploading(false);
      setProgress(`업로드 완료 - ${file.name}`);
      setIsUpdateVideo(true);
      setVideoUrl(url.toString());
    } catch (e) {
      console.log(e);
    }
  };

  const create = async () => {
    if (
      !category ||
      !title ||
      !resolution ||
      !thumbnailUrl ||
      !videoUrl ||
      !freeVideoUrl
    ) {
      toast({
        position: 'top',
        status: 'error',
        title: '모든 항목을 작성해주세요.',
      });
      return;
    }
    if (!isEdit) {
      try {
        console.log({ videoUrl });
        await axios.post('/admin/contents', {
          title,
          grade: 'C',
          resolution,
          videoUrl,
          freeVideoUrl,
          thumbnailUrl,
          category,
        });
      } catch (e) {
        if (e.response) {
          const res = e.response;
          if (res.data && res.data.message === '이미 존재하는 타이틀입니다.') {
            toast({
              position: 'top',
              status: 'error',
              title: '이미 존재하는 제목입니다.',
            });
          } else {
            toast({
              position: 'top',
              status: 'error',
              title: '서버 에러가 발생하였습니다. 개발자에게 문의주세요.',
            });
          }
          return;
        }
      }
    } else {
      const data = {
        title,
        resolution,
        thumbnailUrl,
        category,
      };

      if (isUpdateVideo) {
        data.videoUrl = videoUrl;
      }
      if (isUpdateFreeVideo) {
        data.freeVideoUrl = freeVideoUrl;
      }
      await axios.patch('/admin/contents/' + location.state.content.id, data);
    }
    setTitle('');
    setResolution('');
    setProgress('');
    setCategory('');
    setOnUploading(false);
    setOnUploading1(false);
    setOnUploading2(false);
    setVideoUrl('');
    setFreeVideoUrl('');
    setSignedUrl('');
    setFreeSignedUrl('');
    setThumbnailUrl('');
    history.push('/admin/contents');
  };

  const handleUpload = async (file, type) => {
    if (type === 'thumbnailUrl') {
      setOnUploading1(true);
    }
    const formData = new FormData();
    formData.append('files', file);
    const res = await axios.post('/utils/upload-files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const { url } = res.data[0];
    if (type === 'thumbnailUrl') {
      setThumbnailUrl(url);
      setOnUploading1(false);
    } else if (type === 'freeVideoUrl') {
      setFreeVideoUrl(null);
      setTimeout(() => {
        setFreeVideoUrl(url);
      }, 100);
    }
  };

  const getSignedUrl = async (url) => {
    const { data } = await axios.post('admin/contents/signed-url', { url });
    return data;
  };

  const onDeleteContent = async () => {
    await axios.delete('contents/' + location.state.content.id);
    toast({
      title: '삭제되었습니다',
      status: 'success',
      isClosable: true,
    });
    onClose();
    history.push('/admin/contents');
  };

  return (
    <Container mt="80px" centerContent={false} maxWidth="100%">
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
        <Heading mb="30px" size="md">
          콘텐츠 등록
        </Heading>
        <form>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              콘텐츠 이름
            </FormLabel>
            <Input
              size="sm"
              borderRadius="15px"
              fontSize="md"
              maxWidth={500}
              type="text"
              placeholder="콘텐츠 명"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              콘텐츠 카테고리
            </FormLabel>
            <Select
              placeholder="카테고리"
              defaultValue={category}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              maxWidth={500}
            >
              {categories.map((el) => (
                <option value={el} key={el}>
                  {el}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isRequired mb="24px" hidden>
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              해상도
            </FormLabel>
            <RadioGroup
              value={resolution}
              defaultValue={'4K'}
              onChange={(value) => setResolution(value)}
            >
              <HStack spacing="24px">
                <Radio value="4K">UHD(4K)</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" fontWeight="normal">
              메인 이미지
            </FormLabel>
            <Input
              size="sm"
              border={false}
              type="file"
              multiple={false}
              onChange={(e) => handleUpload(e.target.files[0], 'thumbnailUrl')}
              accept="image/*"
            />
          </FormControl>
          {thumbnailUrl && (
            <>
              미리보기
              <Image boxSize="200px" src={thumbnailUrl} />
            </>
          )}
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" mt="50px" fontWeight="normal">
              콘텐츠 비디오 파일
            </FormLabel>
            <Upload customRequest={uploadFileToS3} accept="video/*">
              <Button>파일 선택</Button>
            </Upload>
            <Text>{progress && `${progress}`}</Text>
          </FormControl>
          {isLoading && <Skeleton height="100px" width="150px" />}
          {signedUrl && (
            <>
              콘텐츠 비디오
              <video width="200px" controls autoPlay={false} preload="metadata">
                <source src={signedUrl} />
              </video>
            </>
          )}
          {isEdit && videoUrl.indexOf('vimeo.com/') > -1 && (
            <ContentVimeoStatus videoUrl={videoUrl} />
          )}
          <FormControl isRequired mb="24px">
            <FormLabel ms="4px" fontSize="lg" mt="50px" fontWeight="normal">
              미리보기 비디오 파일
            </FormLabel>
            <Upload customRequest={uploadFreeFileToS3} accept="video/*">
              <Button>파일 선택</Button>
            </Upload>
            <Text>{freeProgress && `${freeProgress}`}</Text>
          </FormControl>
          {isLoading && <Skeleton height="100px" width="150px" />}
          {freeSignedUrl && (
            <>
              업로드 미리보기 비디오
              <video width="200px" controls autoPlay={false} preload="metadata">
                <source src={freeSignedUrl} />
              </video>
            </>
          )}
        </form>
        <FormControl>
          <Button
            disabled={onUploading || onUploading1 || onUploading2}
            fontSize="16px"
            type="submit"
            bg="red.300"
            w="100%"
            h="45"
            mb="20px"
            color="white"
            mt="20px"
            _hover={{
              bg: 'red.200',
            }}
            onClick={create}
            _active={{
              bg: 'red.400',
            }}
          >
            {(onUploading || onUploading1 || onUploading2) &&
              `파일 업로드 중 입니다 - `}
            {isEdit ? '수정' : '저장'}
          </Button>
        </FormControl>
        {location.state && location.state.content?.id && (
          <Box textAlign="right">
            <Button
              colorScheme="grey"
              color="red"
              width="100px"
              onClick={onOpen}
            >
              삭제
            </Button>
          </Box>
        )}
      </Card>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              콘텐츠삭제
            </AlertDialogHeader>

            <AlertDialogBody>정말로 삭제하시겠습니까?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={onDeleteContent} ml={3}>
                삭제
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};
