import { Button } from '@chakra-ui/button';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Textarea } from '@chakra-ui/textarea';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import Upload from 'rc-upload';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { uploadFile } from 'utils';

export default function AdvertiseOrderSetVideo({ advertiseOrder }) {
  const { advertises } = advertiseOrder;

  return (
    <>
      {advertises.map((el) => {
        return <SetVideo advertise={el} key={el.id} />;
      })}
    </>
  );
}

export const SetVideo = ({ advertise }) => {
  const [newVideoUrl, setNewVideoUrl] = useState(null);
  const [urlForPlay, setUrlForPlay] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleSave = async () => {
    await axios.put(`/advertises/${advertise.id}/video-url`, {
      videoUrl: newVideoUrl,
    });
    toast({
      title:
        '수정되었습니다. vimeo 인코딩 후 새로운 영상을 확일 할 수 있습니다.',
      status: 'success',
      isClosable: true,
    });
    setNewVideoUrl('');
  };

  const uploadFileToS3 = async ({ file }, type) => {
    setLoading(true);
    setUrlForPlay(null);
    setNewVideoUrl(null);
    const isProd = process.env.NODE_ENV === 'production';

    const FOLDER_URI = isProd
      ? '/users/202267125/projects/20925311'
      : '/users/202267125/projects/20925269';

    const formData = new FormData();
    formData.append('files', file);
    formData.append('folder_uri', FOLDER_URI);
    formData.append('name', file.name);

    try {
      const { data: url } = await axios.post(
        '/utils/create-vimeo-video',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      setTimeout(() => {
        setNewVideoUrl(url);
      }, 500);
      setUrlForPlay(URL.createObjectURL(file));
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <Box p="3" mb="1">
      {loading ? (
        <h2>업로드 중...</h2>
      ) : (
        <Upload customRequest={uploadFileToS3} accept="video/*">
          <Button>파일 선택</Button>
        </Upload>
      )}
      {urlForPlay && (
        <video width="320" height="240" controls>
          - <source src={urlForPlay} type="video/mp4" />-{' '}
        </video>
      )}
      <Button
        ml={3}
        hidden={!newVideoUrl}
        onClick={handleSave}
        colorScheme="green"
      >
        저장
      </Button>
    </Box>
  );
};
