import { Tr, Td } from '@chakra-ui/table';
import { Tag } from '@chakra-ui/tag';
import dayjs from 'dayjs';
import { engToKor } from 'utils';

export const AfterServiceTableRow = ({
  id,
  category,
  status,
  writer,
  visitDate,
  createdAt,
  updatedAt,
  onClick,
  admin,
}) => {
  if (!id) {
    return <></>;
  }

  return (
    <Tr onClick={onClick}>
      <Td>{id}</Td>
      <Td>{category}</Td>
      <Td>
        <Tag colorScheme={status === 'READY' ? 'red' : 'green'}>
          {engToKor(status)}
        </Tag>
      </Td>
      <Td>{writer.name}</Td>
      <Td>{visitDate ? dayjs(visitDate).format('YYYY-MM-DD') : '-'}</Td>
      <Td>{dayjs(createdAt).format('YYYY-MM-DD')}</Td>
      <Td>
        {admin
          ? admin.name + '(' + dayjs(updatedAt).format('YYYY-MM-DD') + ')'
          : '-'}
      </Td>
    </Tr>
  );
};
