import { Flex, Box } from '@chakra-ui/layout';
import {
  Table,
  Spacer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { Pagination } from 'antd';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { SearchInput } from 'components/SearchInput';
import { usePagination } from 'hooks/use-pagination';
import { useState } from 'react';
import { useHistory } from 'react-router';
import AdvertiseOrderTableRow from './AdvertiseOrderTableRow';

export const AdvertiseOrderList = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [advertises, setAdvertises] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentPage, total, onPageChange, setSearch } = usePagination(
    '/advertises/orders',
    setAdvertises,
  );

  const colNames = [
    '주문번호',
    '구매자',
    '광고카테고리',
    '선택 매체 수',
    '광고비',
    '판매일자',
    '상태',
    '승인여부',
  ];

  const onExcelDownloadClick = () => {
    console.log('야호');
  };

  const goDetail = (id) => {
    history.push('/admin/advertise/orders/' + id);
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader p="6px 0px 22px 0px">
            <Flex width="100%">
              <Box>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  광고 판매
                </Text>
              </Box>
              <SearchInput setSearch={setSearch}></SearchInput>
              <Spacer></Spacer>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  {colNames.map((colName, idx) => {
                    return (
                      <Th
                        color="gray.400"
                        key={idx}
                        ps={idx === 0 ? '0px' : null}
                      >
                        {colName}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {advertises.map((row) => {
                  return (
                    <Tr key={row.id} onClick={() => goDetail(row.id)}>
                      <AdvertiseOrderTableRow data={row} />
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
          <Box mt="5">
            <Pagination
              showSizeChanger={false}
              current={currentPage}
              onChange={onPageChange}
              total={total}
            ></Pagination>
          </Box>
        </Card>
      </Flex>
    </>
  );
};
