import { Avatar } from '@chakra-ui/avatar';
import { Button } from '@chakra-ui/button';
import { Box, Center, Text } from '@chakra-ui/layout';

export const ServiceManagerCard = (props) => {
  const { name, profileImageUrl, phone } = props.manager;
  const { onDelete, idx } = props;

  return (
    <Box border="1px" borderColor="gray.400" height="280px" pt="20px">
      <Center h="120px">
        <Avatar size="xl" src={profileImageUrl} />
      </Center>
      <Center h="40px">
        <Text fontSize="lg">{phone}</Text>
      </Center>
      <Center h="40px">
        <Text fontSize="x-large">{name}</Text>
      </Center>
      <Box textAlign="right" mr="5px">
        <Button colorScheme="red" onClick={() => onDelete(idx)}>
          삭제
        </Button>
      </Box>
    </Box>
  );
};
