import { Flex } from '@chakra-ui/layout';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import MainpageTopBanner from './components/MainpageTopBanner';
import MainpageUsingExample from './components/MainpageUsingExample';
import FAQ from './components/FAQ';
import PlayListContents from './components/PlayListContents';
import { useHistory, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import CreatorContents from './components/CreatorContents';

function DynamicInfo() {
  const history = useHistory();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  const onTabChange = (idx) => {
    history.push(`?idx=${idx}`);
  };

  useEffect(() => {
    const idx = location.search.substr(-1);
    setTabIndex(+idx);
  }, [location]);

  return (
    <Flex direction="column" mt="80px">
      <Tabs isFitted variant="enclosed" onChange={onTabChange} index={tabIndex}>
        <TabList mb="1em">
          <Tab>메인 상단 배너</Tab>
          <Tab>메인 인테리어 사례</Tab>
          <Tab>자주 묻는 질문</Tab>
          <Tab>빔몬스터 콘텐츠 그룹</Tab>
          <Tab>작가 콘텐츠 그룹</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <MainpageTopBanner />
          </TabPanel>
          <TabPanel>
            <MainpageUsingExample />
          </TabPanel>
          <TabPanel>
            <FAQ />
          </TabPanel>
          <TabPanel>
            <PlayListContents />
          </TabPanel>
          <TabPanel>
            <CreatorContents />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default DynamicInfo;
