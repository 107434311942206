import { Td } from '@chakra-ui/table';
import { Tag } from '@chakra-ui/tag';

export const ContactTableRow = ({
  id,
  title,
  fileUrl,
  writer,
  createdAt,
  isChecked,
  admin,
}) => {
  return (
    <>
      <Td>{id}</Td>
      <Td>{title}</Td>
      <Td>{writer.name}</Td>
      <Td>{createdAt}</Td>
      <Td>{fileUrl?.length > 0 && 'O'}</Td>
      <Td>
        {isChecked ? <Tag colorScheme="green">완료</Tag> : <Tag>대기</Tag>}
      </Td>
      <Td>{isChecked ? admin?.name : '-'}</Td>
    </>
  );
};
