import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Box, Center, Flex, Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import { useEffect, useState } from 'react';

export const ContentCategoryModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [categories, setCategories] = useState([]);
  const [newCategories, setNewCategories] = useState([]);
  const [contentCategories, setContentCategories] = useState([]);
  const [deleteCategories, setDeleteCategories] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get('contents/categories', {
        params: { admin: true },
      });
      setCategories(data.staticCategories);
      setNewCategories(data.staticCategories);
      setContentCategories(data.contentCategories);
      setDeleteCategories([]);
    };
    getData();
  }, [isOpen]);

  const onSaveCategories = async () => {
    const data = [];
    for (let i = 0; i < newCategories.length; i++) {
      if (!newCategories[i]) {
        toast({
          title: '비어있는 항목이 없어야 합니다',
          status: 'warning',
          isClosable: true,
        });
        return;
      }

      if (categories[i] === newCategories[i]) {
        continue;
      }

      data.push({
        oldCategory: categories[i] || null,
        newCategory: newCategories[i],
      });
    }
    await axios.patch('contents/categories', {
      updatedCategories: data,
      deleteCategories,
    });
    toast({
      title: '수정 되었습니다',
      status: 'success',
      isClosable: true,
    });
    onClose();
  };

  const onCategoryChange = (index, value) => {
    setNewCategories(
      newCategories.map((el, idx) => (idx === index ? value : el)),
    );
  };

  const onDeleteClick = (idx) => {
    setNewCategories(newCategories.filter((_el, index) => index !== idx));
    setCategories(categories.filter((_el, index) => index !== idx));
    setDeleteCategories(deleteCategories.concat(categories[idx]));
  };

  return (
    <>
      <Button fontSize="l" mr="4" onClick={onOpen}>
        카테고리 수정
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Text fontSize="xs" mt="10">
              해당 되는 콘텐츠의 카테고리도 모두 변경됩니다. <br /> 콘텐츠가
              있는 카테고리는 수정만 가능합니다.
            </Text>
            {newCategories.map((el, idx) => {
              return (
                <Flex key={idx} mt={4}>
                  <Input
                    disabled
                    value={categories[idx] ? categories[idx] : '신규'}
                  ></Input>
                  <Center mx={2}>
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </Center>
                  <Input
                    value={newCategories[idx]}
                    onChange={(e) => onCategoryChange(idx, e.target.value)}
                  ></Input>

                  <Button
                    disabled={contentCategories.indexOf(el) > -1}
                    colorScheme="red"
                    mx={4}
                    onClick={() => onDeleteClick(idx)}
                  >
                    삭제
                  </Button>
                </Flex>
              );
            })}
            <Button
              onClick={() => setNewCategories(newCategories.concat(''))}
              mt={10}
            >
              추가
            </Button>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onSaveCategories}>
              저장
            </Button>
            <Button variant="ghost" onClick={onClose}>
              취소
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
