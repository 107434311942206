import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const AuthContext = createContext(undefined);

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error();
  }
  return authContext;
};

const useProvideAuth = () => {
  const [user, setUser] = useState(null);
  const history = useHistory();

  const logout = () => {
    window.localStorage.removeItem('qiqi');
    axios.defaults.headers['Authorization'] = ``;
    setUser(null);
  };

  const updateUser = async (token) => {
    window.localStorage.setItem('qiqi', token);
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    const { data } = await axios.get('/auth/me');
    setUser(data);
  };

  useEffect(() => {
    const token = window.localStorage.getItem('qiqi');
    if (token) {
      updateUser(token);
    } else {
      history.replace('/auth/signin');
    }
  }, []);

  return {
    user,
    logout,
    updateUser,
  };
};
