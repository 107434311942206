import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { useDisclosure } from '@chakra-ui/hooks';
import { Input } from '@chakra-ui/input';
import {
  Box,
  Flex,
  Heading,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import {
  Table,
  TableContainer,
  Th,
  Tr,
  Thead,
  Tbody,
  Td,
} from '@chakra-ui/table';
import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

function PlayListContentEdit() {
  const [newName, setNewName] = useState('');
  const [contents, setContents] = useState([]);
  const { contentGroupName } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get('/contents/mainpage', {
        params: {
          isCreator: location.search.indexOf('isCreator') > -1,
        },
      });
      const contentGroup = data.find((el) => el.name === contentGroupName);

      if (!contentGroup || !contentGroupName) {
        history.push('/admin/static-service-info/dynamic/');
      }
      setContents(contentGroup.contents);
      setNewName(contentGroup.name);
    };
    getData();
  }, []);

  const createContent = (el) => {
    const isAlreadyExists = contents.find((content) => content.id === el.id);

    if (isAlreadyExists) {
      toast({
        position: 'top',
        status: 'error',
        title: '이미 존재하는 콘텐츠 입니다.',
      });
      return;
    }
    setContents(contents.concat(el));
  };

  const moveContent = (currentItemIndex, direction) => {
    const newContents = [...contents];

    if (direction === 'up') {
      if (currentItemIndex === 0) {
        return;
      }
      const temp = newContents[currentItemIndex - 1];
      newContents[currentItemIndex - 1] = newContents[currentItemIndex];
      newContents[currentItemIndex] = temp;
    } else {
      if (currentItemIndex === newContents.length - 1) {
        return;
      }
      const temp = newContents[currentItemIndex + 1];
      newContents[currentItemIndex + 1] = newContents[currentItemIndex];
      newContents[currentItemIndex] = temp;
    }
    setContents(newContents);
  };

  const modifyContentGroup = async () => {
    await axios.put(
      `service-statics/playlists`,
      {
        name: contentGroupName,
        newName,
        content_ids: contents.map((el) => el.id),
      },
      {
        params: {
          isCreator: location.search.indexOf('isCreator') > -1,
        },
      },
    );
    toast({
      position: 'top',
      status: 'success',
      title: '수정되었습니다.',
    });
    history.goBack();
  };

  const removeContent = (contentId) => {
    setContents(contents.filter((el) => el.id !== contentId));
  };

  return (
    <Flex direction="column" mt="80px">
      <FormControl>
        <FormLabel>그룹명</FormLabel>
        <Input value={newName} onChange={(e) => setNewName(e.target.value)} />
      </FormControl>

      <Text mt={10}>콘텐츠 목록</Text>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>번호</Th>
              <Th>콘텐츠명</Th>
              <Th>기능</Th>
            </Tr>
          </Thead>
          <Tbody>
            {contents.map((el, index) => {
              return (
                <Tr key={el.id}>
                  <Td>{el.id}</Td>
                  <Td>{el.title}</Td>
                  <Td>
                    <Button mr={3} onClick={() => moveContent(index, 'up')}>
                      위로
                    </Button>
                    <Button mr={3} onClick={() => removeContent(el.id)}>
                      삭제
                    </Button>
                    <Button onClick={() => moveContent(index, 'down')}>
                      아래로
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Button variant="solid" mt={3} onClick={onOpen}>
        콘텐츠 추가
      </Button>
      <Button
        variant={'solid'}
        colorScheme={'red'}
        mt={10}
        onClick={modifyContentGroup}
      >
        수정
      </Button>
      <AddContentModal
        isOpen={isOpen}
        onClose={onClose}
        createContent={createContent}
      />
    </Flex>
  );
}

function AddContentModal({ isOpen, onClose, createContent }) {
  const [searchWord, setSearchWord] = useState('');
  const [contents, setContents] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const toast = useToast();

  useEffect(() => {
    searchContents();
  }, []);

  const handleSearch = (key) => {
    if (key === 'Enter') {
      searchContents();
    }
  };

  const searchContents = async () => {
    const { data } = await axios.get('/contents', {
      params: {
        isCreatorContent: location.search.indexOf('isCreator') > -1,
        search: searchWord,
        limit: 1000,
      },
    });

    setContents(data.data);
  };

  const selectContent = (content) => {
    setSelectedContent(content);
  };

  const create = () => {
    if (!selectedContent) {
      toast({
        position: 'top',
        status: 'error',
        title: '콘텐츠를 먼저 선택해주세요.',
      });
    } else {
      createContent(selectedContent);
      onClose();
    }
  };

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>콘텐츠 추가</ModalHeader>
        <ModalBody minH={200}>
          <Flex>
            <Input
              placeholder="콘텐츠명"
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onKeyUp={(e) => handleSearch(e.key)}
            ></Input>
            <Button variant="ghost" onClick={() => handleSearch('Enter')}>
              검색
            </Button>
          </Flex>
          <OrderedList mt={2}>
            {contents.map((el) => {
              return (
                <ListItem>
                  <Flex>
                    <Text mr={2}>{el.title} - </Text>
                    <Text
                      style={{ cursor: 'pointer' }}
                      color="teal"
                      onClick={() => selectContent(el)}
                    >
                      선택
                    </Text>
                  </Flex>
                </ListItem>
              );
            })}
          </OrderedList>
          <Heading size="md" mt={5}>
            선택된 콘텐츠 {selectedContent && ` - ${selectedContent.title}`}
          </Heading>

          {selectedContent && (
            <Box p={10}>
              <video width="100%" controls autoPlay={false} preload="metadata">
                <source src={selectedContent.videoUrl} />
              </video>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={create}>
            추가
          </Button>
          <Button variant="ghost" onClick={onClose}>
            취소
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default PlayListContentEdit;
