import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { lineChartData, lineChartOptions } from 'variables/charts';

class LineChart extends React.Component {
  constructor(props) {
    super(props);
    const { data } = props;
    console.log(data);

    const chartData = [];

    for (const key in data) {
      let name;
      if (key === 'contentView') {
        name = '콘텐츠 시청(단위 백)';
        console.log(data[key]);
        data[key] = data[key].map((el) => Math.ceil(el / 100));
      } else if (key === 'userRegister') {
        name = '신규 가입';
      } else if (key === 'estimateComplete') {
        name = '견적서 완료';
      } else {
        name = '계약서 완료';
      }
      chartData.push({
        name,
        data: data[key],
      });
    }

    this.state = {
      chartData,
      chartOptions: {
        ...lineChartOptions,
        chart: {
          ...lineChartOptions.chart,
          events: {
            click: (event, chartContext, config) => {
              event.stopPropagation();
            },
            markerClick: (event, chartContext, config) => {
              event.stopPropagation();
            },
            legendClick: (event, chartContext, config) => {
              event.stopPropagation();
            },
          },
        },
        xaxis: {
          categories: [
            '6개월 전',
            '5개월 전',
            '4개월 전',
            '3개월 전',
            '2개월 전',
            '1개월 전',
            '이번 달',
          ],
        },
      },
    };
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="area"
        width="100%"
        height="100%"
      />
    );
  }
}

export default LineChart;
