import { Flex, Box } from '@chakra-ui/layout';
import {
  Table,
  Spacer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { Pagination, Select } from 'antd';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { SearchInput } from 'components/SearchInput';
import { usePagination } from 'hooks/use-pagination';
import { useState } from 'react';
import { AfterServiceDetailModal } from './AfterServiceDetailModal';
import { AfterServiceTableRow } from './AfterServiceTableRow';

export const AfterServiceList = () => {
  const [afterServices, setAfterServices] = useState([]);
  const [detailData, setDetailData] = useState(null);
  const colNames = [
    '번호',
    '카테고리',
    '상태',
    '신청자',
    '방문일자',
    '작성일',
    '답변일',
  ];
  const { currentPage, total, onPageChange, refreshData, setQuery } =
    usePagination('/after-services', setAfterServices);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchObj, setSearchObj] = useState({
    status: '',
    category: '',
  });

  const onRowClick = (rowId) => {
    const data = afterServices.find((el) => el.id === rowId);
    setDetailData(data);
    onOpen();
  };

  const onCloseModal = () => {
    setDetailData(null);
    onClose();
    refreshData();
  };

  const onClickSearch = (searchWord) => {
    setQuery({
      ...(searchObj.status && { status: searchObj.status }),
      category: searchObj.category,
      status: searchObj.status,
      search: searchWord,
      page: 1,
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader p="6px 0px 22px 0px">
            <Flex width="100%">
              <Box mr="10">
                <Text fontSize="xl" fontWeight="bold">
                  A/S 관리
                </Text>
              </Box>

              <Select
                style={{ width: 120, marginRight: '5px' }}
                onChange={(value) => {
                  onPageChange(1);
                  setSearchObj({ ...searchObj, category: value });
                }}
                allowClear
                onClear={() => setSearchObj({ ...searchObj, category: '' })}
                placeholder="카테고리"
                options={[
                  { value: null, label: '전체' },
                  { value: 'LED', label: 'LED' },
                  { value: '빔프로젝터', label: '빔프로젝터' },
                ]}
              />
              <Select
                style={{ width: 120, marginRight: '5px' }}
                onChange={(value) => {
                  onPageChange(1);
                  setSearchObj({ ...searchObj, status: value });
                }}
                allowClear
                onClear={() => setSearchObj({ ...searchObj, status: '' })}
                placeholder="상태"
                options={[
                  { value: null, label: '전체' },
                  { value: 'READY', label: '대기' },
                  { value: 'DONE', label: '완료' },
                ]}
              />
              <SearchInput setSearch={onClickSearch}></SearchInput>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple">
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  {colNames.map((colName, idx) => {
                    return (
                      <Th
                        color="gray.400"
                        key={idx}
                        ps={idx === 0 ? '0px' : null}
                      >
                        {colName}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {afterServices.map((row) => {
                  return (
                    <AfterServiceTableRow
                      onClick={() => onRowClick(row.id)}
                      key={row.id}
                      id={row.id}
                      category={row.category}
                      status={row.status}
                      admin={row.admin}
                      writer={row.writer}
                      visitDate={row.visitDate}
                      createdAt={row.createdAt}
                      updatedAt={row.updatedAt}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
          <Pagination
            showSizeChanger={false}
            current={currentPage}
            onChange={onPageChange}
            total={total}
          ></Pagination>
        </Card>
      </Flex>
      <AfterServiceDetailModal
        isOpen={isOpen}
        onClose={onCloseModal}
        data={detailData}
      ></AfterServiceDetailModal>
    </>
  );
};
