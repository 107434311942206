import { Flex, Box, Link } from '@chakra-ui/layout';
import {
  Table,
  Spacer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  Button,
  Textarea,
} from '@chakra-ui/react';
import moment from 'moment';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import axios from 'axios';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { ContactTableRow } from './ContactTableRow';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router';
import { DatePicker, Pagination } from 'antd';
import { usePagination } from 'hooks/use-pagination';

const { RangePicker } = DatePicker;

export const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedContact, setSelectedContact] = useState(null);
  const { currentPage, total, onPageChange, setQuery, refreshData } =
    usePagination('/contacts', setContacts);
  const [searchObj, setSearchObj] = useState({
    startDate: '',
    endDate: '',
  });

  const textColor = useColorModeValue('gray.700', 'white');
  const colNames = [
    '번호',
    '제목',
    '글쓴이',
    '등록일',
    '첨부파일',
    '체크',
    '확인관리자',
  ];

  const onClickSearch = () => {
    setQuery({
      startDate: searchObj.startDate
        ? searchObj.startDate.format('YYYY-MM-DD')
        : null,
      endDate: searchObj.endDate
        ? searchObj.endDate.format('YYYY-MM-DD')
        : null,
      page: 1,
    });
  };

  const onCheck = async (id) => {
    await axios.patch(`/contacts/${id}`, {
      isChecked: 'true',
    });
    refreshData();
  };

  const openModal = (row) => {
    setSelectedContact(row);
    onOpen();
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader p="6px 0px 22px 0px">
            <Flex>
              <Box mr={30}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  제휴문의 관리
                </Text>
              </Box>
              <Spacer></Spacer>
              <RangePicker
                allowClear={false}
                value={[
                  searchObj.startDate ? moment(searchObj.startDate) : '',
                  searchObj.endDate ? moment(searchObj.endDate) : '',
                ]}
                onChange={([start, end]) =>
                  setSearchObj({
                    ...searchObj,
                    startDate: moment(start),
                    endDate: moment(end),
                  })
                }
                placeholder={['검색시작일', '검색종료일']}
              />
              <Button onClick={onClickSearch}>검색</Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  {colNames.map((colName, idx) => {
                    return (
                      <Th
                        color="gray.400"
                        key={colName}
                        ps={idx === 0 ? '0px' : null}
                      >
                        {colName}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {contacts.map((row) => {
                  return (
                    <Tr onClick={() => openModal(row)} key={row.id}>
                      <ContactTableRow
                        id={row.id}
                        title={row.title}
                        fileUrl={row.fileUrl}
                        writer={row.writer}
                        createdAt={dayjs(row.createdAt).format(
                          'YYYY-MM-DD H:mm',
                        )}
                        isChecked={row.isChecked}
                        admin={row.admin}
                      />
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
          <Pagination
            showSizeChanger={false}
            current={currentPage}
            onChange={onPageChange}
            total={total}
          ></Pagination>
        </Card>
        {selectedContact && (
          <ContactModal
            contact={selectedContact}
            isOpen={isOpen}
            onClose={onClose}
            onCheck={onCheck}
          />
        )}
      </Flex>
    </>
  );
};

function ContactModal({ isOpen, onClose, onCheck, contact }) {
  const history = useHistory();
  const check = () => {
    onCheck(contact.id);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{contact.title}</ModalHeader>
        <ModalBody>
          <Box mt={2}>
            요청자:
            <Link
              onClick={() => history.push(`/admin/users/${contact.writer.id}`)}
            >
              {contact.writer.name}({contact.writer.phone}){' '}
              <ExternalLinkIcon mb="5px" />
            </Link>
          </Box>
          <Box p={1} mb={5}>
            내용
            <Textarea value={contact.content} disabled></Textarea>
          </Box>
          {contact.fileUrl?.length > 0 && (
            <Box mt={10}>
              {contact.fileUrl.map((el, idx) => (
                <Link download href={el} key={el}>
                  첨부파일{idx + 1} 다운로드
                </Link>
              ))}
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={check}
            disabled={contact.isChecked}
          >
            체크완료
          </Button>
          <Button variant="ghost" onClick={onClose}>
            취소
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
