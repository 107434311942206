import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

export const usePagination = (endpoint, setData, limit = 10) => {
  const [searchWord, setSearchWord] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const history = useHistory();
  const location = useLocation();

  const getData = async (page, query) => {
    for (const property in query) {
      const val = query[property];
      if (val === 'null' || val === 'undefined' || !val) {
        delete query[property];
      }
    }
    const res = await axios.get(endpoint, {
      params: {
        page: page - 1,
        limit,
        ...(searchWord !== 'null' && searchWord && { search: searchWord }),
        ...query,
      },
    });
    const { data, total } = res.data;
    setTotal(total);
    setData(data);
  };

  useEffect(() => {
    refreshData();
  }, [location, currentPage, searchWord]);

  const refreshData = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    params.delete('page');
    const search = params.get('search');
    params.delete('search');
    setSearchWord(search);
    const queryStringObj = Object.fromEntries(params);
    let tempQuery = {};
    for (let key in queryStringObj) {
      const val = queryStringObj[key];
      try {
        const parsed = JSON.parse(val);
        tempQuery[key] = parsed;
      } catch (e) {
        tempQuery[key] = val;
      }
    }
    if (page) {
      const numPage = Number(page);
      setCurrentPage(numPage);
      getData(numPage, tempQuery);
    } else {
      getData(1, tempQuery);
    }
  };

  const setQuery = (query) => {
    if (query.page) {
      setCurrentPage(query.page);
    }
    const params = new URLSearchParams(query);
    history.push(
      `${location.pathname}?page=${
        query.page || currentPage
      }&${params.toString()}`,
    );
  };

  const onPageChange = (page) => {
    const params = new URLSearchParams(location.search);
    params.delete('page');
    history.push(`${location.pathname}?page=${page}&${params.toString()}`);
    setCurrentPage(page);
  };

  const setSearch = (search) => {
    const params = new URLSearchParams(location.search);
    params.delete('search');
    params.delete('page');
    history.push(
      `${location.pathname}?search=${
        search ? encodeURIComponent(search) : ''
      }&page=${1}&${params.toString()}`,
    );
  };

  const setQuerystring = (querystring) => {
    history.push(`${location.pathname}?page=${1}&${querystring}`);
  };

  return {
    onPageChange,
    total,
    setTotal,
    setQuery,
    currentPage,
    setCurrentPage,
    setSearch,
    refreshData,
    setQuerystring,
  };
};
