// Chakra imports
import { Flex, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
// Custom icons
import {
  CartIcon,
  RocketIcon,
  StatsIcon,
  WalletIcon,
} from 'components/Icons/Icons.js';
import React from 'react';
import ChartStatistics from './ChartStatistics';

const ActiveUsers = ({ title, chart, data }) => {
  const iconBoxInside = useColorModeValue('white', 'white');
  const textColor = useColorModeValue('gray.700', 'white');
  return (
    <Card p="16px">
      <CardBody>
        <Flex direction="column" w="100%">
          {chart}
          <Flex direction="column" mt="24px" mb="16px" alignSelf="flex-start">
            <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
              {title}
            </Text>
          </Flex>
          <SimpleGrid gap={{ sm: '12px' }} columns={4}>
            <ChartStatistics
              title={'전체 유저'}
              amount={data.userRegister}
              percentage={100}
              icon={<WalletIcon h={'15px'} w={'15px'} color={iconBoxInside} />}
            />
            <ChartStatistics
              title={'콘텐츠재생'}
              amount={data.contentView}
              percentage={100}
              icon={<RocketIcon h={'15px'} w={'15px'} color={iconBoxInside} />}
            />
            <ChartStatistics
              title={'완료견적서'}
              amount={data.estimateComplete}
              percentage={100}
              icon={<CartIcon h={'15px'} w={'15px'} color={iconBoxInside} />}
            />
            <ChartStatistics
              title={'완료계약서'}
              amount={data.contractComplete}
              percentage={100}
              icon={<StatsIcon h={'15px'} w={'15px'} color={iconBoxInside} />}
            />
          </SimpleGrid>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ActiveUsers;
