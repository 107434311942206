import { Box, SimpleGrid, Text, VStack } from '@chakra-ui/layout';
import { Button, Input, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { ServiceManagerCard } from './ServiceManagerCard';

export const ServiceManagerList = () => {
  const [managers, setManagers] = useState([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const fileuploadRef = useRef();
  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get('/service-statics/service-managers');
      setManagers(data.value.data);
    };
    getData();
  }, []);

  const onManagerUpdate = async (managers) => {
    await axios.patch('/service-statics/service-managers', { data: managers });
    setManagers(managers);
  };

  const onDelete = async (index) => {
    const updatedManagers = managers.filter((_el, idx) => index !== idx);
    console.log(updatedManagers, index);
    await onManagerUpdate(updatedManagers);
    setManagers(updatedManagers);
  };

  const onSave = async () => {
    if (!name || !phone || !profileImageUrl) {
      toast({
        position: 'top',
        status: 'warning',
        title: '모든 값을 적어주세요.',
      });
    }
    await onManagerUpdate(managers.concat({ name, phone, profileImageUrl }));
    toast({
      position: 'top',
      status: 'success',
      title: '저장되었습니다',
    });

    fileuploadRef.current.value = '';
    setName('');
    setPhone('');
    setProfileImageUrl('');
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    const res = await axios.post('/utils/upload-files', formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const { url } = res.data[0];
    setProfileImageUrl(url);
  };

  return (
    <Box pt={{ base: '120px', md: '75px' }}>
      <Text fontSize="xl" mb="10px">
        서비스 기사 관리
      </Text>
      <SimpleGrid columns={4} spacing={5}>
        {managers.map((el, index) => (
          <ServiceManagerCard
            key={index}
            manager={el}
            idx={index}
            onDelete={onDelete}
          ></ServiceManagerCard>
        ))}
        <Box border="1px" borderColor="gray.400" height="280px" pt="20px">
          <VStack height="100%" p="10px" spacing={2} align="stretch">
            <Box>
              <Input
                border={false}
                type="file"
                ref={fileuploadRef}
                onChange={(e) => handleUpload(e.target.files[0])}
              ></Input>
            </Box>
            <Box>
              <Input
                placeholder="휴대폰 번호"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></Input>
            </Box>
            <Box>
              <Input
                placeholder="이름"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Input>
            </Box>
            <Button colorScheme="green" onClick={onSave}>
              새로운 매니저 추가
            </Button>
          </VStack>
        </Box>
      </SimpleGrid>
    </Box>
  );
};
