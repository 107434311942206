import { Flex, Box } from '@chakra-ui/layout';
import {
  Table,
  Spacer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { DatePicker, Pagination, Select } from 'antd';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { SearchInput } from 'components/SearchInput';
import moment from 'moment';
import { usePagination } from 'hooks/use-pagination';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { EstimateSetChangeCountModal } from './EstimateSetChangeCountModal';
import EstimateTableRow from './EstimateTableRow';

const { RangePicker } = DatePicker;

export const EstimateList = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [estimates, setEstimates] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchObj, setSearchObj] = useState({
    status: '',
    startDate: '',
    endDate: '',
  });
  const { currentPage, total, onPageChange, setQuery } = usePagination(
    '/estimates',
    setEstimates,
  );

  const colNames = [
    '번호',
    '주문자',
    '하드웨어',
    '주문상품',
    '요청일자',
    '상태',
    '승인관리자',
  ];

  const handleTemplateClick = () => {
    history.push('/admin/estimates/templates');
  };

  const goDetail = (id) => {
    history.push('/admin/estimates/' + id);
  };

  const onClickSearch = (searchWord) => {
    setQuery({
      ...(searchObj.status && { status: searchObj.status }),
      startDate: searchObj.startDate
        ? searchObj.startDate.format('YYYY-MM-DD')
        : null,
      endDate: searchObj.endDate
        ? searchObj.endDate.format('YYYY-MM-DD')
        : null,
      search: searchWord,
      page: 1,
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader p="6px 0px 22px 0px">
            <Flex width="100%">
              <Box>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  견적서 관리
                </Text>
              </Box>
              <Spacer></Spacer>
              <Button onClick={handleTemplateClick}>템플릿 관리</Button>
            </Flex>
          </CardHeader>
          <CardHeader p="6px 0px 22px 0px">
            <Box w="100%">
              <Flex mt="3px" alignItems="center">
                <Select
                  style={{ width: 120, marginRight: '5px' }}
                  onChange={(value) => {
                    onPageChange(1);
                    setSearchObj({ ...searchObj, status: value });
                  }}
                  allowClear
                  onClear={() => setSearchObj({ ...searchObj, status: '' })}
                  placeholder="상태"
                  options={[
                    { value: null, label: '전체' },
                    { value: 'CONFIRM', label: '완료' },
                    { value: 'PENDING_CHECK_ADMIN', label: '검토필요' },
                    { value: 'DELETED', label: '삭제' },
                  ]}
                />
                <RangePicker
                  allowClear={false}
                  value={[
                    searchObj.startDate ? moment(searchObj.startDate) : '',
                    searchObj.endDate ? moment(searchObj.endDate) : '',
                  ]}
                  onChange={([start, end]) =>
                    setSearchObj({
                      ...searchObj,
                      startDate: moment(start),
                      endDate: moment(end),
                    })
                  }
                  placeholder={['검색시작일', '검색종료일']}
                />
                <SearchInput setSearch={onClickSearch}></SearchInput>
                <p style={{ marginLeft: '3px' }}>갯수: {total} 개</p>
              </Flex>
            </Box>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  {colNames.map((colName, idx) => {
                    return (
                      <Th
                        color="gray.400"
                        key={idx}
                        ps={idx === 0 ? '0px' : null}
                      >
                        {colName}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {estimates.map((row) => {
                  return (
                    <Tr key={row.id} onClick={() => goDetail(row.id)}>
                      <EstimateTableRow data={row} />
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
          <Box mt="5">
            <Pagination
              showSizeChanger={false}
              current={currentPage}
              onChange={onPageChange}
              total={total}
            ></Pagination>
          </Box>
        </Card>
      </Flex>
      <EstimateSetChangeCountModal
        isOpen={isOpen}
        onClose={onClose}
      ></EstimateSetChangeCountModal>
    </>
  );
};
