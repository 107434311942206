import { Td } from '@chakra-ui/react';
import React from 'react';

function AdvertiseStoreTableRow(props) {
  const { data } = props;

  for (let i = 0; i < 6; i++) {
    if (!data.advertises[i]) {
      data.advertises[i] = '미판매';
    }
  }

  return (
    <>
      <Td>{data.businessName}</Td>
      <Td>{data.location}</Td>
      <Td>{data.businessCategory}</Td>
      {data.advertises.map((el, idx) => (
        <Td key={idx} style={{ color: el === '미판매' && 'silver' }}>
          {el}
        </Td>
      ))}
    </>
  );
}

export default AdvertiseStoreTableRow;
