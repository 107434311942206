// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Input,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import draftToHtml from 'draftjs-to-html';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Radio } from 'antd';

function FooterInfo() {
  const [data, setData] = useState({});
  const toast = useToast();
  const [privacyEditorState, setPrivacyEditorState] = useState(
    EditorState.createEmpty(),
  );
  const [serviceEditorState, setServiceEditorState] = useState(
    EditorState.createEmpty(),
  );

  const [advertiseRefundEditorState, setAdvertiseRefundEditorState] = useState(
    EditorState.createEmpty(),
  );
  const [contentRefundEditorState, setContentRefundEditorState] = useState(
    EditorState.createEmpty(),
  );

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get('/service-statics/company');
      setData(data.value);
      if (data.value.serviceTerm) {
        const contentBlock = htmlToDraft(data.value.serviceTerm);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
          );
          const editorState = EditorState.createWithContent(contentState);
          setServiceEditorState(editorState);
        }
      }

      if (data.value.privacyTerm) {
        const contentBlock = htmlToDraft(data.value.privacyTerm);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
          );
          const editorState = EditorState.createWithContent(contentState);
          setPrivacyEditorState(editorState);
        }
      }
      if (data.value.advertiseRefundTerm) {
        const contentBlock = htmlToDraft(data.value.advertiseRefundTerm);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
          );
          const editorState = EditorState.createWithContent(contentState);
          setAdvertiseRefundEditorState(editorState);
        }
      }
    };
    getData();
  }, []);

  const textColor = useColorModeValue('gray.700', 'white');

  const onSave = async () => {
    setTimeout(async () => {
      await axios.patch('/service-statics/company', data);
      toast({
        title: '저장되었습니다',
        status: 'success',
        isClosable: true,
      });
    }, 300);
  };

  const setContentRefundTerm = (editorState) => {
    setData({
      ...data,
      contentRefundTerm: draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      ),
    });
    setContentRefundEditorState(editorState);
  };
  const setAdvertiseRefundTerm = (editorState) => {
    setData({
      ...data,
      advertiseRefundTerm: draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      ),
    });
    setAdvertiseRefundEditorState(editorState);
  };

  const setServiceTerm = (editorState) => {
    setData({
      ...data,
      serviceTerm: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
    setServiceEditorState(editorState);
  };

  const setPrivacyTerm = (editorState) => {
    setData({
      ...data,
      privacyTerm: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
    setPrivacyEditorState(editorState);
  };

  return (
    <Flex direction="column" mt="80px">
      <Grid templateColumns={{ sm: '1fr', xl: 'repeat(1, 1fr)' }} gap="22px">
        <Card p="16px" my={{ sm: '24px', xl: '0px' }}>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              사이트 정보
            </Text>
          </CardHeader>
          <Text fontSize="md" color={textColor} fontWeight="bold" my="10px">
            최소 광고 기간(일 단위)
          </Text>
          <Input
            placeholder="일 단위로 입력해주세요."
            value={data.minAdPurchaseDays}
            onChange={(e) => {
              const value = e.target.value;
              if (Number(value) == +value) {
                setData({ ...data, minAdPurchaseDays: e.target.value });
              }
            }}
          ></Input>
          <Text fontSize="md" color={textColor} fontWeight="bold" my="10px">
            광고 송출 간격
          </Text>
          <Radio.Group
            onChange={(e) =>
              setData({ ...data, advertiseInterval: e.target.value })
            }
            value={data.advertiseInterval}
          >
            <Radio value={5}>5분간격</Radio>
            <Radio value={10}>10분간격</Radio>
          </Radio.Group>
          <Text fontSize="md" color={textColor} fontWeight="bold" my="10px">
            SNS 주소
          </Text>
          <CardBody px="5px">
            <Flex direction="column">
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  회사명{' '}
                  <Input
                    value={data.businessName}
                    onChange={(e) =>
                      setData({ ...data, businessName: e.target.value })
                    }
                  ></Input>
                </Text>
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  주소{' '}
                  <Input
                    value={data.address}
                    onChange={(e) =>
                      setData({ ...data, address: e.target.value })
                    }
                  ></Input>
                </Text>
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  대표 전화번호{' '}
                  <Input
                    value={data.phone}
                    onChange={(e) =>
                      setData({ ...data, phone: e.target.value })
                    }
                  ></Input>
                </Text>
              </Flex>
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  대표명{' '}
                  <Input
                    value={data.ceoName}
                    onChange={(e) =>
                      setData({ ...data, ceoName: e.target.value })
                    }
                  ></Input>
                </Text>
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  사업자등록번호{' '}
                  <Input
                    value={data.businessNumber}
                    onChange={(e) =>
                      setData({ ...data, businessNumber: e.target.value })
                    }
                  ></Input>
                </Text>
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  통신판매업 신고 번호
                  <Input
                    value={data.onlineSaleRegistrationNumber}
                    onChange={(e) =>
                      setData({
                        ...data,
                        onlineSaleRegistrationNumber: e.target.value,
                      })
                    }
                  ></Input>
                </Text>
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  이메일
                  <Input
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  ></Input>
                </Text>
              </Flex>
              <Text fontSize="md" color={textColor} fontWeight="bold" my="10px">
                SNS 주소
              </Text>
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  인스타그램{' '}
                  <Input
                    value={data.instagramUrl}
                    onChange={(e) =>
                      setData({ ...data, instagramUrl: e.target.value })
                    }
                  ></Input>
                </Text>
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  페이스북{' '}
                  <Input
                    value={data.facebookUrl}
                    onChange={(e) =>
                      setData({ ...data, facebookUrl: e.target.value })
                    }
                  ></Input>
                </Text>
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  유튜브{' '}
                  <Input
                    value={data.youtubeUrl}
                    onChange={(e) =>
                      setData({ ...data, youtubeUrl: e.target.value })
                    }
                  ></Input>
                </Text>
              </Flex>
              <Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
                개인정보처리방침{' '}
              </Text>
              <Editor
                editorState={privacyEditorState}
                toolbar={{
                  options: ['inline', 'fontSize', 'list', 'textAlign'],
                }}
                wrapperStyle={{ border: '1px solid rgb(226, 232, 240)' }}
                editorStyle={{ paddingLeft: '10px', height: '300px' }}
                toolbarStyle={{
                  border: 'none',
                  borderBottom: '1px solid rgb(226, 232, 240)',
                }}
                onEditorStateChange={setPrivacyTerm}
              />
              <Text
                fontSize="md"
                color={textColor}
                fontWeight="bold"
                me="10px"
                mt="30px"
              >
                이용약관{' '}
              </Text>
              <Editor
                editorState={serviceEditorState}
                toolbar={{
                  options: ['inline', 'fontSize', 'list', 'textAlign'],
                }}
                wrapperStyle={{ border: '1px solid rgb(226, 232, 240)' }}
                onEditorStateChange={setServiceTerm}
                editorStyle={{ paddingLeft: '10px', height: '300px' }}
                toolbarStyle={{
                  border: 'none',
                  borderBottom: '1px solid rgb(226, 232, 240)',
                }}
              />
              <Text
                fontSize="md"
                color={textColor}
                fontWeight="bold"
                me="10px"
                mt="30px"
              >
                광고 환불정책{' '}
              </Text>
              <Editor
                editorState={advertiseRefundEditorState}
                toolbar={{
                  options: ['inline', 'fontSize', 'list', 'textAlign'],
                }}
                wrapperStyle={{ border: '1px solid rgb(226, 232, 240)' }}
                onEditorStateChange={setAdvertiseRefundTerm}
                editorStyle={{ paddingLeft: '10px', height: '300px' }}
                toolbarStyle={{
                  border: 'none',
                  borderBottom: '1px solid rgb(226, 232, 240)',
                }}
              />
              {/*
              <Text
                fontSize="md"
                color={textColor}
                fontWeight="bold"
                me="10px"
                mt="30px"
              >
                작가 콘텐츠 환불정책{' '}
              </Text>
              <Editor
                editorState={contentRefundEditorState}
                toolbar={{
                  options: ['inline', 'fontSize', 'list', 'textAlign'],
                }}
                wrapperStyle={{ border: '1px solid rgb(226, 232, 240)' }}
                onEditorStateChange={setContentRefundTerm}
                editorStyle={{ paddingLeft: '10px', height: '300px' }}
                toolbarStyle={{
                  border: 'none',
                  borderBottom: '1px solid rgb(226, 232, 240)',
                }}
              />
      */}
            </Flex>
          </CardBody>
        </Card>
        <Button colorScheme="green" onClick={onSave}>
          변경 저장
        </Button>
      </Grid>
    </Flex>
  );
}

export default FooterInfo;
