import { useAuth } from 'hooks/use-auth';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const LogOut = () => {
  const { logout } = useAuth();
  const history = useHistory();

  useEffect(() => {
    logout();
    history.replace('/auth/signin');
  }, []);
  return <></>;
};
