import { Button, IconButton } from '@chakra-ui/button';
import Upload from 'rc-upload';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { useDisclosure } from '@chakra-ui/hooks';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Box, Center, Flex, SimpleGrid, Spacer, Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/toast';
import { uploadFile } from 'utils';

function MainpageTopBanner() {
  const [themes, setThemes] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    const getMainpageData = async () => {
      const { data } = await axios.get('/service-statics/mainpage');
      setThemes(data.value.themes);
    };
    getMainpageData();
  }, []);

  const saveThemes = async () => {
    await axios.patch('/service-statics/mainpage', {
      themes,
    });
    toast({
      position: 'top',
      status: 'success',
      title: '저장 되었습니다.',
    });
  };

  const createTheme = async ({ name, url, thumbnailImageUrl }) => {
    setThemes(themes.concat({ name, url, thumbnailImageUrl }));
  };

  const deleteTheme = async (url) => {
    setThemes(themes.filter((el) => el.url !== url));
  };

  const move = (url, direction) => {
    const newThemes = [...themes];
    const currentItemIndex = newThemes.map((el) => el.url).indexOf(url);

    if (direction === 'left') {
      if (currentItemIndex === 0) {
        return;
      }
      const temp = newThemes[currentItemIndex - 1];
      newThemes[currentItemIndex - 1] = newThemes[currentItemIndex];
      newThemes[currentItemIndex] = temp;
    } else {
      if (currentItemIndex === themes.length - 1) {
        return;
      }
      const temp = newThemes[currentItemIndex + 1];
      newThemes[currentItemIndex + 1] = newThemes[currentItemIndex];
      newThemes[currentItemIndex] = temp;
    }
    setThemes(newThemes);
  };

  return (
    <>
      <Flex>
        <Button onClick={onOpen} colorScheme={'red'} size={'md'}>
          새로운 아이템
        </Button>
        <Spacer />
        <Button colorScheme="green" mb={30} onClick={saveThemes}>
          변경사항 저장
        </Button>
      </Flex>
      <SimpleGrid columns={4} spacing={5}>
        {themes.map((el) => {
          return (
            <ContentCard
              move={move}
              deleteTheme={deleteTheme}
              key={el.url}
              url={el.url}
              name={el.name}
            ></ContentCard>
          );
        })}
        <CreateModal
          isOpen={isOpen}
          onClose={onClose}
          createTheme={createTheme}
        />
      </SimpleGrid>
    </>
  );
}

function CreateModal({ isOpen, onClose, createTheme }) {
  const [url, setUrl] = useState('');
  const [name, setName] = useState('');
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState('');
  const [progress, setProgress] = useState(null);
  const [onUploading, setOnUploading] = useState(false);
  const toast = useToast();

  const changeName = (e) => {
    setName(e.target.value);
  };

  const create = () => {
    if (!url || !name || !thumbnailImageUrl) {
      toast({
        position: 'top',
        status: 'error',
        title: '파일 업로드와 이름을 적어주세요',
      });
    } else {
      createTheme({ name, url, thumbnailImageUrl });
      setName('');
      setThumbnailImageUrl('');
      setUrl('');
      setProgress('');
      onClose();
    }
  };

  const uploadFileToS3 = async ({ file }, type) => {
    if (type !== 'thumbnailImageUrl') {
      setProgress(`업로드 준비중`);
      setOnUploading(true);
    }

    const upload = uploadFile(file, file.name);
    upload.on('httpUploadProgress', (progress) => {
      const { loaded, total, Key } = progress;
      const pro = Math.floor((loaded / total) * 100);
      setProgress(`업로드 중 - ${pro} %`);
      if (pro === 100) {
        if (type === 'thumbnailImageUrl') {
          setThumbnailImageUrl(
            `https://beam-monster-backend.s3.ap-northeast-2.amazonaws.com/${Key}`,
          );
        } else {
          setOnUploading(false);
          setProgress(`업로드 완료 - ${file.name}`);
          setUrl(
            `https://beam-monster-backend.s3.ap-northeast-2.amazonaws.com/${Key}`,
          );
        }
      }
    });
    await upload.done();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>신규 생성</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>이름</FormLabel>
            <Input value={name} onChange={changeName} />
          </FormControl>
          <FormLabel>영상 파일</FormLabel>
          <Upload customRequest={uploadFileToS3} accept="video/*">
            <Button>파일 선택</Button>
          </Upload>
          <Text>{progress && `${progress}`}</Text>
          <FormLabel mt={7}>썸네일 이미지</FormLabel>
          <Upload
            customRequest={(e) => uploadFileToS3(e, 'thumbnailImageUrl')}
            accept="image/*"
          >
            <Button>파일 선택</Button>
          </Upload>
          <Box>
            <img src={thumbnailImageUrl} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={create}
            disabled={onUploading}
          >
            저장
          </Button>
          <Button variant="ghost" onClick={onClose}>
            취소
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ContentCard({ name, url, deleteTheme, move }) {
  return (
    <Box>
      <Box border="1px" borderColor={'gray.200'}>
        <video width="100%" controls autoPlay={false} preload="metadata">
          <source src={url} />
        </video>
      </Box>
      <Flex>
        <IconButton
          onClick={() => move(url, 'left')}
          variant={'ghost'}
          icon={<ArrowLeftIcon />}
        ></IconButton>
        <Spacer />
        <Center h="40px">
          <Text fontSize="sm">{name}</Text>
        </Center>

        <Spacer />
        <IconButton
          onClick={() => move(url, 'right')}
          variant={'ghost'}
          icon={<ArrowRightIcon />}
        ></IconButton>
      </Flex>
      <Button
        onClick={() => deleteTheme(url)}
        variant={'solid'}
        colorScheme={'red'}
        w={'100%'}
        size={'xs'}
      >
        삭제
      </Button>
    </Box>
  );
}

export default MainpageTopBanner;
