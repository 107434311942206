import { Button, IconButton } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { useDisclosure } from '@chakra-ui/hooks';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Flex, Spacer } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/toast';
import {
  Table,
  Tbody,
  Td,
  Tr,
  Th,
  Thead,
  TableContainer,
} from '@chakra-ui/table';
import { useHistory } from 'react-router';

function PlayListContents() {
  const [contentGroups, setContentGroups] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    const getContentGroups = async () => {
      const { data } = await axios.get('/contents/mainpage');
      setContentGroups(data);
    };
    getContentGroups();
  }, []);

  const createContentGroup = async (name) => {
    if (contentGroups.find((el) => el.name === name)) {
      toast({
        position: 'top',
        status: 'error',
        title: '이미 존재하는 그룹명입니다.',
      });
    } else {
      setContentGroups(contentGroups.concat({ name, contents: [] }));
    }
  };

  const saveContentGroup = async () => {
    await axios.patch('/service-statics/playlists', {
      data: contentGroups.map((el) => {
        return { name: el.name, content_ids: el.contents.map((el) => el.id) };
      }),
    });
    toast({
      position: 'top',
      status: 'success',
      title: '저장 되었습니다.',
    });
  };

  return (
    <>
      <Flex>
        <Button onClick={onOpen} colorScheme={'red'} size={'md'}>
          새로운 그룹
        </Button>
        <Spacer />
        <Button colorScheme="green" mb={30} onClick={saveContentGroup}>
          변경 저장
        </Button>
      </Flex>
      <ContentGroupTable
        contentGroups={contentGroups}
        setContentGroups={setContentGroups}
      ></ContentGroupTable>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        createContentGroup={createContentGroup}
      />
    </>
  );
}

function CreateModal({ isOpen, onClose, createContentGroup }) {
  const [name, setName] = useState('');
  const toast = useToast();

  const resetState = () => {
    setName('');
  };

  const create = () => {
    if (!name) {
      toast({
        position: 'top',
        status: 'error',
        title: '그룹명을 작성해주세요.',
      });
    } else {
      createContentGroup(name);
      resetState();
      onClose();
    }
  };

  return (
    <Modal
      size={'2xl'}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>신규 그룹 생성</ModalHeader>
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel>그룹명</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={create}>
            저장
          </Button>
          <Button variant="ghost" onClick={onClose}>
            취소
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ContentGroupTable({ contentGroups, setContentGroups }) {
  const history = useHistory();

  const deleteContentGroup = async (targetIndex) => {
    setContentGroups(contentGroups.filter((_el, idx) => targetIndex !== idx));
  };

  const move = (currentItemIndex, direction) => {
    const newContentGroups = [...contentGroups];

    if (direction === 'left') {
      if (currentItemIndex === 0) {
        return;
      }
      const temp = newContentGroups[currentItemIndex - 1];
      newContentGroups[currentItemIndex - 1] =
        newContentGroups[currentItemIndex];
      newContentGroups[currentItemIndex] = temp;
    } else {
      if (currentItemIndex === contentGroups.length - 1) {
        return;
      }
      const temp = newContentGroups[currentItemIndex + 1];
      newContentGroups[currentItemIndex + 1] =
        newContentGroups[currentItemIndex];
      newContentGroups[currentItemIndex] = temp;
    }
    setContentGroups(newContentGroups);
  };

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>제목</Th>
            <Th>콘텐츠 갯수</Th>
            <Th>기능</Th>
          </Tr>
        </Thead>
        <Tbody>
          {contentGroups.map((el, idx) => {
            return (
              <Tr key={el.name}>
                <Td>{el.name}</Td>
                <Td>{el.contents.length} 개</Td>
                <Td>
                  <IconButton
                    onClick={() => move(idx, 'left')}
                    size={'sm'}
                    mr={1}
                    icon={<TriangleUpIcon />}
                  />
                  <IconButton
                    onClick={() => move(idx, 'right')}
                    size={'sm'}
                    mr={2}
                    icon={<TriangleDownIcon />}
                  />
                  <Button
                    size="sm"
                    mr={2}
                    onClick={() => {
                      history.push(
                        `/admin/static-service-info/dynamic/playlist-groups/${el.name}`,
                      );
                    }}
                  >
                    수정
                  </Button>
                  <Button
                    colorScheme={'red'}
                    size="sm"
                    onClick={() => deleteContentGroup(idx)}
                  >
                    삭제
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default PlayListContents;
