import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Box,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';

export const EstimateSetChangeCountModal = ({ isOpen, onClose }) => {
  const [basicCount, setBasicCount] = useState(10);
  const [standardCount, setStandardCount] = useState(20);
  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get(
        '/service-statics/content-change-counts',
      );
      const { basic, standard } = data.value;
      setBasicCount(basic);
      setStandardCount(standard);
    };
    getData();
  }, []);

  const onSave = async () => {
    await axios.patch('/service-statics/content-change-counts', {
      basic: basicCount,
      standard: standardCount,
    });

    toast({
      position: 'top',
      status: 'success',
      title: '저장되었습니다',
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>솔루션 별 수정 횟수 설정</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box bg="tomato" w="100%" p={3} color="white" mb={3}>
            <Text fontSize="sm" mt>
              횟수가 20회로 설정되어있다면, 10개의 플레이리스트를 채운 이 후
              10번의 수정 기회가 있다는 의미입니다.
              <br />
              (한번에 3개를 바꾸는 것은 3회 수정으로 간주함)
            </Text>
          </Box>
          베이직
          <NumberInput
            value={basicCount}
            onChange={(value) => setBasicCount(value)}
            mb={4}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          스탠다드
          <NumberInput
            value={standardCount}
            onChange={(value) => setStandardCount(value)}
            mb={4}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onSave}>
            저장
          </Button>
          <Button onClick={onClose} variant="ghost">
            취소
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
