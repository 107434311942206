import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Flex } from '@chakra-ui/layout';
import {
  Table,
  Spacer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react';
import { Pagination } from 'antd';
import axios from 'axios';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { SearchInput } from 'components/SearchInput';
import dayjs from 'dayjs';
import { usePagination } from 'hooks/use-pagination';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CreatorOrderStatus } from 'utils';
import { commaThreeDigit } from 'utils';
import { utils, writeFileXLSX } from 'xlsx';

const colNames = ['번호', '구매자명', '주문상태', '작품명', '금액', '판매일자'];
export default function CreatorOrderList() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [orders, setContents] = useState([]);
  const [excelStartDate, setExcelStartDate] = useState('');
  const [excelEndDate, setExcelEndDate] = useState('');
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [downloadStatusPerAuthor, setDownloadStatusPerAuthor] = useState(null);
  const { currentPage, total, onPageChange, setSearch } = usePagination(
    '/creators/orders',
    setContents,
  );
  const toast = useToast();

  const excelDownloadPerAuthor = async () => {
    if (
      !excelStartDate ||
      !excelEndDate ||
      !dayjs(excelStartDate)
        .startOf('d')
        .isBefore(dayjs(excelEndDate).endOf('d'))
    ) {
      toast({
        title: '시작일 종료일을 정확히 적어주세요.',
        status: 'warning',
        isClosable: true,
      });
    } else {
      setDownloadStatusPerAuthor('데이터베이스 조회 중...');
      const { data } = await axios.get('/creators/orders', {
        params: {
          limit: 10000000,
          startDate: excelStartDate,
          endDate: excelEndDate,
        },
      });
      setDownloadStatusPerAuthor('엑셀 파일 생성 중...');
      const ws = [];
      const authors = {};
      for (let i = 0; i < data.data.length; i++) {
        const { contents } = data.data[i];
        for (let j = 0; j < contents.length; j++) {
          const { price, creator } = contents[j];
          const { id, name } = creator;
          if (!authors[id]) {
            authors[id] = {};
            authors[id].amount = price;
            authors[id].name = name;
            authors[id].orderIds = [data.data[i].id];
          } else {
            authors[id].amount += price;
            authors[id].orderIds.push(data.data[i].id);
          }
        }
      }
      for (const key in authors) {
        const author = authors[key];

        const uniqueOrderIds = [...new Set(author.orderIds)];

        const order = {};
        order['1'] = key;
        order['2'] = author.name;
        order['3'] = author.amount;
        order['4'] = uniqueOrderIds.join(',');
        order['5'] = uniqueOrderIds.length;
        ws.push(order);
      }
      const worksheet = utils.json_to_sheet(ws);

      utils.sheet_add_aoa(
        worksheet,
        [['작가번호', '작가명', '총 주문금액', '주문 번호', '총주문건수']],
        { origin: 'A1' },
      );
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, '주문');
      writeFileXLSX(workbook, '작가별 작가 콘텐츠 주문.xlsx');
      setDownloadStatusPerAuthor(null);
    }
  };

  const excelDownload = async () => {
    if (
      !excelStartDate ||
      !excelEndDate ||
      !dayjs(excelStartDate)
        .startOf('d')
        .isBefore(dayjs(excelEndDate).endOf('d'))
    ) {
      toast({
        title: '시작일 종료일을 정확히 적어주세요.',
        status: 'warning',
        isClosable: true,
      });
    } else {
      setDownloadStatus('데이터베이스 조회 중...');
      const { data } = await axios.get('/creators/orders', {
        params: {
          limit: 10000000,
          startDate: excelStartDate,
          endDate: excelEndDate,
        },
      });
      setDownloadStatus('엑셀 파일 생성 중...');
      const ws = [];
      for (let i = 0; i < data.data.length; i++) {
        const { merchant_uid, buyer, paidDate, totalPrice, contents } =
          data.data[i];
        const order = {};
        const creatorIds = [...new Set(contents.map((el) => el.creator.id))];
        order['1'] = merchant_uid;
        order['2'] = buyer.id;
        order['3'] = buyer.name;
        order['4'] = dayjs(paidDate).format('YYYY-MM-DD HH:mm');
        order['5'] = totalPrice;
        order['6'] = contents.map((el) => el.id).join(',');
        order['7'] = creatorIds.join(',');
        ws.push(order);
      }
      const worksheet = utils.json_to_sheet(ws);

      utils.sheet_add_aoa(
        worksheet,
        [
          [
            '주문번호',
            '고객번호',
            '고객이름',
            '주문일자',
            '주문금액',
            '주문 콘텐츠 번호',
            '주문 작가 번호',
          ],
        ],
        { origin: 'A1' },
      );
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, '주문');
      writeFileXLSX(workbook, '작가 콘텐츠 주문.xlsx');
      setDownloadStatus(null);
    }
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex width="100%">
            <Box>
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                작가 콘텐츠 판매 현황
              </Text>
            </Box>
            <SearchInput setSearch={setSearch}></SearchInput>
            <Spacer></Spacer>
            <div
              style={{
                border: '1px solid grey',
                padding: '5px',
              }}
            >
              <Flex justifyItems="center">
                <div style={{ marginRight: '2px' }}>
                  <p>시작일</p>
                  <Input
                    type="date"
                    size="sm"
                    value={excelStartDate}
                    onChange={(e) => setExcelStartDate(e.target.value)}
                  />
                </div>
                <div>
                  <p>종료일</p>
                  <Input
                    type="date"
                    size="sm"
                    value={excelEndDate}
                    onChange={(e) => setExcelEndDate(e.target.value)}
                  />
                </div>
                <Button
                  variant="outline"
                  my="auto"
                  ml={2}
                  size="sm"
                  onClick={excelDownload}
                  disabled={downloadStatus}
                >
                  {downloadStatus || '주문 별 판매량 엑셀 저장'}
                </Button>
                <Button
                  variant="outline"
                  my="auto"
                  ml={2}
                  size="sm"
                  onClick={excelDownloadPerAuthor}
                  disabled={downloadStatusPerAuthor}
                >
                  {downloadStatusPerAuthor || '작가 별 판매량 엑셀 저장'}
                </Button>
              </Flex>
            </div>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                {colNames.map((colName, idx) => {
                  return (
                    <Th
                      color="gray.400"
                      key={idx}
                      ps={idx === 0 ? '0px' : null}
                    >
                      {colName}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {orders.map((order) => (
                <Tr
                  key={order.id}
                  onClick={() =>
                    history.push(`/admin/creator-content-orders/${order.id}`)
                  }
                >
                  <Td>{order.id}</Td>
                  <Td>{order.buyer.name}</Td>
                  <Td>{CreatorOrderStatus(order.status)}</Td>
                  <Td>
                    {order.contents[0].title}{' '}
                    {order.contents.length - 1 !== 0 &&
                      `외 ${order.contents.length - 1}개`}
                  </Td>
                  <Td>{commaThreeDigit(order.totalPrice)} 원</Td>
                  <Td>{dayjs(order.paidDate).format('YYYY-MM-DD')}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
        <Pagination
          showSizeChanger={false}
          current={currentPage}
          onChange={onPageChange}
          total={total}
        ></Pagination>
      </Card>
    </Flex>
  );
}
