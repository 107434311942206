import { Button, ButtonGroup } from '@chakra-ui/button';
import {
  Editable,
  EditablePreview,
  EditableTextarea,
} from '@chakra-ui/editable';
import { useDisclosure } from '@chakra-ui/hooks';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import {
  Box,
  Center,
  Flex,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/layout';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/modal';
import { useToast } from '@chakra-ui/toast';
import { Badge, Descriptions } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { commaThreeDigit } from 'utils';
import { AdvertiseOrderStatus } from 'utils';
import AdvertiseOrderSetVideo from './AdvertiseOrderSetVideo';

function AdvertiseOrderDetail() {
  const [advertiseOrder, setAdvertiseOrder] = useState(null);
  const [refundAmount, setRefundAmount] = useState(null);
  const param = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const getData = async () => {
      const { advertiseOrder_id } = param;
      const { data } = await axios.get(
        '/advertises/orders/' + advertiseOrder_id,
      );

      setAdvertiseOrder(data);
    };
    getData();
  }, []);

  if (!advertiseOrder) {
    return null;
  }

  const onConfirm = async () => {
    onClose();
    await axios.post(`/estimates/${advertiseOrder.id}/confirm`, advertiseOrder);
    toast({
      position: 'top',
      status: 'success',
      title: '발송되었습니다.',
    });
    history.push('/admin/estimates');
  };

  const onConfirmClick = async () => {
    const answer = window.confirm('정말로 승인하시겠습니까?');

    if (answer) {
      await axios.post(`/advertises/orders/${advertiseOrder.id}/confirm`);
      toast({
        position: 'top',
        status: 'success',
        title: '승인 처리 되었습니다.',
      });
      history.go(0);
    }
  };

  const onRejectClick = async () => {
    const answer = window.confirm('정말로 거절하시겠습니까?');

    if (answer) {
      await axios.delete(`/advertises/orders/${advertiseOrder.id}/reject`, {
        rejectedText: advertiseOrder.rejectedText,
      });
      toast({
        position: 'top',
        status: 'success',
        title: '거절 처리 되었습니다.',
      });
      history.go(0);
    }
  };

  const handleMemo = async (memo) => {
    await axios.patch(`/advertises/orders/${advertiseOrder.id}`, { memo });
    toast({
      position: 'top',
      status: 'success',
      title: '메모가 저장되었습니다.',
    });
  };

  const onRefundClick = async () => {
    const answer = window.confirm('환불 처리하시겠습니까?');
    if (!answer) {
      return;
    }
    const okStatus = ['RUNNING', 'FINISH', 'REJECTED', 'PAID', 'REFUNDED'];
    if (okStatus.indexOf(advertiseOrder.status) === -1) {
      return toast({
        position: 'top',
        status: 'error',
        title:
          '결제완료, 광고부적합, 광고종료 상태에서만 환불을 할 수 있습니다.',
      });
    }
    try {
      if (advertiseOrder.price < refundAmount) {
        return toast({
          position: 'top',
          status: 'error',
          title: '환불금액이 결제 금액보다 큽니다.',
        });
      }
      await axios.post(`/admin/advertise-orders/refund`, {
        advertiseOrder_id: advertiseOrder.id,
        amount: refundAmount,
      });

      toast({
        position: 'top',
        status: 'success',
        title: '환불 처리 되었습니다. 영업일 기준 최대 10일 걸릴 수 있습니다',
      });
      history.replace(location.pathname);
    } catch (e) {
      toast({
        position: 'top',
        status: 'error',
        title: '외부 결제 서버 오류가 발생했습니다.',
      });
    }
  };

  const onCancelRefund = async () => {
    if (
      confirm(
        '환불 요청이 취소되며, 광고가 송출 되게 됩니다. 환불 요청 취소를 하시겠습니까?',
      )
    ) {
      await axios.delete(`/admin/advertise-orders/refund`, {
        advertiseOrder_id: advertiseOrder.id,
      });
      toast({
        position: 'top',
        status: 'success',
        title: '환불 요청이 취소되었습니다.',
      });
      history.back();
    }
  };

  return (
    <Box mt="100px">
      <Text fontSize="lg" fontWeight="bold">
        주문 정보
      </Text>
      <Descriptions bordered>
        <Descriptions.Item label="구매자">
          {advertiseOrder.buyer.name}
        </Descriptions.Item>
        <Descriptions.Item label="주문상태">
          {AdvertiseOrderStatus(advertiseOrder.status)}
        </Descriptions.Item>
        <Descriptions.Item label="주문번호">
          <Text as="b">{advertiseOrder.merchant_uid}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="구매 매체(구좌)" span="2">
          <UnorderedList>
            {advertiseOrder.advertises.map((ad) => {
              return (
                <ListItem key={ad.id}>
                  <Link to={`/admin/advertise/stores/${ad.storeUser.id}`}>
                    {ad.businessName}
                  </Link>
                </ListItem>
              );
            })}
          </UnorderedList>
        </Descriptions.Item>
        <Descriptions.Item label="결제 완료 날짜">
          {dayjs(advertiseOrder.paidDate).format('YYYY-MM-DD HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label="결제금액">
          {commaThreeDigit(advertiseOrder.price)} 원
          {advertiseOrder.refundAmount > 0 && (
            <Text as="b">
              (환불금액 {commaThreeDigit(advertiseOrder.refundAmount)} 원)
            </Text>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="광고일자">
          {dayjs(advertiseOrder.startDate).format('YYYY-MM-DD')}~
          {dayjs(advertiseOrder.endDate).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="결제방법">
          {advertiseOrder.paymentMethod}
        </Descriptions.Item>
        <Descriptions.Item label="업로드 영상" span={3}>
          {advertiseOrder.videoUrl.length < 5 && <p>Vimeo 인코딩 작업 중...</p>}
          <iframe
            src={advertiseOrder.videoUrl}
            width="500px"
            height="400px"
            frameBorder="0"
          ></iframe>
        </Descriptions.Item>
        <Descriptions.Item label="광고 처리" span={3}>
          <Flex mt={5} justifyItems="center">
            <Button
              w={300}
              colorScheme="green"
              disabled={advertiseOrder.isConfirm}
              onClick={onConfirmClick}
            >
              광고 승인
            </Button>
            <Text ml="3" alignSelf="center">
              {advertiseOrder.confirmAdmin &&
                `승인 관리자 : ${advertiseOrder.confirmAdmin.name}(${dayjs(
                  advertiseOrder.confirmDate,
                ).format('YYYY-MM-DD')})`}
            </Text>
          </Flex>
          <Flex mt={5}>
            <Input
              vlaue={advertiseOrder.rejectedText}
              disabled={advertiseOrder.status !== 'PAID'}
              onChange={(e) =>
                setAdvertiseOrder({
                  ...advertiseOrder,
                  rejectedText: e.target.value,
                })
              }
              width={400}
              mr={1}
              placeholder="거절 사유"
            />
            <Button
              disabled={advertiseOrder.status !== 'PAID'}
              colorScheme="red"
              onClick={onRejectClick}
            >
              광고 거절처리
            </Button>
          </Flex>
          <Flex mt={5}>
            <Input
              width={200}
              value={refundAmount}
              onChange={(e) => {
                e.target.value == +e.target.value &&
                  setRefundAmount(+e.target.value);
              }}
              mr={1}
              placeholder="환불 금액"
            />
            <Button colorScheme="green" onClick={onRefundClick}>
              금액 환불
            </Button>
            {/* 환불기능 종료에 의해 숨김처리
            {advertiseOrder.status === 'REQUEST_REFUND' && (
              <Button colorScheme="red" onClick={onCancelRefund}>
                고객이 요청한 환불
              </Button>
            )}
      */}
          </Flex>
        </Descriptions.Item>
        <Descriptions.Item label="광고 영상 교체" span={3}>
          <AdvertiseOrderSetVideo advertiseOrder={advertiseOrder} />
        </Descriptions.Item>
        <Descriptions.Item label="관리자 메모" span={3}>
          <Box minH="200px">
            <Editable
              placeholder="여기를 눌러서 메모 작성"
              defaultValue={advertiseOrder.memo}
              onSubmit={(memo) => handleMemo(memo)}
            >
              <EditablePreview />
              <EditableTextarea rows={10} size="lg" />
            </Editable>
          </Box>
          <Button variant="ghost">메모저장</Button>
        </Descriptions.Item>
      </Descriptions>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              견적서 발송
            </AlertDialogHeader>

            <AlertDialogBody>정말로 발송 하시겠습니까?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={onConfirm} ml={3}>
                발송
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default AdvertiseOrderDetail;
