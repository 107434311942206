// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/Footer/Footer.js';
// Layout components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar';
import { LOGO_TEXT } from 'constants';
import { useAuth } from 'hooks/use-auth';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes.js';
// Custom Chakra theme
import theme from 'theme/theme.js';
import { AdminUserWrite } from 'views/Admin/AdminUser/AdminUserWrite';
import { UserDetail } from 'views/Admin/User/UserDetail';
import { ContentWrite } from 'views/Admin/Content/ContentWrite';
import { EstimateTemplateWrite } from 'views/Admin/Estimate/EstimateTemplateWrite';
import EstimateDetail from 'views/Admin/Estimate/EstimateDetail';
import ContractDetail from 'views/Admin/Contract/ContractDetail';
import PlayListContentEdit from 'views/Admin/StaticServiceInfo/components/PlayListContentEdit';
// Custom components
import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';
import AdvertiseOrderDetail from 'views/Admin/Advertise/AdvertiseOrderDetail';
import AdvertiseStoreDetail from 'views/Admin/Advertise/Stores/AdvertiseStoreDetail';
import CreatorContentWrite from 'views/Admin/CreatorContent/CreatorContentWrite';
import CreatorList from 'views/Admin/CreatorContent/CreatorList';
import CreatorOrderDetail from 'views/Admin/CreatorContent/CreatorOrderDetail';

export default function Dashboard(props) {
  const { user } = useAuth();
  const { ...rest } = props;
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState('transparent');
  const [fixed, setFixed] = useState(false);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === 'account') {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = 'ltr';
  // Chakra Color Mode
  return (
    <>
      {user && (
        <ChakraProvider theme={theme} resetCss={false}>
          <Sidebar
            routes={routes}
            logoText={LOGO_TEXT}
            display="none"
            sidebarVariant={sidebarVariant}
            {...rest}
          />
          <MainPanel
            w={{
              base: '100%',
              xl: 'calc(100% - 275px)',
            }}
          >
            <Portal>
              <AdminNavbar
                onOpen={onOpen}
                logoText={LOGO_TEXT}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                fixed={fixed}
                {...rest}
              />
            </Portal>
            {getRoute() ? (
              <PanelContent>
                <PanelContainer>
                  <Switch>
                    <Route
                      path="/admin/users/:user_id"
                      component={UserDetail}
                    />
                    <Route
                      path="/admin/admin-users/write"
                      component={AdminUserWrite}
                    />
                    <Route
                      path="/admin/contents/write"
                      component={ContentWrite}
                    />
                    <Route
                      path="/admin/creator-contents/write"
                      component={CreatorContentWrite}
                    />
                    <Route
                      path="/admin/creator-contents/creators"
                      component={CreatorList}
                    />
                    <Route
                      path="/admin/estimates/templates"
                      component={EstimateTemplateWrite}
                    />
                    <Route
                      path="/admin/estimates/:estimate_id"
                      component={EstimateDetail}
                    />
                    <Route
                      path="/admin/advertise/stores/:storeUser_id"
                      component={AdvertiseStoreDetail}
                    />
                    <Route
                      path="/admin/creator-content-orders/:order_id"
                      component={CreatorOrderDetail}
                    />
                    <Route
                      path="/admin/advertise/orders/:advertiseOrder_id"
                      component={AdvertiseOrderDetail}
                    />
                    <Route
                      path="/admin/contracts/:contract_id"
                      component={ContractDetail}
                    />
                    <Route
                      path="/admin/static-service-info/dynamic/playlist-groups/:contentGroupName"
                      component={PlayListContentEdit}
                    />
                    {getRoutes(routes)}
                    <Redirect from="/admin" to="/admin/dashboard" />
                  </Switch>
                </PanelContainer>
              </PanelContent>
            ) : null}
            <Footer />
          </MainPanel>
        </ChakraProvider>
      )}
    </>
  );
}
