import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { Tag } from '@chakra-ui/tag';
import axios from 'axios';
import dayjs from 'dayjs';

const getFilenameWithoutExtensionAndNormalize = (name) => {
  const arr = name.split('.');
  const filename = arr.slice(0, arr.length - 1).join('');
  const randomNumber = Math.floor(Math.random() * 1000);
  return filename.replace(/[^0-9a-zA-Z_()-]+/g, '') + randomNumber;
};

export const commaThreeDigit = (num) => {
  if (!num || num !== +num) {
    return 0;
  }
  const reverse = num.toString().split('').reverse();
  let counter = 0;
  const result = [];
  for (let i = 0; i < reverse.length; i++) {
    const num = reverse[i];
    counter++;
    result.push(num);
    if (counter % 3 === 0) {
      result.push(',');
    }
  }
  const r = result.reverse().join('');
  if (!r) {
    return 0;
  }
  if (r[0] === ',') {
    return r.slice(1, r.length);
  }
  return r;
};

export const koreanWon = (num) => {
  if (!num) {
    return '0원';
  }

  return (+num / 10000).toFixed(1) + '만원';
};

export const uploadFile = (file, filename, isPrivate) => {
  const REGION = 'ap-northeast-2';
  const s3 = new S3Client({
    region: REGION,
    credentials: {
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    },
  });

  const d = dayjs();
  const fileExtension = filename.split('.').pop().toLowerCase();
  const filePath = `${d.format('YYYY-MM-DD')}/${d.format('HHmm')}/${d.format(
    'ss',
  )}-${getFilenameWithoutExtensionAndNormalize(filename)}.${fileExtension}`;

  const uploadParam = {
    Bucket: isPrivate ? 'beam-monster-private' : 'beam-monster-backend',
    Key: filePath,
    Body: file,
    ACL: 'public-read',
    CacheControl: 'max-age=31536000',
    ContentDisposition: `attachment; filename*=utf-8''${encodeURIComponent(
      filename,
    )}; filename="${encodeURIComponent(filename)}"`,
  };

  if (fileExtension === 'pdf') {
    uploadParam.ContentDisposition = 'inline';
    uploadParam.ContentType = 'application/pdf';
  }

  return new Upload({
    client: s3,
    params: uploadParam,
  });
};

export const CreatorOrderStatus = (status) => {
  const obj = {
    PAID: { color: 'green', text: '결제완료' },
    FAIL: { color: 'pink', text: '결제실패' },
  };

  return <Tag colorScheme={obj[status].color}>{obj[status].text}</Tag>;
};

export const AdvertiseOrderStatus = (status) => {
  const obj = {
    PAID: { color: 'green', text: '결제완료' },
    RUNNING: { color: 'blue', text: '진행중' },
    REJECTED: { color: 'red', text: '승인거절' },
    FAIL: { color: 'pink', text: '결제실패' },
    FINISH: { color: 'gray', text: '광고종료' },
    REFUNDED: { color: 'gray', text: '환불' },
    REQUEST_REFUND: { color: 'yellow', text: '환불신청' },
  };

  return <Tag colorScheme={obj[status].color}>{obj[status].text}</Tag>;
};

export const engToKor = (key) => {
  const obj = {
    LED: 'LED',
    BEAM: '빔프로젝터',
    SUBMIT: '검토필요',
    STANDARD: '스탠다드',
    BASIC: '베이직',
    PREMIUM: '프리미엄',
    ONCE: '일시불',
    RENTAL: '할부',
    READY: '대기',
    DONE: '완료',
  };
  return obj[key];
};

export const contractStatus = (status) => {
  if (status === 'READY') {
    return (
      <Tag type="solid" colorScheme="teal">
        계약서요청
      </Tag>
    );
  } else if (status === 'RENTAL_READY') {
    return <Tag colorScheme="yellow">할부심사요청</Tag>;
  } else if (status === 'RENTAL_CHECKED') {
    return <Tag colorScheme="orange">할부심사완료</Tag>;
  } else if (status === 'UPLOADED') {
    return <Tag colorScheme="purple">입금대기</Tag>;
  } else if (status === 'MONEY_CHECKED') {
    return <Tag colorScheme="green">입금확인</Tag>;
  } else if (status === 'SCHEDULED') {
    return <Tag colorScheme="blue">스케쥴확정</Tag>;
  } else if (status === 'FINISH') {
    return <Tag colorScheme="red">완료</Tag>;
  } else if (status === 'REJECT') {
    return <Tag colorScheme="gray">계약종료</Tag>;
  } else if (status === 'DELETED') {
    return <Tag colorScheme="gray">삭제</Tag>;
  }
};

export const estimateStatus = (status) => {
  if (status === 'SUBMIT' || status === 'PENDING_CHECK_ADMIN') {
    return (
      <Tag type="solid" colorScheme="teal">
        구매 상담 요청
      </Tag>
    );
  } else if (status === 'ADMIN_CONFIRM') {
    return <Tag colorScheme="red">관리자 승인완료</Tag>;
  } else if (status === 'CONFIRM') {
    return <Tag colorScheme="red">견적서 발행 완료</Tag>;
  } else if (status === 'DELETED' || status === 'ADMIN_REJECTED') {
    return <Tag colorScheme="gray">견적서삭제</Tag>;
  }
};

export const getVimeoUrl = async (id) => {
  if (id.length > 10) {
    return id;
  }
  const { data } = await axios.get('/utils/playable-vimeo-link', {
    params: {
      url: id,
    },
  });
  return data;
};
