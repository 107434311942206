import { Box, Text } from '@chakra-ui/layout';
import { InstallScheduleCalendar } from './InstallScheduleCalendar';

export const InstallSchedule = () => {
  return (
    <Box mt={82}>
      <Text fontSize="xl">기사 방문 스케쥴</Text>
      <Text fontSize="sm" fontWeight="light" mb={4}>
        A/S, 계약서에서 설정한 서비스기사 방문 스케쥴
      </Text>
      <InstallScheduleCalendar></InstallScheduleCalendar>
    </Box>
  );
};
