import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axios from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import './InstallScheduleCalendar.css';
import { useHistory } from 'react-router';
import { InstallScheduleModal } from './InstallScheduleModal';

const localizer = momentLocalizer(moment);

export const InstallScheduleCalendar = (props) => {
  const [events, setEvents] = useState([]);
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [selected_id, setSelected_id] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [range, setRange] = useState({
    start: dayjs().startOf('month').subtract(7, 'day'),
    end: dayjs().endOf('month').add(7, 'day'),
  });

  const getEvents = async (start, end) => {
    const tempEvents = [];
    const { data: contractEvents } = await axios.get('/contracts/schedules', {
      params: {
        start: dayjs(start).format('YYYY-MM-DD'),
        end: dayjs(end).format('YYYY-MM-DD'),
      },
    });
    tempEvents.push(
      ...contractEvents.map((el) => {
        const date = dayjs(el.visitDate).format('YYYY-MM-DD');
        return {
          start: date,
          end: date,
          title: el.isContract
            ? '[설치] ' + `${el.managerName || ''}`
            : '[A/S] ' +
              `${el.managerName ? el.managerName + '-' : ''}${
                el.writer.name || ''
              }`,
          allDay: true,
          resource: el,
        };
      }),
    );

    const { data: adminEvents } = await axios.get(`/schedules`, {
      params: {
        start: dayjs(start).format('YYYY-MM-DD'),
        end: dayjs(end).format('YYYY-MM-DD'),
      },
    });

    tempEvents.push(
      ...adminEvents.map((el) => {
        const start = dayjs(el.startDate).format('YYYY-MM-DD');
        const end = dayjs(el.endDate).format('YYYY-MM-DD');
        return {
          start,
          end,
          title: el.title,
          allDay: true,
          resource: el,
        };
      }),
    );
    setEvents(tempEvents);
  };

  useEffect(() => {
    getEvents(range.start, range.end);
  }, []);

  const onRangeChange = (data) => {
    const start = data.start;
    const end = data.end;
    getEvents(start, end);
    setRange({ start, end });
  };

  const eventStyleGetter = (event) => {
    if (event.resource?.visitDate) {
      return {
        className: 'staticEvent',
      };
    }
  };

  const onSelectEvent = (event) => {
    const { resource } = event;
    if (resource?.visitDate) {
      const { isContract, id } = resource;
      if (isContract) {
        history.push(`/admin/contracts/${id}`);
      } else {
        history.push(`/admin/after-services?search=${id}`);
      }
    } else {
      setIsShow(true);
      setSelected_id(resource.id);
    }
    return true;
  };

  const closeModal = ({ reload }) => {
    if (reload) {
      getEvents(range.start, range.end);
    }
    setIsShow(false);
    setSelected_id(null);
    setSelectedDate(null);
  };

  const onSelectSlot = ({ start }) => {
    setSelectedDate(dayjs(start).format('YYYY-MM-DD'));
    setIsShow(true);
    setSelected_id(null);
  };

  return (
    <>
      <InstallScheduleModal
        isShow={isShow}
        selected_id={selected_id}
        selectedDate={selectedDate}
        closeModal={closeModal}
      />
      <Calendar
        onRangeChange={onRangeChange}
        selectable
        onSelectSlot={onSelectSlot}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        events={events}
        style={{ height: 700 }}
        popup
        onSelectEvent={onSelectEvent}
        views={{
          month: true,
        }}
        eventPropGetter={eventStyleGetter}
        messages={{
          previous: '이전달',
          today: '오늘',
          next: '다음달',
        }}
      />
    </>
  );
};
