import { Button } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Badge, Flex, Text } from '@chakra-ui/layout';
import { Tr, Td as ChakraTd, Avatar } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAuth } from 'hooks/use-auth';

const Td = ({ children }) => {
  return <ChakraTd py="5px">{children}</ChakraTd>;
};

export const AdminUserTableRow = ({ adminUser }) => {
  const { user } = useAuth();
  const { name, loginId, id, position, email, phone, role } = adminUser;
  const textColor = useColorModeValue('gray.700', 'white');
  let roleBadgeColor = 'red.400';

  if (role === '리더') {
    roleBadgeColor = 'teal.400';
  } else if (role === '매니저') {
    roleBadgeColor = 'blue.400';
  }

  return (
    <Tr>
      <Td>{id}</Td>
      <Td pl="0px">
        <Flex align="center" minWidth="100%" flexWrap="nowrap">
          <Avatar src={''} w="50px" borderRadius="12px" me="18px" />
          <Flex direction="column">
            <Text fontSize="md" color={textColor} minWidth="100%">
              {name}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {position}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} minWidth="100%">
          {loginId}
        </Text>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor}>
            {email}
          </Text>
          <Text fontSize="sm" color="gray.400">
            {phone}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Badge
          bg={roleBadgeColor}
          color={'white'}
          fontSize="13px"
          p="3px 3px"
          w="42px"
          textAlign="center"
        >
          {role}
        </Badge>
      </Td>
      <Td>
        {user.role === '마스터' && (
          <Button p="0px" bg="transparent" variant="no-hover">
            <Link to={{ pathname: '/admin/admin-users/write', adminUser }}>
              <Text
                fontSize="md"
                color="gray.400"
                fontWeight="bold"
                cursor="pointer"
              >
                수정
              </Text>
            </Link>
          </Button>
        )}
      </Td>
    </Tr>
  );
};
