import { Button } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import {
  Editable,
  EditablePreview,
  EditableTextarea,
} from '@chakra-ui/editable';
import { useDisclosure } from '@chakra-ui/hooks';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/layout';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/modal';
import { Select } from '@chakra-ui/select';
import { useToast } from '@chakra-ui/toast';
import { Badge, DatePicker, Descriptions, Image } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useAuth } from 'hooks/use-auth';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { engToKor, contractStatus } from 'utils';

function ContractDetail() {
  const [contract, setContract] = useState(null);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [showSelectPage, setShowSelectPage] = useState(false);
  const [managers, setManagers] = useState([]);
  const { user } = useAuth();
  const param = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  const history = useHistory();
  const fileuploadRef = useRef(null);

  useEffect(() => {
    const getData = async () => {
      const { contract_id } = param;
      const { data } = await axios.get('/contracts/' + contract_id);
      const { data: managerData } = await axios.get(
        '/service-statics/service-managers',
      );

      setManagers(managerData.value.data);
      setContract(data);
    };
    getData();
  }, []);

  if (!contract) {
    return null;
  }

  const handleChange = async () => {
    const isSendTaxReceipt = true;
    const sendTaxReceiptAdmin = user.id;
    const sendTaxReceiptDate = new Date();

    const data = {
      isSendTaxReceipt,
      sendTaxReceiptAdmin,
      sendTaxReceiptDate,
    };

    await axios.patch(`/contracts/${contract.id}`, data);
    setContract({
      ...contract,
      ...data,
      sendTaxReceiptAdmin: user,
    });
  };

  const handleCheckMoneyChange = async () => {
    const isMoneyChecked = true;
    const checkMoneyAdmin = user.id;
    const checkMoneyDate = new Date();
    const status = 'MONEY_CHECKED';

    const data = {
      isMoneyChecked,
      checkMoneyAdmin,
      checkMoneyDate,
      status,
    };

    await axios.patch(`/contracts/${contract.id}`, data);
    setContract({
      ...contract,
      ...data,
      checkMoneyAdmin: user,
      status,
    });
  };

  const sendContract = async () => {
    const { filename, fileUrl, pdfImageUrls, shouldSignImageIndex } = contract;
    if (!filename || !fileUrl) {
      toast({
        position: 'top',
        status: 'warning',
        title: '견적서파일을 선택해주세요.',
      });
      onClose();
      return;
    }
    if (!shouldSignImageIndex || shouldSignImageIndex.length === 0) {
      toast({
        position: 'top',
        status: 'warning',
        title: '유저 사인 페이지는 최소 하나 필요합니다.',
      });
      onClose();
      return;
    }
    await axios.post(`/contracts/${contract.id}/send`, {
      filename,
      fileUrl,
      pdfImageUrls,
      shouldSignImageIndex,
    });
    onClose();
    toast({
      position: 'top',
      status: 'success',
      title: '발송되었습니다.',
    });
    history.push('/admin/contracts');
  };

  const handleMemo = async (memo) => {
    await axios.patch(`/contracts/${contract.id}`, { memo });
    toast({
      position: 'top',
      status: 'success',
      title: '메모가 저장되었습니다.',
    });
  };

  const handleUpload = async (file) => {
    fileuploadRef.current.value = '';

    const formData = new FormData();
    formData.append('files', file);
    setUploadMessage('업로드 & 이미지 변환 중');
    const res = await axios.post('/utils/upload-files', formData, {
      params: {
        shouldSplitPdf: true,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const { url, filename, pdfImages } = res.data[0];
    setUploadMessage(null);
    setShowSelectPage(true);

    setContract({
      ...contract,
      filename,
      fileUrl: url,
      pdfImageUrls: pdfImages,
      shouldSignImageIndex: [],
    });
  };

  const handleScheduled = async () => {
    if (!contract.visitDate) {
      return toast({
        position: 'top',
        status: 'warning',
        title: '방문 예정일자를 입력해주세요.',
      });
    }
    if (!contract.visitManagerPhone) {
      return toast({
        position: 'top',
        status: 'warning',
        title: '방문 기사님을 선택해주세요.',
      });
    }
    const status = 'SCHEDULED';

    const data = {
      status,
      visitDate: contract.visitDate,
      visitManagerName: contract.visitManagerName,
      visitManagerPhone: contract.visitManagerPhone,
      visitManagerProfileImageUrl: contract.visitManagerProfileImageUrl,
    };

    await axios.patch(`/contracts/${contract.id}`, data);
    setContract({
      ...contract,
      status,
    });
    toast({
      position: 'top',
      status: 'success',
      title: '스케쥴이 지정되었습니다.',
    });
  };

  const handleRentalCheck = async () => {
    const rentalCheckAdmin = user.id;
    const rentalCheckDate = new Date();
    const status = 'RENTAL_CHECKED';

    const data = {
      rentalCheckAdmin,
      rentalCheckDate,
      status,
    };

    await axios.patch(`/contracts/${contract.id}`, data);
    toast({
      position: 'top',
      status: 'success',
      title: '할부 심사 승인 처리가 되었습니다.',
    });
    history.go(0);
  };

  const handleFinish = async () => {
    const finishAdminUser = user.id;
    const finishDate = new Date();
    const status = 'FINISH';

    const data = {
      finishAdminUser,
      finishDate,
      status,
    };

    await axios.patch(`/contracts/${contract.id}`, data);
    setContract({
      ...contract,
      ...data,
      finishAdminUser: user,
      finishDate,
    });
    toast({
      position: 'top',
      status: 'success',
      title: '계약 완료 처리가 되었습니다.',
    });
  };

  const handleVisitDate = (e) => {
    setContract({
      ...contract,
      visitDate: e,
    });
  };

  const goToEstimate = () => {
    history.push('/admin/estimates/' + contract.estimate.id);
  };

  const handleDelete = async () => {
    const answer = window.confirm('정말로 삭제하시겠습니까?');

    if (answer) {
      await axios.delete(`/estimates/${contract.estimate.id}`);
      history.replace('/admin/contracts');
      toast({
        position: 'top',
        status: 'success',
        title: '삭제처리 되었습니다.',
      });
    }
  };

  const handleSelect = (e) => {
    if (!e.target.value) {
      setContract({
        ...contract,
        visitManagerPhone: null,
        visitManagerName: null,
        visitManagerProfileImageUrl: null,
      });
      return;
    }
    const manager = JSON.parse(e.target.value);
    setContract({
      ...contract,
      visitManagerPhone: manager.phone,
      visitManagerName: manager.name,
      visitManagerProfileImageUrl: manager.profileImageUrl,
    });
  };
  const isAlreadyChecked = (index) => {
    return contract.shouldSignImageIndex.indexOf(index) > -1;
  };

  const togleSignImage = (index) => {
    if (
      contract.shouldSignImageIndex &&
      contract.shouldSignImageIndex.length > 0
    ) {
      const isAlreadyChecked =
        contract.shouldSignImageIndex.indexOf(index) > -1;
      if (isAlreadyChecked) {
        setContract({
          ...contract,
          shouldSignImageIndex: contract.shouldSignImageIndex.filter(
            (el) => el !== index,
          ),
        });
      } else {
        const arr = [...contract.shouldSignImageIndex, index];
        arr.sort();
        setContract({
          ...contract,
          shouldSignImageIndex: arr,
        });
      }
    } else {
      setContract({
        ...contract,
        shouldSignImageIndex: [index],
      });
    }
  };

  const handleChangeSchedule = async () => {
    const {
      visitDate,
      visitManagerName,
      visitManagerPhone,
      visitManagerProfileImageUrl,
    } = contract;
    if (!visitManagerProfileImageUrl || !visitDate) {
      toast({
        position: 'top',
        status: 'warning',
        title: '방문일, 기사님을 선택해주세요.',
      });
      return;
    }
    if (
      window.confirm(
        '변경 된 스케쥴이 고객님께 문자가 전송됩니다. 수정하시겠습니까?',
      )
    ) {
      await axios.put('contracts/' + contract.id + '/schedule', {
        visitDate,
        visitManagerName,
        visitManagerProfileImageUrl,
        visitManagerPhone,
      });
      toast({
        position: 'top',
        status: 'success',
        title: '변경되었습니다',
      });
    }
  };

  return (
    <Box mt="100px">
      <Text fontSize="lg" fontWeight="bold" mb="10px">
        계약서 정보{' '}
        <Link onClick={goToEstimate}>
          <Button>견적서 바로가기</Button>
        </Link>
      </Text>
      <Descriptions bordered>
        {contract.paymentType === 'RENTAL' && (
          <Descriptions.Item label="할부승인" span={3}>
            <Flex>
              <Button
                disabled={contract.rentalCheckAdmin}
                colorScheme="green"
                onClick={handleRentalCheck}
              >
                할부 승인 처리
              </Button>
              <Center pl={'10px'}>
                <Text>
                  {contract.rentalCheckAdmin &&
                    `관리자: ${contract.rentalCheckAdmin?.name} (${dayjs(
                      contract.rentalCheckDate,
                    ).format('YYYY-MM-DD HH:mm')})`}
                </Text>
              </Center>
            </Flex>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="계약서 파일" span="2">
          <Flex>
            <Box flex={1} textAlign="left">
              <Button
                onClick={() => fileuploadRef.current.click()}
                disabled={
                  ['READY', 'RENTAL_READY', 'RENTAL_CHECKED'].indexOf(
                    contract.status,
                  ) === -1 || uploadMessage
                }
              >
                {uploadMessage || 'PDF 계약서 업로드'}
              </Button>
              <Input
                ref={fileuploadRef}
                size="sm"
                display="none"
                border={false}
                type="file"
                multiple={false}
                accept="application/pdf"
                onChange={(e) => handleUpload(e.target.files[0])}
              />
            </Box>
            <Center flex={3}>
              {contract.filename ? (
                <Link href={contract.fileUrl} isExternal>
                  {contract.filename ? contract.filename : '-'}{' '}
                  <ExternalLinkIcon mx="2px" />
                </Link>
              ) : (
                <Text>-</Text>
              )}
            </Center>
          </Flex>
        </Descriptions.Item>
        <Descriptions.Item label="발송" span="1">
          <Flex>
            <Button
              disabled={contract.fileUploaderAdmin || !contract.fileUrl}
              colorScheme="green"
              variant="solid"
              onClick={onOpen}
            >
              {['READY', 'RENTAL_READY', 'RENTAL_CHECKED'].indexOf(
                contract.status,
              ) === -1
                ? '계약서 발송완료'
                : '계약서 발송'}
            </Button>
            {['READY', 'RENTAL_READY', 'RENTAL_CHECKED'].indexOf(
              contract.status,
            ) === -1 && (
              <Center pl={'10px'}>
                <Text>
                  관리자: {contract.fileUploaderAdmin?.name}(
                  {dayjs(contract.fileUploadDate).format('YYYY-MM-DD HH:mm')})
                </Text>
              </Center>
            )}
          </Flex>
        </Descriptions.Item>
        {showSelectPage && (
          <Descriptions.Item span="3" label="서명 페이지">
            <Center>유저가 서명해야 하는 페이지를 선택해주세요.</Center>
            선택된 페이지 :{' '}
            {contract.shouldSignImageIndex &&
              contract.shouldSignImageIndex.map((el) => el + 1).join(',')}
            <SimpleGrid columns={2} spacing={3}>
              {contract.pdfImageUrls.map((el, idx) => {
                return (
                  <GridItem key={idx}>
                    <VStack>
                      <Image width={400} src={el} preview={false} />
                      <Button
                        onClick={() => togleSignImage(idx)}
                        colorScheme={isAlreadyChecked(idx) ? 'red' : 'green'}
                      >
                        {isAlreadyChecked(idx)
                          ? `${idx + 1} 페이지 해제`
                          : `${idx + 1} 페이지 선택`}
                      </Button>
                    </VStack>
                  </GridItem>
                );
              })}
            </SimpleGrid>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="결제 타입">
          {engToKor(contract.paymentType)}
        </Descriptions.Item>
        <Descriptions.Item label="주문상태">
          {contractStatus(contract.status)}
        </Descriptions.Item>
        <Descriptions.Item label="주문번호">{contract.id}</Descriptions.Item>
        <Descriptions.Item label="주문시간">
          {dayjs(contract.createdAt).format('YYYY-MM-DD HH:mm')}
        </Descriptions.Item>
        <Descriptions.Item label="주문자" span={2}>
          {`[${contract.writer?.businessName}] ${contract.writer.name}(${contract.writer.phone})`}
        </Descriptions.Item>
        <Descriptions.Item label="서명 계약서 PDF" span={3}>
          {contract.clientSignPdfUrl ? (
            <Link download href={contract.clientSignPdfUrl} target="_blank">
              <Button>다운로드</Button>
            </Link>
          ) : (
            '-'
          )}
        </Descriptions.Item>
        {contract.estimate.hardware === 'LED' ? (
          <Descriptions.Item label="LED 견적 정보" span={3}>
            <Text fontSize="lg">
              가로 - <strong>{contract.estimate.ledWidth * 1000}</strong> mm
              세로 - <strong>{contract.estimate.ledHeight * 1000}</strong> mm
              넓이 -{' '}
              <strong>
                {contract.estimate.ledHeight * contract.estimate.ledWidth}
              </strong>{' '}
              m^2
            </Text>
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label="빔프로젝터 견적 정보" span={3}>
            <Text fontSize="lg">{contract.estimate.beamSize}</Text>
          </Descriptions.Item>
        )}
        {['READY', 'RENTAL_CHECKED', 'RENTAL_READY'].indexOf(
          contract.status,
        ) === -1 && (
          <Descriptions.Item label="입금확인" span={1}>
            <Flex>
              {!contract.isMoneyChecked && (
                <Button colorScheme="green" onClick={handleCheckMoneyChange}>
                  입금확인 처리
                </Button>
              )}
              <Center pl={'10px'}>
                <Text>
                  {contract.checkMoneyAdmin &&
                    `관리자: ${contract.checkMoneyAdmin?.name} (${dayjs(
                      contract.checkMoneyDate,
                    ).format('YYYY-MM-DD HH:mm')})`}
                </Text>
              </Center>
            </Flex>
          </Descriptions.Item>
        )}
        {['READY', 'RENTAL_CHECKED', 'RENTAL_READY'].indexOf(
          contract.status,
        ) === -1 && (
          <Descriptions.Item label="세금계산서" span={2}>
            <Flex>
              {!contract.isSendTaxReceipt && (
                <Button colorScheme="green" onClick={handleChange}>
                  세금계산서 발송처리
                </Button>
              )}
              <Center pl={'10px'}>
                <Text>
                  {contract.sendTaxReceiptAdmin &&
                    `관리자: ${contract.sendTaxReceiptAdmin.name} (${dayjs(
                      contract.sendTaxReceiptDate,
                    ).format('YYYY-MM-DD HH:mm')})`}
                </Text>
              </Center>
            </Flex>
          </Descriptions.Item>
        )}
        {['MONEY_CHECKED', 'SCHEDULED', 'FINISH', 'REJECT'].indexOf(
          contract.status,
        ) > -1 && (
          <>
            <Descriptions.Item label="방문 예정일자" span={1}>
              <DatePicker
                format="YYYY/MM/DD HH:mm"
                showTime
                value={contract.visitDate ? moment(contract.visitDate) : null}
                onOk={handleVisitDate}
              />
            </Descriptions.Item>
            <Descriptions.Item label="방문 기사님" span={2}>
              <VStack>
                <Select
                  placeholder="서비스 기사"
                  size="sm"
                  onChange={handleSelect}
                  value={JSON.stringify({
                    name: contract.visitManagerName,
                    phone: contract.visitManagerPhone,
                    profileImageUrl: contract.visitManagerProfileImageUrl,
                  })}
                >
                  {managers.map((el) => {
                    return (
                      <option key={el.phone} value={JSON.stringify(el)}>
                        {`${el.name} (${el.phone})`}
                      </option>
                    );
                  })}
                </Select>
                <Text>
                  신청 주소:{' '}
                  {`${contract.estimate.address || ''} ${
                    contract.estimate.detailAddress || ''
                  }`}
                </Text>
              </VStack>
            </Descriptions.Item>
          </>
        )}
        {['MONEY_CHECKED', 'SCHEDULED', 'FINISH'].indexOf(contract.status) >
          -1 && (
          <Descriptions.Item label="스케쥴 지정" span={3}>
            <Flex>
              {contract.status === 'MONEY_CHECKED' && (
                <Button colorScheme="green" onClick={handleScheduled}>
                  스케쥴 저장
                </Button>
              )}
              {contract.status !== 'MONEY_CHECKED' && (
                <Button colorScheme="green" onClick={handleChangeSchedule}>
                  스케쥴 수정
                </Button>
              )}
              <Text>
                {contract.finishAdminUser &&
                  `관리자: ${contract.finishAdminUser?.name} (${dayjs(
                    contract.finishDate,
                  ).format('YYYY-MM-DD HH:mm')})`}
              </Text>
            </Flex>
          </Descriptions.Item>
        )}
        {['SCHEDULED'].indexOf(contract.status) > -1 && (
          <Descriptions.Item label="계약완료" span={3}>
            <Flex>
              {!contract.finishAdminUser && (
                <Button colorScheme="green" onClick={handleFinish}>
                  계약 완료
                </Button>
              )}
              <Text>
                {contract.finishAdminUser &&
                  `관리자: ${contract.finishAdminUser?.name} (${dayjs(
                    contract.finishDate,
                  ).format('YYYY-MM-DD HH:mm')})`}
              </Text>
              <Center>
                <Text ml="30px" fontWeight="light">
                  계약 완료 처리를 해야 유저가 서비스를 이용 할 수 있습니다.
                </Text>
              </Center>
            </Flex>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="관리자 메모" span={3}>
          <Box minH="200px">
            <Editable
              placeholder="여기를 눌러서 메모 작성"
              defaultValue={contract.memo}
              onSubmit={(memo) => handleMemo(memo)}
            >
              <EditablePreview />
              <EditableTextarea rows={10} size="lg" />
            </Editable>
          </Box>
          <Button variant="ghost">메모저장</Button>
        </Descriptions.Item>
        <Descriptions.Item label="계약서 삭제" span={3}>
          <Button colorScheme="red" onClick={handleDelete}>
            삭제
          </Button>
        </Descriptions.Item>
      </Descriptions>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              계약서 발송
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text m={2} fontSize="lg">
                발송을 하게 되면 유저에게 문자가 전송되며,
                <br /> 계약서를 수정 할 수 없습니다.
                <br /> 고객 서명이 들어가야할 페이지{' '}
                {contract.shouldSignImageIndex &&
                  contract.shouldSignImageIndex.map((el) => el + 1).join(', ')}
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={sendContract} ml={3}>
                발송
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default ContractDetail;
