import { Td } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { estimateStatus } from 'utils';
import { engToKor } from 'utils';

function EstimateTableRow(props) {
  const { data } = props;

  return (
    <>
      <Td>{data.id}</Td>
      <Td>{data.writer.name}</Td>
      <Td>{engToKor(data.hardware)}</Td>
      <Td>{engToKor(data.solutionType)}</Td>
      <Td>{dayjs(data.createdAt).format('YYYY-MM-DD HH:mm')}</Td>
      <Td>{estimateStatus(data.status)}</Td>
      <Td>{data.admin?.name}</Td>
    </>
  );
}

export default EstimateTableRow;
